import React from 'react';
import oFetch from 'o-fetch';
import AsyncButton from 'react-async-button';

type AreYouSureProps = {
  onClose: () => void;
  onSubmit: () => void;
  text: string;
  buttonText: string;
  buttonPendingText: string;
};

export function AreYouSure(props: AreYouSureProps) {
  const onClose = oFetch(props, 'onClose');
  const onSubmit = oFetch(props, 'onSubmit');
  const text = oFetch(props, 'text');
  const buttonText = oFetch(props, 'buttonText');
  const buttonPendingText = oFetch(props, 'buttonPendingText');

  return (
    <>
      <div className="boss-modal-window__message-block boss-modal-window__message-block_role_note">
        <div className="boss-modal-window__message-group">
          <p className="boss-modal-window__message-note">
            <span
              style={{ textAlign: 'center' }}
              className="boss-modal-window__message-line"
            >
              {text}
            </span>
          </p>
        </div>
      </div>
      <div className="boss-modal-window__actions">
        <button
          onClick={onClose}
          type="button"
          className="boss-button boss-button_role_inactive boss-modal-window__button"
        >
          CANCEL
        </button>
        <AsyncButton
          className="boss-button boss-button_role_cancel boss-modal-window__button"
          text={buttonText}
          pendingText={buttonPendingText}
          onClick={onSubmit}
        />
      </div>
    </>
  );
}
