import { useEffect } from "react";
import { Entertainer, EntertainerBankDetailsParams, UseEntertainerArgs } from "./types";
import oFetch from "o-fetch";
import { useLegacyGlobal } from "@/components/hooks-components/global";

export function useEntertainer(args: UseEntertainerArgs) {
  const updateGlobalState = oFetch(useLegacyGlobal(), 'updateGlobalState');

  useEffect(() => {
    updateGlobalState('entertainer', () => {
      return args.entertainer;
    });
  }, []);

  const entertainer = oFetch(useLegacyGlobal(), 'entertainer');

  function updateBankDetails(values: EntertainerBankDetailsParams): void {
    updateGlobalState('entertainer', (oldEntertainer: Entertainer) => {
      return {
        ...oldEntertainer,
        hasBankDetails: values.hasBankDetails,
        altBankingFullname: values.altBankingFullname,
        bankDetailsLastUpdatedAt: values.bankDetailsLastUpdatedAt,
        bankDetailsLastUpdatedBy: values.bankDetailsLastUpdatedBy,
      };
    });
  }

  return { entertainer, updateBankDetails };
}
