import React, { useEffect, useMemo } from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { EntertainersList } from './entertainers-list';

export type EntertainersListAppProps = {
  accessToken: string;
  entertainers: any[];
  entertainerTypes: any;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  allCount: number;
};

export function EntertainersListApp(props: EntertainersListAppProps) {
  const accessToken = oFetch(props, 'accessToken');
  const entertainerTypes = oFetch(props, 'entertainerTypes');

  useEffect(() => {
    window.boss.accessToken = accessToken;
  }, []);

  const initialFilterValues = useMemo(() => {
    const parsedHash = queryString.parse(window.location.search);
    return {
      name: '',
      type: '',
      ...parsedHash
    };
  }, []);

  const entertainerTypesOptions = useMemo(() => {
    return Object.entries(entertainerTypes).map((entry: any[]) => {
      const [value, label] = entry;
      return {
        value,
        label
      };
    });
  }, []);

  return (
    <LegacyGlobalProvider global={{ entertainerTypesOptions, initialFilterValues }}>
      <ModalsProvider>
        <EntertainersList {...props} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
