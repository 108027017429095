import React from 'react';
import { EntertainerFinanceReportsTableProps } from '../types';
import { ReportDataRow } from './report-data-row';
import { FINANCE_REPORT_TABLE_DATE_FORMAT } from '@/lib/date-fns-formats';
import { format } from 'date-fns';

export function EntertainerFinanceReportsTable(props: EntertainerFinanceReportsTableProps): JSX.Element {
  const hasReports = props.compositeEntertainerFinanceReports.length > 0;
  const weekDates = props.rotaWeek.weekDates();

  const renderReports = (
    <div className="boss-table boss-table_page_entertainer-finance-reports">
      <div className="boss-table__row">
        <ol>
          <li>Name</li>
          {Object.entries(weekDates).map(([day, date]) => {
            const key = date.toString();
            const dayWord = day.charAt(0).toUpperCase() + day.slice(1);
            const formattedDayTitle = `${format(date, FINANCE_REPORT_TABLE_DATE_FORMAT)}\n${dayWord}`;
            return (
              <li key={key}>{formattedDayTitle}</li>
            );
          })}
          <li>Cash</li>
          <li>Bank Transfer</li>
          <li>Total</li>
          <li>Status</li>
          <li>BD</li>
          <li>Notes</li>
        </ol>
      </div>
      {props.compositeEntertainerFinanceReports.map((report) => {
        return (
          <ReportDataRow
            allDataExists={props.allDataExists}
            permissions={props.permissions}
            isWeekFinished={props.isWeekFinished}
            key={report.id}
            rotaWeek={props.rotaWeek}
            compositeEntertainerFinanceReport={report}
          />
        );
      })}
    </div>
  );

  const renderNoReports = (
    <div className="boss-page-main__content">
      <div className="boss-page-main__note">
        <p className="boss-page-main__text-placeholder">There are no reports to show.</p>
      </div>
    </div>
  );

  return (
    <div className="boss-page-main__content">
      <div className="boss-page-main__inner">
        { hasReports ? renderReports : renderNoReports }
      </div>
    </div>
  );
}