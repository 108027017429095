import React from 'react';
import oFetch from 'o-fetch';
import { EntertainerContactForm } from './entertainer-contact-form';


export function EntertainerContactModal(props: any) {
  const onSubmit = oFetch(props, 'onSubmit');
  const processing = oFetch(props, 'processing');
  const initialValues = oFetch(props, 'initialValues');

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <EntertainerContactForm
          onSubmit={onSubmit}
          processing={processing}
          initialValues={initialValues}
        />
      </div>
    </div>
  );
}
