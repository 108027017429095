import { BossFormSelectNew } from '@/components/boss-form';
import { BossFormCurrencyInput } from '@/components/final-form-fields/boss-form-currency-input';
import oFetch from 'o-fetch';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';

type Props = {
  options: any[],
  processing: boolean;
  paymentType: any;
  fixedPaymentCents: any;
  paymentCentsPerHour: any;
  payRateType: any;
  disabledPaymentType: boolean;
};

export const PAY_RATE_FIXED = 'fixed';
export const PAY_RATE_HOURLY = 'hourly';

const payRateTypeOptions = [
  {
    value: PAY_RATE_FIXED,
    label: 'Fixed',
  },
  {
    value: PAY_RATE_HOURLY,
    label: 'Hourly',
  },
];

export function EntertainmentPaymentTypeField(props: Props) {
  const options = oFetch(props, 'options');
  const processing = oFetch(props, 'processing');
  const disabledPaymentType = oFetch(props, 'disabledPaymentType');
  const paymentType = oFetch(props, 'paymentType');
  const payRateType = oFetch(props, 'payRateType');
  const paymentTypeValue = oFetch(paymentType, 'input.value');
  const payRateTypeValue = oFetch(payRateType, 'input.value');
  const fixedPaymentCents = oFetch(props, 'fixedPaymentCents');
  const paymentCentsPerHour = oFetch(props, 'paymentCentsPerHour');
  const displayPriceField = paymentTypeValue && paymentTypeValue !== 'none';
  const isHourly = payRateTypeValue === PAY_RATE_HOURLY;
  const isFixed = payRateTypeValue === PAY_RATE_FIXED;

  useEffect(() => {
    if (payRateTypeValue === PAY_RATE_FIXED) {
      paymentCentsPerHour.input.onChange(null);
    }
    if (payRateTypeValue === PAY_RATE_HOURLY) {
      fixedPaymentCents.input.onChange(null);
    }
  }, [payRateTypeValue]);

  useEffect(() => {
    if (paymentTypeValue === 'none') {
      payRateType.input.onChange(null);
      paymentCentsPerHour.input.onChange(null);
      fixedPaymentCents.input.onChange(null);
    }
  }, [paymentTypeValue]);

  return (
    <>
      <Field
        name={'paymentType'}
        label="Payment Type"
        options={options}
        disabled={processing || disabledPaymentType}
        component={BossFormSelectNew}
      />
      {displayPriceField && (
        <>
          <Field
            name={'payRateType'}
            label="Pay Rate Type"
            options={payRateTypeOptions}
            disabled={processing}
            clearable={false}
            component={BossFormSelectNew}
          />
          {isFixed && (
            <Field
              name={'fixedPaymentCents'}
              label="Price"
              component={BossFormCurrencyInput}
            />
          )}
          {isHourly && (
            <Field
              name={'paymentCentsPerHour'}
              label="Price per hour"
              component={BossFormCurrencyInput}
            />
          )}
        </>
      )}
    </>
  );
}
