import { normalizeFinalFormErrors } from "@/lib/normalize-final-form-errors";
import { bossRequestHttp } from "@/lib/request-api";
import oFetch from "o-fetch";

type EntertainerUpdateValues = {
  name: string;
  description: string;
  entertainerType: string;
};

type EntertainerUpdateParams = {
  entertainer: any;
  values: EntertainerUpdateValues;
  onSuccess: (entertainer: any) => void;
  onFailure: () => void;
};

export function updateEntertainerRequest(params: EntertainerUpdateParams) {
  const entertainer = oFetch(params, 'entertainer');
  const values = oFetch(params, 'values');
  const onSuccess = oFetch(params, 'onSuccess');
  const onFailure = oFetch(params, 'onFailure');
  const entertainerId = oFetch(entertainer, 'id');

  return bossRequestHttp({
    successHandler(params: any) {
      const data = oFetch(params, 'data');
      const entertainer = oFetch(data, 'entertainer');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ message: 'Update Entertainer Success' });
      onSuccess(entertainer);
    },
    errorHandler(params: any) {
      const statusCode = oFetch(params, 'statusCode');
      const globalNotifications = oFetch(params, 'globalNotifications');

      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage({ message: 'Update Entertainer Failed' });
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'name',
            'entertainerType',
            'contacts'
          ],
        });
        const normalizedErrors = normalizeFinalFormErrors(errors);
        onFailure();
        return normalizedErrors;
      }
      return false;
    }
  }).put(`/api/v1/entertainers/${entertainerId}`, values);
}
