import { bossRequestHttp } from '@/lib/request-api';

type TPageInitialLoad = {
  staffMemberId: number
};

export function profilePageInitialLoad(params:TPageInitialLoad) {
  const { staffMemberId } = params;

  return bossRequestHttp({
    successHandler(params: any) {
      return params;
    },
    errorHandler() {},
  }).get(`/api/v1/entertainers/${staffMemberId}/profile`);
}
