import { ColoredSingleOption, ColoredSingleValue } from '@/components/boss-form/colored-select';
import BossDateRangePicker from '@/components/react-dates/boss-date-range-picker';
import Select from '@/lib/boss-react-select';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import React, { useState } from 'react';
import { useNavigate, useNavigation } from 'react-router-dom-v6';
import queryString from 'query-string';

type Props = {
  startDate: string;
  endDate: string;
  venuesOptions: any;
};

export function Filter(props: Props) {
  const initialStartDate = oFetch(props, 'startDate');
  const initialEndDate = oFetch(props, 'endDate');
  const venuesOptions = oFetch(props, 'venuesOptions');
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [venueId, setVenueId] = useState<number | null>(null);

  function handleDatesChange({ startDate, endDate }: any) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  function handleVenueChange(selectedVenueId: number | null) {
    setVenueId(selectedVenueId);
  }

  function handleFilter() {
    const currentQueryString = queryString.parse(window.location.search);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...currentQueryString,
      start_date: startDate,
      end_date: endDate,
      venue_id: venueId
    }, { skipNull: true, skipEmptyString: true })}`;
    return navigate(url);
  }

  return (
    <div className="boss-board__manager-filter">
      <div className="boss-form">
        <div className="boss-form__row boss-form__row_align_center boss-form__row_desktop">
          <div className="boss-form__field boss-form__field_role_control boss-form__field_layout_max">
            <p className="boss-form__label boss-form__label_type_light">
              <span className="boss-form__label-text">Filter</span>
            </p>
            <div className="date-control date-control_type_icon date-control_type_interval-fluid date-range-picker_no-label">
              <BossDateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                format={utils.uiRotaDateFormat}
                startDate={startDate}
                endDate={endDate}
                onApply={handleDatesChange}
                showClearDates={false}
              />
            </div>
          </div>
          <div className="boss-form__field boss-form__field_layout_min">
            <div className="boss-form__select boss-form__select_size_small">
              <Select
                options={venuesOptions}
                onChange={handleVenueChange}
                clearable
                ignoreCase
                simpleValue
                optionComponent={ColoredSingleOption}
                valueComponent={ColoredSingleValue}
                placeholder={'Venue Name'}
                value={venueId}
                multi={false}
              />
            </div>
          </div>
          <div className="boss-form__field boss-form__field_layout_min">
            <button
              disabled={navigation.state === 'loading'}
              className="boss-button boss-form__submit"
              onClick={handleFilter}
            >
              Update
            </button>
          </div>
        </div>
        <div className="boss-form__row boss-form__row_mobile">
          <div className="boss-form__field boss-form__field_layout_max">
            <p className="boss-form__label">
              <span className="boss-form__label-text">Filter </span>
            </p>
            <div className="date-control date-control_type_icon date-control_type_interval-fluid date-range-picker_no-label">
              <BossDateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                format={utils.uiRotaDateFormat}
                startDate={startDate}
                endDate={endDate}
                onApply={handleDatesChange}
              />
            </div>
          </div>
          <div className="boss-form__field boss-form__field_layout_min">
            <div className="boss-form__select">
              <Select
                options={venuesOptions}
                onChange={handleVenueChange}
                clearable
                ignoreCase
                simpleValue
                optionComponent={ColoredSingleOption}
                valueComponent={ColoredSingleValue}
                placeholder={'Venue Name'}
                value={venueId}
                multi={false}
              />
            </div>
            <div className="boss-form__field boss-form__field_layout_min">
              <button
                disabled={navigation.state === 'loading'}
                className="boss-button boss-form__submit"
                onClick={handleFilter}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
