import React from 'react';
import RotaChart from "./rota-chart";
import iScroll from 'boss-iscroll';
import ReactIScroll from 'react-iscroll';
import oFetch from 'o-fetch';
import { EntertainmentShiftsChart } from './entertainment-shifts-chart';
import { FaPlus } from "react-icons/fa6";
import { EntertainmentShiftList } from './entertainment-shift-list';
import { EntertainmentShiftItem } from './entertainment-shift-item';

const scrollOptions = {
  scrollX: true,
  scrollY: false,
  scrollbars: true,
  // mouseWheel: true,
  interactiveScrollbars: true,
  shrinkScrollbars: 'scale',
  fadeScrollbars: false,
  eventPassthrough: true,
  click: true
};

function RotaGraph(props) {
  const [
    staffMembers,
    staffTypes,
    rotaShifts,
    onShiftClick,
    entertainmentShifts,
  ] = oFetch(props, 'staffMembers', 'staffTypes', 'rotaShifts', 'onShiftClick', 'entertainmentShifts');
  const onNewEntertainment = oFetch(props, 'onNewEntertainment');
  const onEntertainmentShiftClick = oFetch(props, 'onEntertainmentShiftClick');
  const entertainmentShiftsExist = entertainmentShifts.length > 0;

  function getShiftColor(shift) {
    const staffMember = staffMembers.find(staff => staff.id === shift.staff_member);
    const staffType = staffTypes.find(staffType => staffType.id === staffMember.staff_type);
    return staffType.color;
  }

  return (
    <div className="boss-rotas__graphs-list">
      <div className="boss-rotas__graphs-item">
        <div className="rota-chart">
          <div className="rota-chart__inner">
            <ReactIScroll
              iScroll={iScroll}
              options={scrollOptions}
            >
              <RotaChart
                rotaShifts={rotaShifts}
                staffTypes={staffTypes}
                staff={staffMembers}
                getShiftColor={(shift) => getShiftColor(shift)}
                onShiftClick={onShiftClick}
              />
            </ReactIScroll>
            <div
              className="boss-page-dashboard__buttons-group"
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                onClick={onNewEntertainment}
                className="boss-button boss-button_role_primary-light boss-button_type_extra-small boss-page-dashboard__status-action"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FaPlus size={20} />
                <span>Add Entertainment</span>
              </button>
            </div>
            {entertainmentShiftsExist && (
              <>
                <ReactIScroll
                  iScroll={iScroll}
                  options={scrollOptions}
                >
                  <EntertainmentShiftsChart
                    entertainmentShifts={entertainmentShifts}
                    onEntertainmentShiftClick={onEntertainmentShiftClick}
                  />
                </ReactIScroll>
                <EntertainmentShiftList
                  entertainmentShifts={entertainmentShifts}
                  renderItem={(entertainmentShift) => (
                    <EntertainmentShiftItem
                      onEntertainmentShiftClick={() => onEntertainmentShiftClick(entertainmentShift)}
                      entertainmentShift={entertainmentShift}
                    />
                  )}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RotaGraph;
