import React, { useEffect, useRef } from 'react';
import d3 from 'd3';
import { RotaDate } from '@/lib/rota-date';
import safeMoment from '@/lib/safe-moment';
import _ from 'underscore';
import oFetch from 'o-fetch';

const MAX_HEIGHT_PER_PERSON = 20;
const MILLISECONDS_PER_HOUR = 60 * 60 * 1000;

function makeRotaHoursXAxis(xScale, graphXAxisHours) {
  const xAxis = d3.svg.axis();
  xAxis
    .scale(xScale)
    .orient('top')
    .ticks(graphXAxisHours.length)
    .tickSize(-100000)
    .tickFormat(function (offset) {
      return '';
    });
  return xAxis;
}

export function RotaChartInner(props) {
  const graphElRef = useRef(null);
  const tooltipElRef = useRef(null);

  useEffect(() => {
    if (graphElRef.current) {
      initGraph(graphElRef.current);
    }
  }, [props]);

  function getEntertainersOnRota() {
    const entertainersList = oFetch(props, 'entertainmentShifts')
      .map(shift => oFetch(shift, 'entertainer'));
    const sortedEntertainersList = _(entertainersList).sortBy(function (entertainer) {
      return entertainer ? oFetch(entertainer, 'entertainerType') : 'No Entertainer';
    });

    return sortedEntertainersList;
  }

  function getRotaDate() {
    const appType = oFetch(props, 'appType');
    let dExampleTimeFromTheDay;
    if (props.entertainmentShifts.length > 0) {
      const entertainmentShifts = oFetch(props, 'entertainmentShifts');
      const firstRotaShift = oFetch(entertainmentShifts, 0);
      dExampleTimeFromTheDay = safeMoment.iso8601Parse(oFetch(firstRotaShift, 'startTime')).toDate();
    } else {
      // Any date will do since there's no data anyway
      dExampleTimeFromTheDay = new Date();
    }
    return RotaDate.fromTime({ dTime: dExampleTimeFromTheDay, appType });
  }

  function generateRotaShiftList(entertainersList) {
    const rotaDate = getRotaDate();

    function calculateOffsetInHours(date) {
      const offsetInMilliseconds = new Date(date).valueOf() - rotaDate.startTime().valueOf();
      const offsetInHours = offsetInMilliseconds / MILLISECONDS_PER_HOUR;
      return offsetInHours;
    }

    const entertainmentShifts = props.entertainmentShifts.map((entertainmentShift, i) => {
      const entertainer = oFetch(entertainmentShift, 'entertainer');
      return {
        startOffset: calculateOffsetInHours(entertainmentShift.startTime),
        endOffset: calculateOffsetInHours(entertainmentShift.endTime),
        entertainer: entertainer,
        isStandby: entertainmentShift.shift_type === 'standby',
        staffIndex: i,
        originalShiftObject: entertainmentShift,
      };
    });
    return entertainmentShifts;
  }

  function getScales(innerWidth) {
    const hoursNotShownOnTheLeft = getHoursNotShownOnTheLeft();
    const hoursNotShownOnTheRight = getHoursNotShownOnTheRight();
    const hoursNotShown = getHoursNotShown();
    const graphXAxisHours = oFetch(props, 'graphXAxisHours');

    const xScale = d3.scale
      .linear()
      .domain([0, graphXAxisHours.length])
      .range([0, innerWidth]);
    const barWidthScale = d3.scale
      .linear()
      .domain([0, graphXAxisHours.length])
      .range([0, innerWidth]);

    return { xScale, barWidthScale };
  }

  function showStaffPreview(shift) {
    props.updateStaffToPreview(shift.originalShiftObject.staff_member);
  }

  function stopShowingStaffPreview(shift) {
    props.updateStaffToPreview(null);
  }

  function getHoursNotShown() {
    const hoursNotShownOnTheLeft = getHoursNotShownOnTheLeft();
    const hoursNotShownOnTheRight = getHoursNotShownOnTheRight();
    return hoursNotShownOnTheLeft + hoursNotShownOnTheRight;
  }

  function getHoursNotShownOnTheLeft() {
    const rotaDate = getRotaDate();
    const chartStartTime = props.startTime;
    const dayStartTime = rotaDate.startTime().valueOf();
    const msNotShown = chartStartTime - dayStartTime;
    return msNotShown / MILLISECONDS_PER_HOUR;
  }

  function getHoursNotShownOnTheRight() {
    const rotaDate = getRotaDate();
    const chartEndTime = props.endTime;
    const dayEndTime = rotaDate.endTime().valueOf();
    const msNotShown = dayEndTime - chartEndTime;
    return msNotShown / MILLISECONDS_PER_HOUR;
  }

  function initGraph(el) {
    if (!graphElRef.current) {
      return;
    }

    const graphEl = graphElRef.current;

    graphEl.innerHTML = '';

    const entertainersList = getEntertainersOnRota();
    const entertainmentShifts = generateRotaShiftList(entertainersList);
    const numberOfDifferentStaffMembers = entertainersList.length;

    const innerHeight = entertainersList.length * MAX_HEIGHT_PER_PERSON;
    // Using Math.floor means that there's some empty space at the top of the chart
    let heightPerPerson = Math.floor(innerHeight / numberOfDifferentStaffMembers);
    if (heightPerPerson > MAX_HEIGHT_PER_PERSON) {
      heightPerPerson = MAX_HEIGHT_PER_PERSON;
    }

    const aggregateHeightOfBars = heightPerPerson * numberOfDifferentStaffMembers;
    const verticalSpacingToPushBarsToBottom = innerHeight - aggregateHeightOfBars;

    const innerWidth = 700;
    const padding = 20;
    const width = innerWidth + padding * 2;
    const height = innerHeight + padding * 2;

    const { xScale, barWidthScale } = getScales(innerWidth);

    const chart = d3
      .select(el)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(20, 0)`);
    const graphXAxisHours = oFetch(props, 'graphXAxisHours');

    const xAxis = makeRotaHoursXAxis(xScale, graphXAxisHours);

    chart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('class', 'axis')
      .call(xAxis);

    const bar = chart
      .append('g')
      .selectAll('g')
      .data(entertainmentShifts)
      .enter()
      .append('g')
      .classed('rota-chart__shift', true)
      .attr('transform', function (rotaShift, i) {
        const transformX = xScale(rotaShift.startOffset);
        return (
          'translate(' +
          transformX +
          ',' +
          (rotaShift.staffIndex * heightPerPerson + verticalSpacingToPushBarsToBottom) +
          ')'
        );
      });
    bar
      .append('pattern')
      .attr('id', 'diagonalHatch')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 4)
      .attr('height', 4)
      .append('path')
      .attr('d', 'M-1,1 l2,-2M0,4 l4,-4M3,5 l2,-2')
      .attr('style', 'stroke:gray;stroke-width:1');
    bar
      .append('rect')
      .attr('rx', () => {
        if (heightPerPerson < 15) {
          return 0;
        }
        return 4;
      })
      .attr('ry', () => {
        if (heightPerPerson < 15) {
          return 0;
        }
        return 4;
      })
      .attr('width', function (shift) {
        const hours = shift.endOffset - shift.startOffset;
        return barWidthScale(hours);
      })
      .attr('style', function (shift) {
        return `fill:#CCC`;
      })
      .attr('height', heightPerPerson - 1);
    bar
      .append('rect')
      .attr('rx', () => {
        if (heightPerPerson < 15) {
          return 0;
        }
        return 4;
      })
      .attr('ry', () => {
        if (heightPerPerson < 15) {
          return 0;
        }
        return 4;
      })
      .attr('width', function (shift) {
        const hours = shift.endOffset - shift.startOffset;
        return barWidthScale(hours);
      })
      .attr('style', function (shift) {
        const originalShiftObject = oFetch(shift, 'originalShiftObject');
        const status = oFetch(originalShiftObject, 'status');
        if (status === 'accepted') {
          return 'fill:#83dc71';
        }
        return 'fill:#8c7ae6';
      })
      .on('mouseenter', function (entertainmentShift) {
        d3.select(this).style('fill', 'url(#diagonalHatch)');
        const tooltipDiv = tooltipElRef.current;
        if (tooltipDiv) {
          const [entertainer, originalShiftObject] = oFetch(entertainmentShift, 'entertainer', 'originalShiftObject');
          const entertainmentType = oFetch(originalShiftObject, 'entertainmentType');
          const formattedStartTime = safeMoment.iso8601Parse(entertainmentShift.originalShiftObject.startTime).format('HH:mm');
          const formattedEndTime = safeMoment.iso8601Parse(entertainmentShift.originalShiftObject.endTime).format('HH:mm');
          const html = `${entertainer ? entertainer.name : entertainmentType} (${entertainmentType}) <br/> (${formattedStartTime} - ${formattedEndTime})`;

          tooltipDiv.innerHTML = `<span class="rota-chart__shift-label">${html}</span>`;
        }
      })
      .on('mousemove', () => {
        const tooltipDiv = tooltipElRef.current;
        if (tooltipDiv) {
          d3.select(tooltipDiv).style("opacity", 1);
          d3.select(tooltipDiv)
            .style("left", d3.event.layerX + 10 + "px")
            .style("top", d3.event.layerY - 28 + "px");
        }
      })
      .on('mouseout', function (shift) {
        const originalShiftObject = oFetch(shift, 'originalShiftObject');
        const status = oFetch(originalShiftObject, 'status');

        d3.select(this).style(
          'fill',
          status === 'accepted' ? '#83dc71' : '#8c7ae6',
        );
        const tooltipDiv = tooltipElRef.current;
        if (tooltipDiv) {
          d3.select(tooltipDiv).style("opacity", 0);
        }
      })
      .on('click', function (entertainmentShift) {
        props.onEntertainmentShiftClick(oFetch(entertainmentShift, 'originalShiftObject'));
      })
      .attr('height', heightPerPerson - 1);
    bar
      .append('text')
      .text(function (entertainmentShift) {
        if (heightPerPerson < 15) {
          return '';
        }
        const [entertainer, originalShiftObject] = oFetch(entertainmentShift, 'entertainer', 'originalShiftObject');
        const entertainmentType = oFetch(originalShiftObject, 'entertainmentType');
        const formattedStartTime = safeMoment.iso8601Parse(entertainmentShift.originalShiftObject.startTime).format('HH:mm');
        const formattedEndTime = safeMoment.iso8601Parse(entertainmentShift.originalShiftObject.endTime).format('HH:mm');
        if (entertainer) {
          return `${entertainer.name} - ${entertainmentType} (${formattedStartTime} - ${formattedEndTime})`;
        }
        return `${entertainmentType} (${formattedStartTime} - ${formattedEndTime})`;
      })
      .attr('dx', 4)
      .attr('dy', 12)
      .classed('rota-chart__shift-label', true)
      .attr('text-anchor', 'middle');
  }

  return (
    <div
      className="rota-chart__content"
      style={{ position: 'relative' }}
    >
      <div
        className="tooltip2"
        ref={tooltipElRef}
      />
      <svg
        id="rota-chart"
        ref={graphElRef}
      />
    </div>
  );
}
