import safeMoment from '@/lib/safe-moment';
import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { MdSearch, MdEdit } from "react-icons/md";

type Props = {
  entertainmentShift: any,
  onEntertainmentShiftClick: () => void,
};

export function EntertainmentShiftItem(props: Props) {
  const entertainmentShift = oFetch(props, 'entertainmentShift');
  const onEntertainmentShiftClick = oFetch(props, 'onEntertainmentShiftClick');
  const rota = oFetch(entertainmentShift, 'rota');
  const venueId = oFetch(rota, 'venue');
  const date = oFetch(rota, 'date');
  const startTime = oFetch(entertainmentShift, 'startTime');
  const endTime = oFetch(entertainmentShift, 'endTime');
  const entertainmentShiftStatus = oFetch(entertainmentShift, 'status');
  const isPayable = oFetch(entertainmentShift, 'isPayable');
  // const entertainmentShiftAcceptance = oFetch(entertainmentShift, 'entertainmentShiftAcceptance');
  const displayedName = useMemo(() => {
    if (isPayable) {
      const entertainer = oFetch(entertainmentShift, 'entertainer');
      return oFetch(entertainer, 'name');
    } else {
      return oFetch(entertainmentShift, 'entertainmentTypeLabel');
    }
  }, [entertainmentShift]);
  const entertainmentShiftTime = useMemo(() => {
    return {
      formattedStartTime: safeMoment.iso8601Parse(startTime).format('HH:mm'),
      formattedEndTime: safeMoment.iso8601Parse(endTime).format('HH:mm'),
    };
  }, [startTime, endTime]);

  function renderActionButtons() {
    if (entertainmentShiftStatus === 'pending') {
      return (
        <div>
          {isPayable && (
            <p
              className="boss-table__text"
              style={{ width: 'auto' }}
            >
              <div>
                <a
                  href={`/hours_confirmation?date=${date}&venue_id=${venueId}`}
                  target='_blank'
                  rel="noreferrer"
                  className="boss-button boss-button_type_extra-small"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <MdSearch
                    style={{ marginRight: '5px' }}
                    size={20}
                  />
                  <span>View</span>
                </a>
              </div>
            </p>
          )}
          <p
            className="boss-table__text"
            style={{ width: 'auto' }}
          >
            <button
              className="boss-button boss-button_type_extra-small"
              onClick={onEntertainmentShiftClick}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <MdEdit
                style={{ marginRight: '5px' }}
                size={20}
              />
              <span>Edit</span>
            </button>
          </p>
        </div>
      );
    }
    if (entertainmentShiftStatus === 'accepted') {
      return (
        <div>
          <p
            className="boss-table__text"
            style={{ width: 'auto' }}
          >
            <a
              href={`/hours_confirmation?date=${date}&venue_id=${venueId}`}
              target='_blank'
              rel="noreferrer"
              className="boss-button boss-button_type_extra-small"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <MdSearch
                style={{ marginRight: '5px' }}
                size={20}
              />
              <span>Accepted</span>
            </a>
          </p>
          <p
            className="boss-table__text"
            style={{ width: 'auto' }}
          >
            <button
              className="boss-button boss-button_type_extra-small"
              onClick={onEntertainmentShiftClick}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <MdSearch
                style={{ marginRight: '5px' }}
                size={20}
              />
              <span>View</span>
            </button>
          </p>
        </div>
      );
    }
    if (entertainmentShiftStatus === 'active') {
      return (
        <div>
          {isPayable && (
            <p
              className="boss-table__text"
              style={{ width: 'auto' }}
            >
              <a
                href={`/hours_confirmation?date=${date}&venue_id=${venueId}`}
                target='_blank'
                rel="noreferrer"
                className="boss-button boss-button_type_extra-small"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <MdSearch
                  style={{ marginRight: '5px' }}
                  size={20}
                />
                <span>Active</span>
              </a>
            </p>
          )}
          <p
            className="boss-table__text"
            style={{ width: 'auto' }}
          >
            <button
              className="boss-button boss-button_type_extra-small"
              onClick={onEntertainmentShiftClick}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <MdSearch
                style={{ marginRight: '5px' }}
                size={20}
              />
              <span>View</span>
            </button>
          </p>
        </div>
      );
    }
    if (entertainmentShiftStatus === 'finished') {
      return (
        <div>
          {isPayable && (
            <p
              className="boss-table__text"
              style={{ width: 'auto' }}
            >
              <button
                className="boss-button boss-button_type_extra-small"
                onClick={onEntertainmentShiftClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <MdSearch
                  style={{ marginRight: '5px' }}
                  size={20}
                />
                <span>View</span>
              </button>
            </p>
          )}
        </div>
      );
    }
    return null;
  }

  function renderStatus() {
    if (entertainmentShiftStatus === 'pending') {
      return (
        <p
          className="boss-button boss-button_type_label boss-button_type_no-behavior boss-user-summary__label"
          style={{ backgroundColor: '#8e8e8e' }}
        >{entertainmentShiftStatus}</p>
      );
    }
    if (entertainmentShiftStatus === 'accepted') {
      return (
        <p
          className="boss-button boss-button_type_label boss-button_type_no-behavior boss-user-summary__label"
          style={{ backgroundColor: 'rgb(131, 220, 113)' }}
        >{entertainmentShiftStatus}</p>
      );
    }
    if (entertainmentShiftStatus === 'active') {
      return (
        <p
          className="boss-button boss-button_type_label boss-button_type_no-behavior boss-user-summary__label"
          style={{ backgroundColor: 'rgb(131, 220, 113)' }}
        >{entertainmentShiftStatus}</p>
      );
    }
    if (entertainmentShiftStatus === 'finished') {
      return (
        <p
          className="boss-button boss-button_type_label boss-button_type_no-behavior boss-user-summary__label"
          style={{ backgroundColor: 'rgb(131, 220, 113)' }}
        >{entertainmentShiftStatus}</p>
      );
    }
    return null;
  }

  return (
    <div className="boss-table__row test-staff-members-index-listing">
      <div
        className="boss-table__cell"
        style={{ minHeight: 'auto' }}
      >
        <div
          className="boss-table__info"
          data-role="name"
        >
          <p className="boss-table__label">Name</p>
          <p className="boss-table__text">{displayedName}</p>
        </div>
      </div>
      <div
        className="boss-table__cell"
        style={{ minHeight: 'auto' }}
      >
        <div
          className="boss-table__info"
          data-role="staff-type"
        >
          <p className="boss-table__label">Time</p>
          <p className="boss-table__text">{oFetch(entertainmentShiftTime, 'formattedStartTime')} - {oFetch(entertainmentShiftTime, 'formattedEndTime')}</p>
        </div>
      </div>
      <div
        className="boss-table__cell"
        style={{ minHeight: 'auto' }}
      >
        <div
          className="boss-table__info"
          data-role="staff-type"
        >
          <p className="boss-table__label">Status</p>
          <p className="boss-table__text">
            {renderStatus()}
          </p>
        </div>
      </div>
      <div
        className="boss-table__cell"
        style={{ minHeight: 'auto' }}
      >
        <div
          className="boss-table__info"
          data-role="actions"
          style={{ width: 'auto' }}
        >
          <p className="boss-table__label">Actions</p>
          {renderActionButtons()}
        </div>
      </div>
    </div>
  );
}
