import React from 'react';

type Props = {};

export function Pagination({ }: Props) {
  return (
    <div className="boss-board__manager-actions">
      <button className="boss-button boss-button_role_load-more boss-button_adjust_full-mobile">Load More</button>
    </div>
  );
}
