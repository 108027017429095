import { FORM_ERROR } from 'final-form';
// import { z } from 'zod';

export const BASE_ERROR_KEY = 'base';
type ReactFormBaseError = {
  [FORM_ERROR]?: string[],
};
export type OutputValidationErrors<T extends Partial<Record<string, string[]>>> = (Omit<T, 'base'> & ReactFormBaseError) | Record<string, never>;

export function normalizeFinalFormErrors<T extends Partial<Record<string, string[]>>>(errors: T | undefined | null):  OutputValidationErrors<T> {
  if (!errors) {
    return {};
  }
  return Object.entries(errors).reduce((acc, errorEntry) => {
    const [key, value] = errorEntry;
    if (key === BASE_ERROR_KEY) {
      return {
        ...acc,
        [FORM_ERROR]: value,
      };
    } else {
      return {
        ...acc,
        [key]: value,
      };
    }
  }, {});
}