import oFetch from 'o-fetch';
import z from 'zod';
import { RotaDate } from './rota-date';
import { extendMoment } from 'moment-range';
import moment from 'moment';
import * as Moment from 'moment';
import { BOSS_APP_TYPE, MOSS_APP_TYPE, RotaAppType } from './constants';
import safeMoment from '../safe-moment';
import { addDays } from 'date-fns';
const momentRange = extendMoment(Moment);

export const $RotaAppType = z.union([
  z.literal(BOSS_APP_TYPE),
  z.literal(MOSS_APP_TYPE),
]);

export const $Moment = z.custom<moment.Moment>((value) => {
  return moment.isMoment(value);
});

export const $WeekDates = z.object({
  monday: z.date(),
  tuesday: z.date(),
  wednesday: z.date(),
  thursday: z.date(),
  friday: z.date(),
  saturday: z.date(),
  sunday: z.date(),
}).strict();

type WeekDates = z.infer<typeof $WeekDates>;

export const $TRotaWeek = z.object({
  startDate: z.function().args().returns(z.date()),
  mStartDate: z.function().args().returns($Moment),
  endDate: z.function().args().returns(z.date()),
  mEndDate: z.function().args().returns($Moment),
  startTime: z.function().args(z.object({ appType: $RotaAppType })).returns(z.date()),
  mStartTime: z.function().args(z.object({ appType: $RotaAppType })).returns($Moment),
  endTime: z.function().args(z.object({ appType: $RotaAppType })).returns(z.date()),
  mEndTime: z.function().args(z.object({ appType: $RotaAppType })).returns($Moment),
  containsDate: z.function().args(z.object({ dDate: z.date() })).returns(z.boolean()),
  mContainsDate: z.function().args(z.object({ mDate: $Moment })).returns(z.boolean()),
  containsTime: z.function().args(z.object({ dTime: z.date(), appType: $RotaAppType })).returns(z.boolean()),
  mContainsTime: z.function().args(z.object({ mTime: $Moment, appType: $RotaAppType })).returns(z.boolean()),
  weekDates: z.function().args().returns($WeekDates),
}).strict();

export type TRotaWeek = z.infer<typeof $TRotaWeek>;

const mFromDate = (args: { mCalendarDate: moment.Moment }): TRotaWeek => {
  const mCalendarDate = oFetch(args, 'mCalendarDate');
  if (typeof mCalendarDate === 'undefined' || mCalendarDate === null) {
    throw new Error(`mCalendarDate must be supplied`);
  }
  const mDateOfDate = mCalendarDate.clone();
  mDateOfDate.set('hours', 0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  if (!mCalendarDate.isSame(mDateOfDate)) {
    throw new Error(`supplied mCalendarDate should be a date but was a time`);
  }

  const mStartDate = mCalendarDate.clone().startOf('isoWeek');
  const mEndDate = mCalendarDate.clone().endOf('isoWeek');
  const mDates = momentRange.range(mStartDate, mEndDate);

  const startDate = () => mStartDate.toDate();
  const mGetStartDate = () => mStartDate.clone();
  const endDate = () => mEndDate.toDate();
  const mGetEndDate = () => mEndDate.clone();

  const mStartTime = (args: { appType: RotaAppType }): moment.Moment => {
    const appType = oFetch(args, 'appType');

    return RotaDate.mFromDate({
      mCalendarDate: mCalendarDate,
      appType,
    }).mStartTime();
  };

  const startTime = (args: { appType: RotaAppType }): Date => {
    const appType = oFetch(args, 'appType');

    return mStartTime({ appType }).toDate();
  };

  const mEndTime = (args: { appType: RotaAppType }): moment.Moment => {
    const appType = oFetch(args, 'appType');

    return RotaDate.mFromDate({
      mCalendarDate: mCalendarDate,
      appType,
    }).mEndTime();
  };

  const endTime = (args: { appType: RotaAppType }): Date => {
    const appType = oFetch(args, 'appType');
    return mEndTime({ appType }).toDate();
  };

  const mContainsDate = (args: { mDate: moment.Moment }): boolean => {
    const mDate = oFetch(args, 'mDate');
    return mDates.contains(mDate);
  };

  const containsDate = (args: { dDate: Date }): boolean => {
    const dDate = oFetch(args, 'dDate');
    return mContainsDate({ mDate: moment(dDate) });
  };

  const mContainsTime = (args: { mTime: moment.Moment, appType: RotaAppType }): boolean => {
    const mTime = oFetch(args, 'mTime');
    const appType = oFetch(args, 'appType');
    const mDate = RotaDate.mFromTime({
      mTime,
      appType,
    }).mCalendarDate();

    return mContainsDate({ mDate });
  };

  const containsTime = (args: { dTime: Date, appType: RotaAppType }): boolean => {
    const dTime = oFetch(args, 'dTime');
    const appType = oFetch(args, 'appType');
    return mContainsTime({ mTime: moment(dTime), appType });
  };

  function weekDates(): WeekDates {
    return {
      'monday': startDate(),
      'tuesday': addDays(startDate(), 1),
      'wednesday': addDays(startDate(), 2),
      'thursday': addDays(startDate(), 3),
      'friday': addDays(startDate(), 4),
      'saturday': addDays(startDate(), 5),
      'sunday': addDays(startDate(), 6),
    };
  }

  return {
    startDate,
    mStartDate: () => mGetStartDate(),
    endDate,
    mEndDate: () => mGetEndDate(),
    startTime,
    mStartTime,
    endTime,
    mEndTime,
    containsDate,
    mContainsDate,
    containsTime,
    mContainsTime,
    weekDates,
  };
};

const fromDate = (args: { dCalendarDate: Date }): TRotaWeek => {
  const dCalendarDate = oFetch(args, 'dCalendarDate') as Date;
  return mFromDate({ mCalendarDate: moment(dCalendarDate) });
};

const sFromDate = (args: { sCalendarDate: string }): TRotaWeek => {
  const sCalendarDate = oFetch(args, 'sCalendarDate') as string;
  return mFromDate({ mCalendarDate: safeMoment.uiDateParse(sCalendarDate) });
};

const mFromTime = (args: { mTime: moment.Moment, appType: RotaAppType }): TRotaWeek => {
  const mTime = oFetch(args, 'mTime') as moment.Moment;
  if (typeof mTime === 'undefined' || mTime === null) {
    throw new Error(`mTime must be supplied`);
  }
  const appType = oFetch(args, 'appType') as RotaAppType;

  const mCalendarDate = RotaDate.mFromTime({
    mTime,
    appType,
  }).mCalendarDate();

  return mFromDate({ mCalendarDate });
};

const fromTime = (args: { dTime: Date, appType: RotaAppType }): TRotaWeek => {
  const dTime = oFetch(args, 'dTime') as Date;
  if (typeof dTime === 'undefined' || dTime === null) {
    throw new Error(`dTime must be supplied`);
  }
  const appType = oFetch(args, 'appType') as RotaAppType;

  return mFromTime({
    mTime: moment(dTime),
    appType,
  });
};

const sFromTime = (args: { sTime: string, appType: RotaAppType }): TRotaWeek => {
  const sTime = oFetch(args, 'sTime') as string;
  if (typeof sTime === 'undefined' || sTime === null) {
    throw new Error(`sTime must be supplied`);
  }
  const appType = oFetch(args, 'appType') as RotaAppType;

  return mFromTime({
    mTime: safeMoment.iso8601Parse(sTime),
    appType,
  });
};

const RotaWeek = {
  //Actual constructor
  mFromDate,
  fromDate,
  sFromDate,
  //constructs using mFromDate
  mFromTime,
  fromTime,
  sFromTime,
};

export {
  RotaWeek,
};
