import React from 'react';
import { BankDetailsListItemProps } from '../types';

export function BankDetailsListItem(props: BankDetailsListItemProps) {
  return (
    <li className="boss-details__item">
      <p className="boss-details__label boss-details__label boss-details__label_size_small">BD</p>
      <p className="boss-details__value">
        <span className="boss-details__value-part">
          {props.hasBankDetails && (
            <span className="boss-indicator">
              <span className="boss-indicator__icon boss-indicator__icon_check-bold" />
            </span>
          )}
          {!props.hasBankDetails && (
            <span className="boss-indicator">
              <span className="boss-indicator__icon boss-indicator__icon_close-bold" />
            </span>
          )}
        </span>
        {props.canUpdateBankDetails && (
          <span className="boss-details__value-part">
            <button
              onClick={props.onEditClick}
              className="boss-action boss-action_role_edit"
            />
          </span>
        )}
      </p>
    </li>
  );
}
