import { BossFormSelectNew } from '@/components/boss-form';
import { BossFormCurrencyInput } from '@/components/final-form-fields/boss-form-currency-input';
import { BossFormTimeMaskInput } from '@/components/final-form-fields/boss-form-time-mask-input';
import BigNumber from 'bignumber.js';
import oFetch from 'o-fetch';
import React, { useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';

type Props = {
  options: any[],
  processing: boolean;
  paymentType: any;
  fixedPaymentCents: any;
  paymentCentsPerHour: any;
  payRateType: any;
  paymentPerHourMinutes: any;
  disabledPaymentType: boolean;
  disabled: boolean;
};

export const PAY_RATE_FIXED = 'fixed';
export const PAY_RATE_HOURLY = 'hourly';

const payRateTypeOptions = [
  {
    value: PAY_RATE_FIXED,
    label: 'Fixed',
  },
  {
    value: PAY_RATE_HOURLY,
    label: 'Hourly',
  },
];

export function EntertainmentAcceptancePaymentTypeField(props: Props) {
  const options = oFetch(props, 'options');
  const disabled = oFetch(props, 'disabled');
  const processing = oFetch(props, 'processing');
  const disabledPaymentType = oFetch(props, 'disabledPaymentType');
  const paymentType = oFetch(props, 'paymentType');
  const payRateType = oFetch(props, 'payRateType');
  const paymentPerHourMinutes = oFetch(props, 'paymentPerHourMinutes');
  const paymentTypeValue = oFetch(paymentType, 'input.value');
  const payRateTypeValue = oFetch(payRateType, 'input.value');
  const paymentPerHourMinutesValue = oFetch(paymentPerHourMinutes, 'input.value');
  const fixedPaymentCents = oFetch(props, 'fixedPaymentCents');
  const paymentCentsPerHour = oFetch(props, 'paymentCentsPerHour');
  const paymentCentsPerHourValue = oFetch(paymentCentsPerHour, 'input.value');
  const displayPriceField = paymentTypeValue && paymentTypeValue !== 'none';
  const isHourly = payRateTypeValue === PAY_RATE_HOURLY;
  const isFixed = payRateTypeValue === PAY_RATE_FIXED;

  useEffect(() => {
    if (payRateTypeValue === PAY_RATE_FIXED) {
      paymentCentsPerHour.input.onChange(null);
      paymentPerHourMinutes.input.onChange(null);
    }
    if (payRateTypeValue === PAY_RATE_HOURLY) {
      fixedPaymentCents.input.onChange(null);
    }
  }, [payRateTypeValue]);

  useEffect(() => {
    if (paymentTypeValue === 'none') {
      payRateType.input.onChange(null);
      paymentCentsPerHour.input.onChange(null);
      fixedPaymentCents.input.onChange(null);
    }
  }, [paymentTypeValue]);

  const totalPounds = useMemo(() => {
    if (isHourly && !paymentPerHourMinutesValue.toString().includes('_')) {
      const hours = Math.floor(paymentPerHourMinutesValue / 60);
      const minutes = paymentPerHourMinutesValue % 60;
      const totalCents = hours * paymentCentsPerHourValue + ((minutes * paymentCentsPerHourValue) / 60);
      return (new BigNumber(totalCents)).div(100).toString();
    }
    return null;
  }, [paymentCentsPerHourValue, paymentPerHourMinutesValue]);

  return (
    <>
      <Field
        name={'paymentType'}
        label="Payment Type"
        options={options}
        disabled={processing || disabledPaymentType || disabled}
        component={BossFormSelectNew}
      />
      {displayPriceField && (
        <>
          <Field
            name={'payRateType'}
            label="Pay Rate Type"
            options={payRateTypeOptions}
            disabled={processing || disabled}
            clearable={false}
            component={BossFormSelectNew}
          />
          {isFixed && (
            <Field
              name={'fixedPaymentCents'}
              label="Price"
              disabled={disabled}
              component={BossFormCurrencyInput}
            />
          )}
          {isHourly && (
            <>
              <Field
                name={'paymentPerHourMinutes'}
                label="Hours"
                disabled={disabled}
                component={BossFormTimeMaskInput}
              />
              <Field
                name={'paymentCentsPerHour'}
                label="Price per hour"
                disabled={disabled}
                component={BossFormCurrencyInput}
              />
              <span className="boss-time-shift__status-count">Total: £{totalPounds || '(unknown)'}</span>
            </>
          )}
        </>
      )}
    </>
  );
}
