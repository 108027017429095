import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import oFetch from 'o-fetch';
import { IMaskInput } from 'react-imask';
import BigNumber from 'bignumber.js';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import cn from 'classnames';

type UnitType = '£' | '$' | string;
type UnitPositionType = 'left' | 'right';

type Props = {
  disabled: boolean;
  inputClassName: string;
  fieldClassName: string;
  className: string;
  placeholder: string;
  label: string;
  required: boolean;
  unitPosition: UnitPositionType;
  unitClassName: string;
  unit: UnitType;
  renderLabelAction: () => ReactNode;
  input: FieldInputProps<number, any>;
  meta: FieldMetaState<number>
};

export const BossFormCurrencyInput: FunctionComponent<Props> = (props) => {
  const disabled = oFetch(props, 'disabled');
  const inputClassName = oFetch(props, 'inputClassName');
  const className = oFetch(props, 'className');
  const fieldClassName = oFetch(props, 'fieldClassName');
  const placeholder = oFetch(props, 'placeholder');
  const label = oFetch(props, 'label');
  const required = oFetch(props, 'required');
  const renderLabelAction = oFetch(props, 'renderLabelAction');

  const unit = oFetch(props, 'unit');
  const unitPosition = oFetch(props, 'unitPosition');
  const unitClassName = oFetch(props, 'unitClassName');

  const input = oFetch(props, 'input');
  const value = oFetch(input, 'value');
  const name = oFetch(input, 'name');
  const onChange = oFetch(input, 'onChange');

  const meta = oFetch(props, 'meta');
  const touched = oFetch(meta, 'touched');
  const { error, submitError } = meta;

  const hasFieldValidationError = error || submitError;

  const inputClassNames = cn('boss-form__input', {
    [inputClassName]: !!inputClassName,
    'boss-form__input_state_error': touched && hasFieldValidationError,
  });

  const fieldClassNames = cn('boss-form__field', {
    [className]: !!className,
    [fieldClassName]: !!fieldClassName,
  });

  const unitFieldClassName = cn('boss-form__units', { [unitClassName]: unitClassName });

  const fieldDisplayValue = useMemo(() => {
    if (value === null || !value) {
      if (value === 0) {
        return '0';
      }
      return value;
    }
    return (new BigNumber(value)).div(100).toString();
  }, [value]);


  function renderInputs() {
    return (
      <IMaskInput
        mask={Number}
        name={name}
        value={fieldDisplayValue}
        disabled={disabled}
        radix={'.'}
        unmask={true}
        thousandsSeparator={''}
        className={inputClassNames}
        onAccept={(value: string) => {
          if (value === '') {
            onChange(null);
          } else {
            onChange((new BigNumber(value)).times(100).integerValue().toNumber());
          }
        }}
        placeholder={placeholder}
      />
    );
  }

  return (
    <div className={fieldClassNames}>
      {label && (
        <div className="boss-form__label-group">
          <label className="boss-form__label">
            <span className="boss-form__label-text">{`${label} ${required ? '*' : ''}`}</span>
          </label>
          {renderLabelAction()}
        </div>
      )}
      {unitPosition === 'left' ? (
        <span className={unitFieldClassName}>
          <span className="boss-form__units-value">{unit}</span>
          {renderInputs()}
        </span>
      ) : (
        <span className={unitFieldClassName}>
          {renderInputs()}
          <span className="boss-form__units-value boss-form__units-value_position_after">{unit}</span>
        </span>
      )}
    </div>
  );
};

BossFormCurrencyInput.defaultProps = {
  disabled: false,
  placeholder: '',
  inputClassName: '',
  fieldClassName: '',
  className: '',
  label: '',
  required: false,
  renderLabelAction: () => undefined,
  unitPosition: 'left',
  unitClassName: '',
  unit: '£'
};
