import { useLegacyGlobal } from '@/components/hooks-components/global';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { Tooltip } from 'react-tippy';

type Props = {
  entertainmentShift: any;
};

export function ShiftItem(props: Props) {
  const entertainmentShift = oFetch(props, 'entertainmentShift');
  const startTime = oFetch(entertainmentShift, 'startTime');
  const endTime = oFetch(entertainmentShift, 'endTime');
  const uiDate = oFetch(entertainmentShift, 'date');
  const venueId = oFetch(entertainmentShift, 'venueId');
  const entertainmentShiftAcceptance = oFetch(entertainmentShift, 'entertainmentShiftAcceptance');
  const entertainmentShiftStatusTitles = oFetch(useLegacyGlobal(), 'entertainmentShiftStatusTitles');
  const entertainmentShiftAcceptanceStatus = oFetch(entertainmentShift, 'status');
  const shiftAcceptancePayRateType = oFetch(entertainmentShiftAcceptance, 'payRateType');
  const isShiftAcceptanceFixed = shiftAcceptancePayRateType === 'fixed';
  const acceptedBy = oFetch(entertainmentShiftAcceptance, 'acceptedBy');
  const acceptedAt = oFetch(entertainmentShiftAcceptance, 'acceptedAt');
  const displayedPayment = oFetch(entertainmentShift, 'displayedPayment');
  const acceptanceDisplayedPayment = oFetch(entertainmentShiftAcceptance, 'displayedPayment');
  const paymentTypeLabel = oFetch(entertainmentShift, 'paymentTypeTitle');
  const payRateTypeLabel = oFetch(entertainmentShift, 'payRateTypeTitle');

  const paymentTypeTitle = oFetch(entertainmentShiftAcceptance, 'paymentTypeTitle');
  const payRateTypeTitle = oFetch(entertainmentShiftAcceptance, 'payRateTypeTitle');

  const entertainmentShiftPayRateType = oFetch(entertainmentShift, 'payRateType');
  const entertainmentShiftPaymentType = oFetch(entertainmentShift, 'paymentType');
  const entertainmentShiftAcceptancePayRateType = oFetch(entertainmentShiftAcceptance, 'payRateType');
  const entertainmentShiftAcceptancePaymentType = oFetch(entertainmentShiftAcceptance, 'paymentType');

  const payRateChanged = entertainmentShiftPayRateType !== entertainmentShiftAcceptancePayRateType;
  const paymentTypeChanged = entertainmentShiftPaymentType !== entertainmentShiftAcceptancePaymentType;
  const paymentChanged = displayedPayment !== acceptanceDisplayedPayment;

  const venueName = oFetch(entertainmentShift, 'venueName');

  const displayedTime = useMemo(() => {
    const startTimeFormatted = safeMoment.iso8601Parse(startTime).format('HH:mm');
    const endTimeFormatted = safeMoment.iso8601Parse(endTime).format('HH:mm');
    return ` ${startTimeFormatted} - ${endTimeFormatted}`;
  }, [startTime, endTime]);

  const formattedAcceptedAt = useMemo(() => {
    if (acceptedAt) {
      return safeMoment.iso8601Parse(acceptedAt).format(utils.commonDateFormatWithTime());
    }
    return null;
  }, []);

  function renderChangedMark(fromValue: any) {
    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={<span>Changed from <b>{fromValue}</b></span>}
      >
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  const acceptedTimeAndAmount = useMemo(() => {
    if (entertainmentShiftAcceptanceStatus !== 'accepted') {
      return null;
    }
    const displayedPayment = oFetch(entertainmentShiftAcceptance, 'displayedPayment');
    const displayedTotalPayment = oFetch(entertainmentShiftAcceptance, 'displayedTotalPayment');

    if (isShiftAcceptanceFixed) {
      return {
        displayedPayment,
        displayedTotalPayment
      };
    } else {
      return {
        displayedPayment,
        displayedTotalPayment,
        displayedAcceptedHours: oFetch(entertainmentShiftAcceptance, 'displayedAcceptedHours'),
      };
    }
  }, [entertainmentShiftAcceptanceStatus]);

  return (
    <div className="boss-timeline__record">
      <p className="boss-timeline__text boss-timeline__text_role_venue">{venueName}</p>
      <div className="boss-timeline__details">
        <div className="boss-timeline__details-header">
          <p className="boss-timeline__text boss-timeline__text_role_hours">
            <span className="boss-timeline__text-marked">Status: </span> {entertainmentShiftStatusTitles[entertainmentShiftAcceptanceStatus]}
          </p>
        </div>
        <div className="boss-timeline__details-content boss-timeline__details-content">
          <div className="boss-timeline__details-inner">
            <p className="boss-timeline__text">
              <span className="boss-timeline__text-faded">From/To:</span>{displayedTime}
            </p>
            {acceptedTimeAndAmount && (
              <>
                {!isShiftAcceptanceFixed && (
                  <>
                    <p className="boss-timeline__text">
                      <span className="boss-timeline__text-faded">Accepted: </span>{oFetch(acceptedTimeAndAmount, 'displayedAcceptedHours')}
                    </p>
                  </>
                )}
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Accepted by {acceptedBy} at {formattedAcceptedAt}</span>
                </p>
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Payment type:</span> {paymentTypeTitle} {paymentTypeChanged && renderChangedMark(paymentTypeLabel)}
                </p>
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Payrate:</span> {payRateTypeTitle} {payRateChanged && renderChangedMark(payRateTypeLabel)}
                </p>
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Payment:</span> {acceptanceDisplayedPayment} {paymentChanged && renderChangedMark(displayedPayment)}
                </p>
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Total: </span>{oFetch(acceptedTimeAndAmount, 'displayedTotalPayment')}
                </p>
              </>
            )}
            {!acceptedTimeAndAmount && (
              <>
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Payment type:</span> {paymentTypeLabel}
                </p>
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Payrate:</span> {payRateTypeLabel}
                </p>
                <p className="boss-timeline__text">
                  <span className="boss-timeline__text-faded">Payment:</span> {displayedPayment}
                </p>
              </>
            )}
            <a
              href={`/rotas/${uiDate}?venue_id=${venueId}`}
              target='_blank'
              className="boss-timeline__link boss-timeline__link_role_details"
              rel="noreferrer"
            >View Rota</a>
          </div>
        </div>
      </div>
    </div>
  );
}
