import { BossFormInput } from '@/components/boss-form';
import oFetch from 'o-fetch';
import React from 'react';
import { Field, Form } from 'react-final-form';

type Props = {
  onSubmit: () => void;
  initialValues: any;
  processing: boolean;
};

export function EntertainerContactForm(props: Props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const processing = oFetch(props, 'processing');
  const initialValues = oFetch(props, 'initialValues');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError, ...rest }) => {
        const disabled = processing || submitting;

        return (
          <>
            <Field
              name={'firstName'}
              label={'First Name'}
              disabled={disabled}
              parse={value => value}
              component={BossFormInput}
            />
            <Field
              name={'surname'}
              label={'Surname'}
              disabled={disabled}
              parse={value => value}
              component={BossFormInput}
            />
            <Field
              name={'email'}
              label={'Email'}
              disabled={disabled}
              parse={value => value}
              component={BossFormInput}
            />
            <Field
              name={'phoneNumber'}
              label={'Phone Number'}
              disabled={disabled}
              parse={value => value}
              component={BossFormInput}
            />

            <div className="boss-form__row">
              <button
                className="boss-button boss-form__submit boss-form__submit_adjust_single"
                disabled={disabled}
                onClick={handleSubmit}
                type="button"
              >
                Add Contact
              </button>
            </div>
          </>
        );
      }}
    />
  );
}
