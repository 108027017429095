import { BossFormTextArea, Fields } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import oFetch from 'o-fetch';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { EntertainmentAcceptancePaymentTypeField } from './entertainment-acceptance-payment-type-field';

type Props = {
  initialValues: any;
  onSubmit: (values: any) => void;
  onDisable: () => void;
};

export function EntertainmentShiftAcceptanceForm(props: Props) {
  const initialValues = oFetch(props, 'initialValues');
  const onSubmit = oFetch(props, 'onSubmit');
  const onDisable = oFetch(props, 'onDisable');
  const entertainmentPaymentTypeOptions = oFetch(useLegacyGlobal(), 'entertainmentPaymentTypeOptions');
  const [disabling, setDisabling] = useState(false);

  async function handleDisable() {
    setDisabling(true);
    await onDisable();
    setDisabling(false);
  }

  return (
    <Form
      onSubmit={(values) => onSubmit(values)}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError, values, ...rest }) => {
        const disable = submitting || disabling;
        return (
          <div className='boss-time-shift__form'>
            <div className="boss-time-shift__log boss-time-shift__log_state_accepted">
              <div className="boss-time-shift__time">
                <Field
                  name="acceptanceDescription"
                  label={'Description'}
                  disabled={disable}
                  component={BossFormTextArea}
                />
                <Fields
                  names={['paymentType', 'fixedPaymentCents', 'paymentCentsPerHour', 'payRateType', 'paymentPerHourMinutes']}
                  processing={false}
                  disabledPaymentType={false}
                  disabled={disable}
                  options={entertainmentPaymentTypeOptions}
                  originalRender={EntertainmentAcceptancePaymentTypeField}
                />
              </div>
              <div className="boss-time-shift__actions">
                <button
                  onClick={handleSubmit}
                  disabled={disable}
                  type="button"
                  className="boss-button boss-button_role_success boss-time-shift__button boss-time-shift__button_role_accept-shift"
                >
                  Accept
                </button>
                <button
                  className="boss-button boss-button_role_cancel boss-time-shift__button boss-time-shift__button_role_delete-shift"
                  disabled={disable}
                  onClick={handleDisable}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
