import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import BigNumber from 'bignumber.js';
import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { EntertainmentShiftAcceptanceForm } from './entertainment-shift-acceptance-form';
import { EntertainmentShiftAccepted } from './entertainment-shift-accepted';

type Props = {
  entertainmentShift: any;
  onAccept: (params: any) => void;
  onDisable: (params: any) => void;
};

export function EntertainmentShiftItem(props: Props) {
  const entertainmentShift = oFetch(props, 'entertainmentShift');
  const onAccept = oFetch(props, 'onAccept');
  const onDisable = oFetch(props, 'onDisable');
  const entertainer = oFetch(entertainmentShift, 'entertainer');
  const entertainmentShiftAcceptance = oFetch(entertainmentShift, 'entertainmentShiftAcceptance');
  const entertainmentShiftAcceptanceStatus = oFetch(entertainmentShiftAcceptance, 'status');
  const entertainmentTypeTitle = oFetch(entertainmentShift, 'entertainmentTypeTitle');
  const paymentTypeTitle = oFetch(entertainmentShift, 'paymentTypeTitle');
  const fixedPaymentCents = oFetch(entertainmentShift, 'fixedPaymentCents');
  const paymentCentsPerHour = oFetch(entertainmentShift, 'paymentCentsPerHour');
  const date = oFetch(entertainmentShift, 'date');
  const startTime = oFetch(entertainmentShift, 'startTime');
  const endTime = oFetch(entertainmentShift, 'endTime');
  const description = oFetch(entertainmentShift, 'description');
  const createdByUserName = oFetch(entertainmentShift, 'createdByUserName');
  const createdAt = oFetch(entertainmentShift, 'createdAt');
  const entertainmentShiftAccepted = entertainmentShiftAcceptanceStatus === 'accepted';

  const formattedPaymentCents = useMemo(() => {
    if (fixedPaymentCents) {
      return `£ ${(new BigNumber(fixedPaymentCents)).div(100).toString()}`;
    }
    if (paymentCentsPerHour) {
      return `£ ${(new BigNumber(paymentCentsPerHour)).div(100).toString()}/h`;
    }
    return null;
  }, [date]);

  const formattedDate = useMemo(() => {
    return safeMoment.uiDateParse(date).format(utils.fullDayFormat);
  }, [date]);

  const formattedCreatedAt = useMemo(() => {
    return safeMoment.iso8601Parse(createdAt).format(utils.commonDateFormatWithTime());
  }, [createdAt]);

  const entertainmentShiftTime = useMemo(() => {
    return {
      formattedStartTime: safeMoment.iso8601Parse(startTime).format('HH:mm'),
      formattedEndTime: safeMoment.iso8601Parse(endTime).format('HH:mm'),
    };
  }, [startTime, endTime]);

  const entertainerData = useMemo(() => {
    if (entertainer) {
      const entertainerId = oFetch(entertainer, 'id');
      const name = oFetch(entertainer, 'name');
      const entertainerTypeTitle = oFetch(entertainer, 'entertainerTypeTitle');
      return {
        entertainerId,
        name,
        entertainerTypeTitle
      };
    }
    return null;
  }, [entertainer]);

  return (
    <div className='boss-hrc boss-hrc_context_stack boss-hrc_updated'>
      <div className='boss-hrc__side'>
        <div
          className='boss-hrc__user-info'
          style={{
            padding: '15px'
          }}
        >
          <span
            className='boss-button boss-button_type_ultra-small boss-button_primary-light boss-check__header-action'
            style={{
              marginBottom: '25px'
            }}
          >ENTERTAINER</span>
          <div className='boss-user-badge'>
            <div className='boss-user-badge__profile'>
              <div className="boss-user-badge__avatar">
                <div className="boss-user-badge__avatar-inner">
                  <img
                    src="https://thispersondoesnotexist.com/"
                    alt="Hugo Lambert"
                    className="boss-user-badge__pic"
                  />
                </div>
              </div>
              {entertainerData && (
                <div className="boss-user-badge__info">
                  <h2 className="boss-user-badge__name">
                    <a
                      href={`/entertainers/${oFetch(entertainerData, 'entertainerId')}`}
                      target='__blank'
                    >{oFetch(entertainerData, 'name')}</a>
                  </h2>
                  <span
                    className="boss-button boss-button_type_small boss-user-badge__label"
                    style={{ backgroundColor: 'rgb(169, 129, 93)' }}
                  >{oFetch(entertainerData, 'entertainerTypeTitle')}</span>
                  <p className="boss-user-badge__date">{formattedDate}</p>
                </div>
              )}
              {!entertainerData && (
                <div className="boss-user-badge__info">
                  <span
                    className="boss-button boss-button_type_small boss-user-badge__label"
                    style={{ backgroundColor: 'rgb(169, 129, 93)' }}
                  >{entertainmentTypeTitle}</span>
                  <p className="boss-user-badge__date">{formattedDate}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='boss-hrc__main'>
        <div
          className="boss-hrc__header"
          style={{
            justifyContent: 'space-between'
          }}
        >
          <h3 className="boss-hrc__status">
            <span className="boss-button boss-button_type_small boss-hrc__status-label boss-button_status_clocked-out">
              {entertainmentTypeTitle.toUpperCase()}
            </span>
          </h3>
          <p className="boss-hrc__date">
            <span className="boss-hrc__date-text">{formattedDate}</span>
          </p>
        </div>
        <div className='boss-hrc__content'>
          {description && (
            <div className="boss-hrc__notes">
              <div className="boss-notes boss-notes_role_clock-in">
                <div className="boss-notes__header">
                  <h4 className="boss-notes__label">Description</h4>
                </div>
                <div className="boss-notes__content">
                  <div className="boss-notes__content-inner">
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='boss-hrc__shifts'>
            <div className='boss-hrc__shift'>
              <div className='boss-time-shift boss-time-shift_updated'>
                <div className="boss-time-shift__header">
                  <h4 className="boss-time-shift__title">Shift</h4>
                </div>
                <div className='boss-time-shift__form'>
                  <div className="boss-time-shift__log boss-time-shift__log_state_accepted">
                    <div className="boss-time-shift__group">
                      <div className="boss-time-shift__time">
                        <div className="boss-time-shift__interval">
                          <div className="boss-time-shift__hours">
                            <p className="boss-time-shift__label">
                              <span className="boss-time-shift__label-text">Start</span>
                            </p>
                            <div className="boss-time-shift__select">
                              <p className="boss-time-shift__select-value">{oFetch(entertainmentShiftTime, 'formattedStartTime')}</p>
                            </div>
                          </div>
                          <div className="boss-time-shift__delimiter" />
                          <div className="boss-time-shift__hours">
                            <p className="boss-time-shift__label">
                              <span className="boss-time-shift__label-text">End</span>
                            </p>
                            <div className="boss-time-shift__select">
                              <p className="boss-time-shift__select-value">{oFetch(entertainmentShiftTime, 'formattedEndTime')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!entertainmentShiftAccepted && (
                      <div className="boss-time-shift__actions">
                        <p className="boss-time-shift__status boss-time-shift__status_state_visible">
                          <span className="boss-time-shift__status-count boss-time-shift__status-count_role_breaks">{paymentTypeTitle}</span>
                          {formattedPaymentCents && <span className="boss-time-shift__status-count">{formattedPaymentCents}</span>}
                          <span className="boss-time-shift__status-meta">Created by {createdByUserName} at {formattedCreatedAt}</span>
                        </p>
                      </div>
                    )}
                    {entertainmentShiftAccepted && (
                      <EntertainmentShiftAccepted entertainmentShift={entertainmentShift} />
                    )}
                  </div>
                </div>
                {!entertainmentShiftAccepted && (
                  <EntertainmentShiftAcceptanceForm
                    onSubmit={(values: any) => {
                      return onAccept({ values });
                    }}
                    onDisable={() => onDisable({ values: { entertainmentShiftAcceptanceId: oFetch(entertainmentShiftAcceptance, 'id') } })}
                    initialValues={{
                      entertainmentShiftAcceptanceId: oFetch(entertainmentShiftAcceptance, 'id'),
                      paymentType: oFetch(entertainmentShiftAcceptance, 'paymentType'),
                      payRateType: oFetch(entertainmentShiftAcceptance, 'payRateType'),
                      fixedPaymentCents: oFetch(entertainmentShiftAcceptance, 'fixedPaymentCents'),
                      paymentCentsPerHour: oFetch(entertainmentShiftAcceptance, 'paymentCentsPerHour'),
                      paymentPerHourMinutes: oFetch(entertainmentShiftAcceptance, 'paymentPerHourMinutes'),
                      acceptanceDescription: oFetch(entertainmentShiftAcceptance, 'acceptanceDescription')
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
