export const INITIAL_LOAD = 'ROTA_DAILY/INITIAL_LOAD';
export const ADD_NEW_SHIFT = 'ROTA_DAILY/ADD_NEW_SHIFT';
export const CANCEL_ADD_NEW_SHIFT = 'ROTA_DAILY/CANCEL_ADD_NEW_SHIFT';
export const SHOW_GRAPH_DETAILS = 'ROTA_DAILY/SHOW_GRAPH_DETAILS';
export const CLOSE_GRAPH_DETAILS = 'ROTA_DAILY/CLOSE_GRAPH_DETAILS';
export const UPDATE_ROTA_SHIFT = 'ROTA_DAILY/UPDATE_ROTA_SHIFT';
export const DELETE_ROTA_SHIFT = 'ROTA_DAILY/DELETE_ROTA_SHIFT';
export const ADD_ROTA_SHIFT = 'ROTA_DAILY/ADD_ROTA_SHIFT';
export const ADD_ENTERTAINMET_SHIFT = 'ROTA_DAILY/ADD_ENTERTAINMET_SHIFT';
export const UPDATE_ENTERTAINMET_SHIFT = 'ROTA_DAILY/UPDATE_ENTERTAINMET_SHIFT';
export const DELETE_ENTERTAINMET_SHIFT = 'ROTA_DAILY/DELETE_ENTERTAINMET_SHIFT';
export const OPEN_MULTIPLE_SHIFT = 'ROTA_DAILY/OPEN_MULTIPLE_SHIFT';
export const CLOSE_MULTIPLE_SHIFT = 'ROTA_DAILY/CLOSE_MULTIPLE_SHIFT';
export const SET_MULTIPLE_SHIFT_STAFF_ID = 'ROTA_DAILY/SET_MULTIPLE_SHIFT_STAFF_ID';
export const SET_STAFF_TYPES_FILTER = 'ROTA_DAILY/SET_STAFF_TYPES_FILTER';
export const SET_ROTA_STATUS = 'ROTA_DAILY/SET_ROTA_STATUS';
export const UPDATE_STAFF_MEMBER_SHIFT_INFO = 'ROTA_DAILY/UPDATE_STAFF_MEMBER_SHIFT_INFO';
export const ADD_ROTA = 'ROTA_DAILY/ADD_ROTA';
export const UPDATE_TRADING_STATUS = 'ROTA_DAILY/UPDATE_TRADING_STATUS';
export const UPDATE_DELIVERY_DAY_STATUS = 'ROTA_DAILY/UPDATE_DELIVERY_DAY_STATUS';
