import React from 'react';
import { Outlet } from 'react-router-dom-v6';
import DashboardWrapper from '@/components/dashboard-wrapper';
import { ProfileCard, ProfileNav } from './components';
import ContentWrapper from '@/components/content-wrapper';
import { ProfileActions } from './components/profile-actions';
import { entertainerHeaderHook } from './entertainer-header-hook';

export function ProfileHeader() {
  const { openEditEntertainerModal, entertainer, disableEntertainer } = entertainerHeaderHook();
  return (
    <>
      <DashboardWrapper>
        <div className='boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_profile'>
          <div className='boss-page-dashboard__group'>
            <ProfileCard entertainer={entertainer} />
            <ProfileActions
              onEditProfile={openEditEntertainerModal}
              onDisableProfile={disableEntertainer}
            />
          </div>
          <ProfileNav />
        </div>
      </DashboardWrapper>
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </>
  );
}
