import React from 'react';
import { ClockLoader } from 'react-spinners';
type Props = {};

export function Fallback({ }: Props) {
  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      marginTop: '-25px',
      marginLeft: '-25px',
    }}
    >
      <ClockLoader color="#92c5f2" />
    </div >
  );
}
