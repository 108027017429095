import React from 'react';
import oFetch from 'o-fetch';

type Props = {
  entertainer: any
};

export function ProfileCardHeader(props: Props) {
  const entertainer = oFetch(props, 'entertainer');
  const entertainerTypeLabel = oFetch(entertainer, 'entertainerTypeLabel');
  const name = oFetch(entertainer, 'name');
  const description = oFetch(entertainer, 'description');

  return (
    <div className="boss-user-summary__header">
      <h2 className="boss-user-summary__name">{name}</h2>
      <span
        className="boss-button boss-button_type_small boss-button_type_no-behavior boss-user-summary__label"
      >{entertainerTypeLabel}</span>
      <p className="boss-details__value">{description}</p>
    </div>
  );
}
