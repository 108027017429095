import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import oFetch from 'o-fetch';
import { IMaskInput } from 'react-imask';
import { MaskedRange } from 'imask';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import cn from 'classnames';
import utils from '@/lib/utils';

type UnitType = '£' | '$' | string;
type UnitPositionType = 'left' | 'right';

type Props = {
  disabled: boolean;
  inputClassName: string;
  fieldClassName: string;
  className: string;
  placeholder: string;
  label: string;
  required: boolean;
  unitPosition: UnitPositionType;
  unitClassName: string;
  unit: UnitType;
  renderLabelAction: () => ReactNode;
  input: FieldInputProps<number, any>;
  meta: FieldMetaState<number>
};

export const BossFormTimeMaskInput: FunctionComponent<Props> = (props) => {
  const disabled = oFetch(props, 'disabled');
  const inputClassName = oFetch(props, 'inputClassName');
  const className = oFetch(props, 'className');
  const fieldClassName = oFetch(props, 'fieldClassName');
  const placeholder = oFetch(props, 'placeholder');
  const label = oFetch(props, 'label');
  const required = oFetch(props, 'required');
  const renderLabelAction = oFetch(props, 'renderLabelAction');

  const unit = oFetch(props, 'unit');
  const unitPosition = oFetch(props, 'unitPosition');
  const unitClassName = oFetch(props, 'unitClassName');

  const input = oFetch(props, 'input');
  const value = oFetch(input, 'value');
  const name = oFetch(input, 'name');
  const onChange = oFetch(input, 'onChange');

  const meta = oFetch(props, 'meta');
  const touched = oFetch(meta, 'touched');
  const { error, submitError } = meta;

  const hasFieldValidationError = error || submitError;

  const inputClassNames = cn('boss-form__input', {
    [inputClassName]: !!inputClassName,
    'boss-form__input_state_error': touched && hasFieldValidationError,
  });

  const fieldClassNames = cn('boss-form__field', {
    [className]: !!className,
    [fieldClassName]: !!fieldClassName,
  });

  const unitFieldClassName = cn('boss-form__units', { [unitClassName]: unitClassName });

  const fieldDisplayValue = useMemo(() => {
    if (value.toString().includes('_')) {
      return value;
    } else {
      const hours = utils.addZeroToNumber(Math.floor(value / 60));
      const minutes = utils.addZeroToNumber(value % 60);
      return `${hours}:${minutes}`;
    }
  }, [value]);


  function renderInputs() {
    return (
      <IMaskInput
        mask={'HH:MM'}
        blocks={{
          HH: {
            mask: MaskedRange,
            placeholderChar: '_',
            from: 0,
            to: 23,
            maxLength: 2
          },
          MM: {
            mask: MaskedRange,
            placeholderChar: '_',
            from: 0,
            to: 59,
            maxLength: 2
          }
        }}
        name={name}
        value={fieldDisplayValue}
        disabled={disabled}
        lazy={false}
        className={inputClassNames}
        onAccept={(value: string) => {
          if (!value.includes('_')) {
            const [hours, minutes] = value.split(':');
            onChange((Number(hours) * 60) + Number(minutes));
          } else {
            onChange(value);
          }
        }}
        placeholder={placeholder}
      />
    );
  }

  return (
    <div className={fieldClassNames}>
      {label && (
        <div className="boss-form__label-group">
          <label className="boss-form__label">
            <span className="boss-form__label-text">{`${label} ${required ? '*' : ''}`}</span>
          </label>
          {renderLabelAction()}
        </div>
      )}
      {unitPosition === 'left' ? (
        <span className={unitFieldClassName}>
          <span className="boss-form__units-value">{unit}</span>
          {renderInputs()}
        </span>
      ) : (
        <span className={unitFieldClassName}>
          {renderInputs()}
          <span className="boss-form__units-value boss-form__units-value_position_after">{unit}</span>
        </span>
      )}
    </div>
  );
};

BossFormTimeMaskInput.defaultProps = {
  disabled: false,
  placeholder: '',
  inputClassName: '',
  fieldClassName: '',
  className: '',
  label: '',
  required: false,
  renderLabelAction: () => undefined,
  unitPosition: 'left',
  unitClassName: '',
  unit: 'T'
};
