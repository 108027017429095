import React from 'react';
import oFetch from 'o-fetch';
import { ProfileCardHeader } from './profile-card-header';

type Props = {
  entertainer: any
};

export function ProfileCard(props: Props) {
  const entertainer = oFetch(props, 'entertainer');

  return (
    <div className='boss-page-dashboard__user-summary'>
      <div className='boss-user-summary'>
        <div className='boss-user-summary__side'>
          <div className='boss-user-summary__avatar'>
            <div className='boss-user-summary__avatar-inner'>
              <img
                src={"https://thispersondoesnotexist.com/"}
                className='boss-user-summary__pic'
              />
            </div>
          </div>
        </div>
        <div className='boss-user-summary__content'>
          <ProfileCardHeader entertainer={entertainer} />
        </div>
      </div>
    </div>
  );
}
