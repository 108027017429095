import { mountComponents } from '@/lib/react-rails-ujs';
import { OpsDiaryApp } from './apps/OpsDiary';
import { SecurityVenuesApp } from './apps/SecurityVenues';
import { SecurityShiftRequestsApp } from './apps/SecurityShiftRequests';
import { SecurityShiftRequestReviewsApp } from './apps/SecurityShiftRequestReviews';
import DevsApp from './apps/Devs';
import InvitesAcceptPage from './components/invites/invites-accept';
import { InvitesApp } from './apps/Invites';
import VenuesApp from './apps/Venues';
import { KPIApp } from './apps/Kpi';
import { AccessoriesApp } from './apps/Accessories';
import { AccessoryRequestsApp } from './apps/AccessoryRequests';
import { AddStaffMemberPage } from './apps/AddStaffMember';
import { AddMossStaffMemberApp } from './apps/AddMossStaffMember';
import { ApiKeysApp } from './apps/ApiKeys';
import { BonaiApp } from './apps/Bonai';
import { CheckListsApp } from './apps/CheckList';
import { EditChecklistAssignmentsApp, ViewChecklistAssignmentsApp } from './apps/ChecklistAssignments';
import { ClockInOutApp } from './apps/ClockInOut/clock-in-out-app';
import { DevTests } from './apps/DevTests';
import { FacialRecognitionSettingsApp } from './apps/FacialRecognitionSettings';
import { FinanceReportsApp } from './apps/FinanceReports';
import { HandoverPlannersMonthApp, HandoverPlannersWeekApp } from './apps/HandoverPlanners';
import { HolidayReportApp } from './apps/HolidayReport/holiday-report-app';
import { HolidayRequestsApp } from './apps/HolidayRequests';
import { HoursConfirmationApp } from './apps/HoursConfirmation';
import { IncidentReportsIndexApp, IncidentReportsShowApp } from './apps/IncidentReports';
import { MachinesIndexApp } from './apps/Machines';
import { MachinesRefloatsApp } from './apps/MachinesRefloats';
import { MaintenanceApp } from './apps/MaintenanceApp';
import { MessageBoardApp } from './apps/MessageBoardApp';
import { MossHourTagsApp } from './apps/MossHourTags';
import { MossPayRatesApp } from './apps/MossPayRates';
import {
  MossStaffMemberProfileDetailsApp,
  MossStaffMemberOwedHoursApp,
  MossStaffMemberHolidaysApp,
} from './apps/MossStaffMemberProfile';
import { MossFinanceReportsApp } from './apps/MossFinanceReports';
import { MossFinanceReportHourTagsApp } from './apps/MossFinanceReportHourTags';
import { Names } from './apps/Names';
import { NativeAppLinks } from './apps/NativeAppLinks';
import { PaymentUploadApp } from './apps/PaymentUploadApp';
import { PayRatesApp } from './apps/PayRates';
import PayrollReportsApp from './apps/PayrollReports';
import { ContactsPage, CountingApp, CountingOverviewApp } from './apps/Purple';
import { QuizRanksApp } from './apps/QuizRanks';
import { QuizCategoriesApp, QuizCategoryQuestionsApp, QuizCategoryTagsApp } from './apps/Quizzes';
import RollbarErrorTestApp from './apps/RollbarErrorTest/rollbar-error-test-app';
import { RotaDailyApp } from './apps/RotaDaily';
import { RotaOverviewApp } from './apps/RotaOverview/rota-overview-app';
import { SecurityRotaDailyApp } from './apps/SecurityRotaDaily';
import { SecurityRotaOverviewApp } from './apps/SecurityRotaOverview';
import { SecurityRotaShiftRequestsApp } from './apps/SecurityRotaShiftRequests';
import StaffHoursOverviewApp from './apps/StaffHoursOverview';
import { StaffMemberPasswordResetApp } from './apps/StaffMemberPasswordReset';
import { SecurityPayrollReportApp } from './apps/SecurityPayrollReportApp';
import { ContactsDetailsApp } from './apps/ContactsDetails';
import { SecurityPayRatesApp } from './apps/SecurityPayRates';
import { EmptyHeader } from './components/containers/header/header.js';
import { Header } from './components/boss-header';
import DetailsModal from './components/safe-checks/details-modal';
import FruitPopover from './components/shared/fruit-popover';
import { RekognitionTestApp } from './apps/RekognitionTest';
import { SiteIssuesApp } from './apps/SiteIssues';
import {
  StaffMemberAccessoriesApp,
  StaffMemberHolidaysApp,
  StaffMemberOwedHoursApp,
  StaffMemberProfileDetailsApp,
  StaffMemberProfileDisciplinaryApp,
  StaffMemberProfileHistoryApp,
  StaffMemberProfilePaymentsApp,
  StaffMemberProfileQuizzes,
  StaffMemberQuizCategoryHistoryApp,
  StaffMemberShiftsApp,
} from './apps/StaffMemberProfile';
import StaffMembersFilterForm from './apps/StaffMembers/components/staff-members-filter-form.js';
import { MossStaffMembersFilterForm } from './apps/MossStaffMembersApp/components/moss-staff-members-filter-form.js';
import { MossStaffMembersOnMossPayRatesFilter } from './apps/MossStaffMembersOnMossPayRatesFilter';
import { StaffTrackingApp } from './apps/StaffTracking';
import ColorPicker from './apps/StaffTypes/color-picker.js';
import StaffVettingApp from './apps/StaffVetting';
import { StaffMembersOnPayRatesFilter } from './apps/StaffMembersOnPayRatesFilter';
import Submissions from './apps/Submissions';
import { TrainingMaterailsApp, TrainingMaterialsCategoryApp } from './apps/TrainingMaterials';
import VenueAlertsApp from './apps/VenueAlerts';
import { VenueDashboardApp } from './apps/VenueDashboardApp';
import VenueHealthCheckApp from './apps/VenueHealthCheckApp';
import VenueHealthCheckNewResponseApp from './apps/VenueHealthCheckNewResponseApp';
import VenueHealthCheckQuestionnaireApp from './apps/VenueHealthCheckQuestionnaireApp';
import VenueHealthCheckReportApp from './apps/VenueHealthCheckReportApp';
import VouchersApp from './apps/Vouchers';
import VouchersRedeemApp from './apps/VouchersRedeem';
import VouchersUsageApp from './apps/VouchersUsage';
import WelcomeToLiverpoolCards from './apps/WelcomeToLiverpoolCards';
import WelcomeToLiverpoolClients from './apps/WelcomeToLiverpoolClients';
import { ModulrReportsApp } from './apps/ModulrReports';
import { BonusPaymentsApp, BonusPaymentsHeader } from './apps/BonusPayments';
import { BonusPaymentsAdminApp } from './apps/BonusPaymentsAdmin';
import { UsersApp } from './apps/Users';
import { UserProfileApp } from './apps/UserProfile';
import StaticBossWeekPickerPopover from '@/components/react-dates/static-boss-week-picker-popover';
import { MarketingTasksApp } from './apps/MarketingTasks';
import { ErrorReportsApp } from './apps/ErrorReports';
import { AcceptedMarketingTasksApp } from './apps/AcceptedMarketingTasks';
import { DevicesApp } from './apps/DevicesApp';
import { MarketingTasksPageOptionsApp } from './apps/MarketingTasksPageOptions';
import { MessageScheduleApp } from './apps/Aeva';
import { StaffPartyPeopleApp } from './apps/StaffPartyPeople';
import { MossPartyPeopleApp } from './apps/MossPartyPeople';
import { MossHourTagsReportApp } from './apps/MossHourTagsReport';
import { WhatsappServersApp } from './apps/WhatsappServers';
import { PublicHolidaysApp } from './apps/PublicHolidays';
import { WhatsappFeaturesApp } from './apps/WhatsappFeatures';
import { EntertainersListApp } from './apps/Entertainers/EntertainersListApp';
import { EntertainerApp } from './apps/Entertainers/EntertainerApp';
import { EntertainerHoursOverviewApp } from './apps/Entertainers/EntertainerHoursOverviewApp';
import { EntertainerFinanceReportsApp } from './apps/EntertainerFinanceReports';

document.addEventListener('DOMContentLoaded', function () {
  mountComponents({
    EntertainerHoursOverviewApp,
    EntertainerApp,
    EntertainersListApp,
    WhatsappServersApp,
    WhatsappFeaturesApp,
    PublicHolidaysApp,
    MarketingTasksPageOptionsApp,
    MessageScheduleApp,
    UsersApp,
    AcceptedMarketingTasksApp,
    UserProfileApp,
    BonusPaymentsAdminApp,
    BonusPaymentsHeader,
    BonusPaymentsApp,
    RekognitionTestApp,
    ModulrReportsApp,
    ContactsDetailsApp,
    SecurityPayRatesApp,
    KPIApp,
    TrainingMaterailsApp,
    TrainingMaterialsCategoryApp,
    CountingOverviewApp,
    ContactsPage,
    DevTests,
    NativeAppLinks,
    CountingApp,
    FacialRecognitionSettingsApp,
    QuizRanksApp,
    StaffMembersOnPayRatesFilter,
    PayRatesApp,
    Names,
    ApiKeysApp,
    StaffTrackingApp,
    FinanceReportsApp,
    SecurityRotaShiftRequestsApp,
    SecurityRotaDailyApp,
    SecurityRotaOverviewApp,
    RotaOverviewApp,
    ClockInOutApp,
    AddStaffMemberPage,
    AddMossStaffMemberApp,
    StaffMemberQuizCategoryHistoryApp,
    StaffMemberProfileDisciplinaryApp,
    StaffMemberAccessoriesApp,
    StaffMemberShiftsApp,
    StaffMemberOwedHoursApp,
    StaffMemberHolidaysApp,
    StaffMemberProfilePaymentsApp,
    StaffMemberProfileDetailsApp,
    IncidentReportsShowApp,
    IncidentReportsIndexApp,
    MachinesRefloatsApp,
    MachinesIndexApp,
    Submissions,
    CheckListsApp,
    EditChecklistAssignmentsApp,
    ViewChecklistAssignmentsApp,
    RotaDailyApp,
    StaffMemberPasswordResetApp,
    AccessoryRequestsApp,
    AccessoriesApp,
    VenuesApp,
    InvitesApp,
    InvitesAcceptPage,
    DevsApp,
    SecurityShiftRequestReviewsApp,
    SecurityShiftRequestsApp,
    SecurityVenuesApp,
    OpsDiaryApp,
    PayrollReportsApp,
    SecurityPayrollReportApp,
    WelcomeToLiverpoolCards,
    WelcomeToLiverpoolClients,
    VenueAlertsApp,
    HandoverPlannersMonthApp,
    HandoverPlannersWeekApp,
    StaffMemberProfileQuizzes,
    Header,
    VenueDashboardApp,
    QuizCategoryQuestionsApp,
    QuizCategoryTagsApp,
    QuizCategoriesApp,
    StaffMemberProfileHistoryApp,
    ColorPicker,
    HolidayReportApp,
    StaffMembersFilterForm,
    MossStaffMembersFilterForm,
    StaffVettingApp,
    HoursConfirmationApp,
    StaffHoursOverviewApp,
    RollbarErrorTestApp,
    HolidayRequestsApp,
    FruitPopover,
    EmptyHeader,
    EntertainerFinanceReportsApp,
    VenueHealthCheckApp,
    DetailsModal,
    VenueHealthCheckNewResponseApp,
    VenueHealthCheckQuestionnaireApp,
    VenueHealthCheckReportApp,
    MaintenanceApp,
    MarketingTasksApp,
    MessageBoardApp,
    MossHourTagsApp,
    MossPayRatesApp,
    MossStaffMemberProfileDetailsApp,
    MossStaffMemberHolidaysApp,
    MossStaffMemberOwedHoursApp,
    MossStaffMembersOnMossPayRatesFilter,
    MossFinanceReportsApp,
    VouchersApp,
    VouchersUsageApp,
    VouchersRedeemApp,
    PaymentUploadApp,
    BonaiApp,
    SiteIssuesApp,
    StaticBossWeekPickerPopover,
    ErrorReportsApp,
    DevicesApp,
    StaffPartyPeopleApp,
    MossPartyPeopleApp,
    MossFinanceReportHourTagsApp,
    MossHourTagsReportApp,
  });
});
