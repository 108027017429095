import React, { useState, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import oFetch from 'o-fetch';
import configureStore from '@/apps/store';
import reducers from './reducers';
import { initialLoad } from './actions';

import RotaDaily from './container/rota-daily';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { RotaDateUIUtils, RotaAppType } from '@/lib/rota-date';
import { ModalsProvider } from '@/components/hooks-components/modals';

export function RotaDailyApp(props) {
  const [store, setStore] = useState(null);
  const accessToken = oFetch(props, 'accessToken');
  const entertainers = oFetch(props, 'entertainers');
  const noEntertainerTypes = oFetch(props, 'noEntertainerTypes');
  const entertainmentTypes = oFetch(props, 'entertainmentTypes');
  const entertainmentPaymentTypes = oFetch(props, 'entertainmentPaymentTypes');
  const entertainmentShiftStatusTitles = oFetch(props, 'entertainmentShiftStatusTitles');
  const entertainmentTypeEntertainers = oFetch(props, 'entertainmentTypeEntertainers');

  const appType = RotaAppType.boss;
  const rota = oFetch(props, 'rota');

  const timeSelectOptions = useMemo(() => {
    const graphRotaDate = RotaDateUIUtils.sFromDate({ sDate: oFetch(rota, 'date'), appType });
    return graphRotaDate.getSelectOptions({ intervalSeconds: 60 * 30 });
  }, []);

  const entertainmentTypeOptions = useMemo(() => {
    return Object.entries(entertainmentTypes).map(entry => {
      const [value, label] = entry;
      return {
        value,
        label
      };
    });
  }, []);
  const entertainmentPaymentTypeOptions = useMemo(() => {
    return Object.entries(entertainmentPaymentTypes).map(entry => {
      const [value, label] = entry;
      return {
        value,
        label
      };
    });
  }, []);

  const graphXAxisHours = useMemo(() => {
    const graphRotaDate = RotaDateUIUtils.sFromDate({ sDate: oFetch(rota, 'date'), appType });
    return graphRotaDate.getGraphXAxisHours();
  }, []);

  useEffect(() => {
    window.boss.accessToken = accessToken;
  }, [accessToken]);

  useEffect(() => {
    const store = configureStore(reducers);
    store.dispatch(initialLoad({ ...props }));
    setStore(store);
  }, []);

  if (store === null) {
    return null;
  }
  return (
    <LegacyGlobalProvider global={{
      timeSelectOptions,
      appType,
      graphXAxisHours,
      entertainers,
      entertainmentTypeOptions,
      entertainmentPaymentTypeOptions,
      noEntertainerTypes,
      entertainmentShiftStatusTitles,
      entertainmentTypeEntertainers
    }}
    >
      <ModalsProvider>
        <Provider store={store}>
          <RotaDaily />
        </Provider>
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
