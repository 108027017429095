import { http, bossRequestHttp } from '@/lib/request-api';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';

export function updateTradingStatusRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [trading, venueId, date] = oFetch(values, 'trading', 'venueId', 'date');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(
    `/api/v1/venues/${venueId}/update_trading_status`,
    {
      trading,
      date,
    }
  );
}

export function createEntertainmentRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [
    entertainerId,
    startTime,
    endTime,
    description,
    entertainmentType,
    paymentType,
    fixedPaymentCents,
    paymentCentsPerHour,
    venueId,
    rotaDate,
    payRateType
  ] = oFetch(
    values,
    'entertainerId',
    'startTime',
    'endTime',
    'description',
    'entertainmentType',
    'paymentType',
    'fixedPaymentCents',
    'paymentCentsPerHour',
    'venueId',
    'rotaDate',
    'payRateType'
  );

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'entertainerId',
            'startTime',
            'endTime',
            'description',
            'entertainmentType',
            'paymentType',
            'fixedPaymentCents',
            'paymentCentsPerHour'
          ],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
      return false;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(
    `/api/v1/entertainers/entertainment_shifts`,
    {
      entertainerId,
      startTime,
      endTime,
      description,
      entertainmentType,
      paymentType,
      fixedPaymentCents,
      paymentCentsPerHour,
      venueId,
      rotaDate,
      payRateType
    }
  );
}

export function updateEntertainmentRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [
    entertainmentShiftId,
    entertainerId,
    startTime,
    endTime,
    description,
    entertainmentType,
    paymentType,
    fixedPaymentCents,
    paymentCentsPerHour,
    payRateType
  ] = oFetch(
    values,
    'entertainmentShiftId',
    'entertainerId',
    'startTime',
    'endTime',
    'description',
    'entertainmentType',
    'paymentType',
    'fixedPaymentCents',
    'paymentCentsPerHour',
    'payRateType'
  );

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'entertainerId',
            'startTime',
            'endTime',
            'description',
            'entertainmentType',
            'paymentType',
            'fixedPaymentCents',
            'paymentCentsPerHour'
          ],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
      return false;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).put(
    `/api/v1/entertainers/entertainment_shifts/${entertainmentShiftId}`,
    {
      entertainerId,
      startTime,
      endTime,
      description,
      entertainmentType,
      paymentType,
      fixedPaymentCents,
      paymentCentsPerHour,
      payRateType
    }
  );
}

export function disableEntertainmentShiftRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const entertainmentShiftId = oFetch(values, 'entertainmentShiftId');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
      const statusCode = oFetch(params, 'statusCode');
      const errors = oFetch(params, 'errors');
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
          ],
        });
        return utils.normalizeFinalFormErrors(errors);
      }
      return false;
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).delete(`/api/v1/entertainers/entertainment_shifts/${entertainmentShiftId}`);
}

export function updateDeliveryDayStatusRequest(params) {
  const [values, onSuccess, onFailure] = oFetch(params, 'values', 'onSuccess', 'onFailure');
  const [isDeliveryDay, venueId, date] = oFetch(values, 'isDeliveryDay', 'venueId', 'date');

  return bossRequestHttp({
    errorHandler(params) {
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultFailureMessage();
      onFailure();
    },
    successHandler(params) {
      const data = oFetch(params, 'data');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage();
      onSuccess(data);
    },
  }).post(
    `/api/v1/venues/${venueId}/update_delivery_day_status`,
    {
      isDeliveryDay,
      date,
    }
  );
}

export const updateStaffMemberShiftRequest = values => {
  const { shift_id } = values;
  return http({ successMessage: 'Rota Shift Updated Successfully' }).patch(
    `/api/v1/rota_shifts/${shift_id}`,
    {
      ...values,
    },
  );
};

export const deleteStaffMemberShiftRequest = shift_id => {
  return http({ successMessage: 'Rota Shift Deleted Successfully' }).delete(
    `/api/v1/rota_shifts/${shift_id}`,
  );
};

export const addShiftRequest = (values, venueId, rotaDate) => {
  return http({ successMessage: 'Rota Shift Added Successfully' }).post(
    `/api/v1/venues/${venueId}/rotas/${rotaDate}/rota_shifts`,
    {
      ...values,
    },
  );
};

export const setRotaStatusRequest = (status, venueId, rotaDate) => {
  if (!['in_progress', 'finished'].includes(status)) {
    throw Error('Wrong status');
  }
  return http().post(`/api/v1/venues/${venueId}/rotas/${rotaDate}/mark_${status}`);
};
