import { ALL_TAB_FILTER_OPTION, CASH_TAB_FILTER_OPTION, BANK_TRANSFER_TAB_FILTER_OPTION } from '../constants';
import { EntertainerFinanceReport, EntertainerFinanceReportVenuePart, TabFilterOption, CompositeEntertainerFinanceReport, DayBreakdownItem } from '../types';

export function getCompositeEntertainerFinanceReport(args: { entertainerFinanceReport: EntertainerFinanceReport; relevantVenueParts: EntertainerFinanceReportVenuePart[]; tab: TabFilterOption; }): CompositeEntertainerFinanceReport {
  const entertainerFinanceReport = args.entertainerFinanceReport;

  const result: CompositeEntertainerFinanceReport = {
    id: entertainerFinanceReport.id,
    isDone: entertainerFinanceReport.isDone,
    isCompletable: entertainerFinanceReport.isCompletable,
    pendingCalculation: entertainerFinanceReport.pendingCalculation,
    entertainerDisabled: entertainerFinanceReport.entertainerDisabled,
    entertainerHasBankDetails: entertainerFinanceReport.entertainerHasBankDetails,
    entertainerName: entertainerFinanceReport.entertainerName,
    entertainerId: entertainerFinanceReport.entertainerId,
    completedAt: entertainerFinanceReport.completedAt,
    effectiveStatus: entertainerFinanceReport.effectiveStatus,
    note: entertainerFinanceReport.note,
    mondayCashCents: 0,
    tuesdayCashCents: 0,
    wednesdayCashCents: 0,
    thursdayCashCents: 0,
    fridayCashCents: 0,
    saturdayCashCents: 0,
    sundayCashCents: 0,
    mondayBankTransferCents: 0,
    tuesdayBankTransferCents: 0,
    wednesdayBankTransferCents: 0,
    thursdayBankTransferCents: 0,
    fridayBankTransferCents: 0,
    saturdayBankTransferCents: 0,
    sundayBankTransferCents: 0,
    mondayHasDeletedHours: false,
    tuesdayHasDeletedHours: false,
    wednesdayHasDeletedHours: false,
    thursdayHasDeletedHours: false,
    fridayHasDeletedHours: false,
    saturdayHasDeletedHours: false,
    sundayHasDeletedHours: false,
    mondayValidationErrors: new Set<string>(),
    tuesdayValidationErrors: new Set<string>(),
    wednesdayValidationErrors: new Set<string>(),
    thursdayValidationErrors: new Set<string>(),
    fridayValidationErrors: new Set<string>(),
    saturdayValidationErrors: new Set<string>(),
    sundayValidationErrors: new Set<string>(),
    mondayDayBreakdownItems: [] as DayBreakdownItem[],
    tuesdayDayBreakdownItems: [] as DayBreakdownItem[],
    wednesdayDayBreakdownItems: [] as DayBreakdownItem[],
    thursdayDayBreakdownItems: [] as DayBreakdownItem[],
    fridayDayBreakdownItems: [] as DayBreakdownItem[],
    saturdayDayBreakdownItems: [] as DayBreakdownItem[],
    sundayBreakdownItems: [] as DayBreakdownItem[],
    totalCashCents: 0,
    totalBankTransferCents: 0,
    totalCents: 0,
  };

  args.relevantVenueParts.forEach((venuePart) => {
    const date = venuePart.date;
    const isMonday = date.getDay() === 1;
    const isTuesday = date.getDay() === 2;
    const isWednesday = date.getDay() === 3;
    const isThursday = date.getDay() === 4;
    const isFriday = date.getDay() === 5;
    const isSaturday = date.getDay() === 6;
    const isSunday = date.getDay() === 0;

    if (isMonday) {
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
        result.mondayCashCents += venuePart.payableCashCents;
      }
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
        result.mondayBankTransferCents += venuePart.payableBankTransferCents;
      }
      result.mondayHasDeletedHours = result.mondayHasDeletedHours || venuePart.hasDeletedHours;
      result.mondayDayBreakdownItems.push(venuePart.dayBreakdownItem);

      venuePart.validationErrors.forEach((error) => {
        result.mondayValidationErrors.add(error);
      });
    } else if (isTuesday) {
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
        result.tuesdayCashCents += venuePart.payableCashCents;
      }
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
        result.tuesdayBankTransferCents += venuePart.payableBankTransferCents;
      }
      result.tuesdayHasDeletedHours = result.tuesdayHasDeletedHours || venuePart.hasDeletedHours;
      result.tuesdayDayBreakdownItems.push(venuePart.dayBreakdownItem);

      venuePart.validationErrors.forEach((error) => {
        result.tuesdayValidationErrors.add(error);
      });
    } else if (isWednesday) {
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
        result.wednesdayCashCents += venuePart.payableCashCents;
      }
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
        result.wednesdayBankTransferCents += venuePart.payableBankTransferCents;
      }
      result.wednesdayHasDeletedHours = result.wednesdayHasDeletedHours || venuePart.hasDeletedHours;
      result.wednesdayDayBreakdownItems.push(venuePart.dayBreakdownItem);

      venuePart.validationErrors.forEach((error) => {
        result.wednesdayValidationErrors.add(error);
      });
    } else if (isThursday) {
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
        result.thursdayCashCents += venuePart.payableCashCents;
      }
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
        result.thursdayBankTransferCents += venuePart.payableBankTransferCents;
      }
      result.thursdayHasDeletedHours = result.thursdayHasDeletedHours || venuePart.hasDeletedHours;
      result.thursdayDayBreakdownItems.push(venuePart.dayBreakdownItem);

      venuePart.validationErrors.forEach((error) => {
        result.thursdayValidationErrors.add(error);
      });
    } else if (isFriday) {
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
        result.fridayCashCents += venuePart.payableCashCents;
      }
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
        result.fridayBankTransferCents += venuePart.payableBankTransferCents;
      }
      result.fridayHasDeletedHours = result.fridayHasDeletedHours || venuePart.hasDeletedHours;
      result.fridayDayBreakdownItems.push(venuePart.dayBreakdownItem);

      venuePart.validationErrors.forEach((error) => {
        result.fridayValidationErrors.add(error);
      });
    } else if (isSaturday) {
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
        result.saturdayCashCents += venuePart.payableCashCents;
      }
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
        result.saturdayBankTransferCents += venuePart.payableBankTransferCents;
      }
      result.saturdayHasDeletedHours = result.saturdayHasDeletedHours || venuePart.hasDeletedHours;
      result.saturdayDayBreakdownItems.push(venuePart.dayBreakdownItem);

      venuePart.validationErrors.forEach((error) => {
        result.saturdayValidationErrors.add(error);
      });
    } else if (isSunday) {
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
        result.sundayCashCents += venuePart.payableCashCents;
      }
      if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
        result.sundayBankTransferCents += venuePart.payableBankTransferCents;
      }
      result.sundayHasDeletedHours = result.sundayHasDeletedHours || venuePart.hasDeletedHours;
      result.sundayBreakdownItems.push(venuePart.dayBreakdownItem);

      venuePart.validationErrors.forEach((error) => {
        result.sundayValidationErrors.add(error);
      });
    } else {
      throw new Error(`Unknown day of week: ${date.getDay()}`);
    }

    if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == CASH_TAB_FILTER_OPTION) {
      result.totalCashCents += venuePart.payableCashCents;
      result.totalCents += venuePart.payableCashCents;
    }
    if (args.tab == ALL_TAB_FILTER_OPTION || args.tab == BANK_TRANSFER_TAB_FILTER_OPTION) {
      result.totalBankTransferCents += venuePart.payableBankTransferCents;
      result.totalCents += venuePart.payableBankTransferCents;
    }
  });

  return result;
}
