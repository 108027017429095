import React, { useMemo, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import MonthElement from './month-element';
import CalendarInfo from './calendar-info';
import { START_DATE, END_DATE } from 'react-dates/constants';

function BossDateRangePicker(props) {
  const [startDate, setStartDate] = useState(oFetch(props, 'startDate') || null);
  const [endDate, setEndDate] = useState(oFetch(props, 'endDate') || null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDateId, endDateId, numberOfMonths, isOutsideRangeFromProps, readOnly, showClearDates, format] = oFetch(
    props,
    'startDateId',
    'endDateId',
    'numberOfMonths',
    'isOutsideRange',
    'readOnly',
    'showClearDates',
    'format'
  );

  function getDateValue(value) {
    if (format) {
      return value ? moment(value, format, true) : value;
    } else {
      return value;
    }
  }

  const normalizedStartDate = useMemo(() => {
    return getDateValue(startDate);
  }, [startDate]);

  const normalizedEndDate = useMemo(() => {
    return getDateValue(endDate);
  }, [endDate]);

  function handleFocusChange(focusedInput) {
    // don't autoclose the datepicker
    if (focusedInput) {
      setFocusedInput(focusedInput);
    }
  }

  function handleApplyChanges() {
    if (!startDate || !endDate) {
      return;
    }
    const returnFn = oFetch(props, 'onApply')({
      startDate,
      endDate
    });
    if (returnFn && typeof returnFn.then === 'function') {
      return returnFn.then(() => {
        setFocusedInput(null);
      });
    } else {
      setFocusedInput(null);
    }
  }

  function handleCancelChanges() {
    setStartDate(oFetch(props, 'startDate') || null);
    setEndDate(oFetch(props, 'endDate') || null);
    setFocusedInput(null);
  }

  function renderMonthElement({ month, onMonthSelect, onYearSelect }) {
    return (
      <MonthElement
        month={month}
        onMonthSelect={onMonthSelect}
        onYearSelect={onYearSelect}
      />
    );
  }

  function renderCalendarInfo() {
    return (
      <CalendarInfo
        onCancel={handleCancelChanges}
        onApply={handleApplyChanges}
        startDate={normalizedStartDate}
        endDate={normalizedEndDate}
      />
    );
  }

  function isOutsideRange() { return false; }

  function initialVisibleMonth() {
    if (focusedInput === START_DATE && normalizedStartDate) {
      return normalizedStartDate;
    }
    if (focusedInput === END_DATE && normalizedEndDate) {
      return normalizedEndDate;
    }
    return moment();
  }

  function handleDatesChange({ startDate, endDate }) {
    // clear button clicked
    if (startDate === null && endDate === null) {
      oFetch(props, 'onApply')({ startDate, endDate });
    }
    if (typeof startDate !== 'undefined' && startDate !== null) {
      startDate.startOf('day');
    }
    if (typeof endDate !== 'undefined' && endDate !== null) {
      endDate.startOf('day');
    }
    setStartDate(format ? startDate.format(format) : startDate);
    setEndDate(format ? endDate.format(format) : endDate);
  }

  return (
    <DateRangePicker
      readOnly={readOnly}
      initialVisibleMonth={initialVisibleMonth}
      numberOfMonths={numberOfMonths}
      firstDayOfWeek={1}
      withPortal
      showClearDates={showClearDates}
      isOutsideRange={isOutsideRangeFromProps || isOutsideRange}
      displayFormat={utils.commonDateFormat}
      startDate={normalizedStartDate}
      endDate={normalizedEndDate}
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onFocusChange={handleFocusChange}
      startDateId={startDateId}
      endDateId={endDateId}
      renderMonthElement={renderMonthElement}
      renderCalendarInfo={renderCalendarInfo}
      hideKeyboardShortcutsPanel
      weekDayFormat="ddd"
      disabled={readOnly}
    />
  );
}

BossDateRangePicker.propTypes = {
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onApply: PropTypes.func.isRequired,
  numberOfMonths: PropTypes.number,
  isOutsideRange: PropTypes.func,
  readOnly: PropTypes.bool,
  showClearDates: PropTypes.bool,
  format: PropTypes.string,
};

BossDateRangePicker.defaultProps = {
  numberOfMonths: 1,
  isOutsideRange: null,
  readOnly: false,
  showClearDates: true,
  startDateId: 'startDateId',
  endDateId: 'endDateId',
  format: null
};

export default BossDateRangePicker;
