import React, { Fragment } from 'react';
import { StatusColumnProps } from '../types';
import { Tooltip } from 'react-tippy';
import { format } from 'date-fns';
import { HUMAN_DATE_FORMAT_WITH_TIME } from '@/lib/date-fns-formats';
import AsyncButton from 'react-async-button';
import classNames from 'classnames';
import humanize from 'string-humanize';
import { REPORT_DONE_UI_STATUS, REPORT_INCOMPLETE_UI_STATUS, REPORT_READY_UI_STATUS, REPORT_REQUIRING_UPDATE_UI_STATUS } from '../constants';

export function StatusColumn(props: StatusColumnProps): JSX.Element {
  const canCompleteReports = props.allDataExists && props.permissions.canCompleteEntertainerFinanceReports;
  const isFinanceReportWeekFinished = props.isWeekFinished;
  const entertainerFinanceReport = props.compositeEntertainerFinanceReport;

  const statusClassNames = classNames('boss-table__text', {
    'boss-table__text_role_pending-status': entertainerFinanceReport.effectiveStatus === REPORT_READY_UI_STATUS || entertainerFinanceReport.effectiveStatus === REPORT_REQUIRING_UPDATE_UI_STATUS,
    'boss-table__text_role_alert-status': entertainerFinanceReport.effectiveStatus === REPORT_INCOMPLETE_UI_STATUS,
    'boss-table__text_role_success-status': entertainerFinanceReport.effectiveStatus === REPORT_DONE_UI_STATUS,
  });

  function renderTooltip(): JSX.Element {
    if (!props.compositeEntertainerFinanceReport.completedAt) {
      throw new Error('Expected entertainerFinanceReport.completedAt to be defined');
    }
    const html = (
      <span>
        Completed at: {format(props.compositeEntertainerFinanceReport.completedAt, HUMAN_DATE_FORMAT_WITH_TIME)}
      </span>
    );

    return (
      <Tooltip
        arrow
        theme="light"
        position="bottom"
        interactive
        html={html}
      >
        <p className="boss-table__text boss-table__text_role_action boss-table__text_role_details boss-table__text_role_success-status">
          Done
        </p>
      </Tooltip>
    );
  }

  return (
    <div className="boss-table__cell">
      {props.compositeEntertainerFinanceReport.completedAt && (
        <Fragment>
          {renderTooltip()}
          {canCompleteReports && !isFinanceReportWeekFinished && (
            <div className="boss-table__actions">
              <AsyncButton
                className="boss-button boss-button_type_extra-small boss-button_role_undo-light boss-table__action"
                text="Undo Completion"
                pendingText="Processing ..."
                onClick={() => props.onUncomplete({ entertainerId: props.compositeEntertainerFinanceReport.entertainerId })}
              />
            </div>
          )}
        </Fragment>
      )}
      { !props.compositeEntertainerFinanceReport.completedAt && (
        <p className={statusClassNames}>{humanize(entertainerFinanceReport.effectiveStatus)}</p>
      )}
      { canCompleteReports && entertainerFinanceReport.isCompletable && (
        <div className="boss-table__actions">
          <AsyncButton
            className="boss-button boss-button_type_extra-small boss-button_role_confirm-light boss-table__action"
            text="Mark Completed"
            pendingText="Processing ..."
            onClick={() => props.onMarkCompleted({ entertainerId: props.compositeEntertainerFinanceReport.entertainerId })}
          />
        </div>
      )}
    </div>
  );
}