import { $BossUiDate } from '@/lib/schema-funcs';
import z from 'zod';

export const $OnDateChangeFuncArgs = z.object({
  startUIDate: $BossUiDate(z),
  endUIDate: $BossUiDate(z),
}).strict().transform((data) => {
  return {
    startDate: data.startUIDate,
    endDate: data.endUIDate,
  };
});
export const $OnDateChangeFunc = z.function().args($OnDateChangeFuncArgs).returns(z.void());
export type OnDateChangeFunc = z.infer<typeof $OnDateChangeFunc>;