import React, { useState, useEffect } from 'react';
import oFetch from 'o-fetch';
import LoadMoreButton from '@/components/load-more/load-more-button';

const PAGINATION_AMOUNT = 10;

export default function ClockingDayList(props) {
  const [clockingDays, clockingDayRenderer] = oFetch(props, 'clockingDays', 'clockingDayRenderer');
  const [currentAmount, setCurrentAmount] = useState(PAGINATION_AMOUNT);
  const totalAmount = oFetch(clockingDays, 'length');

  useEffect(() => {
    setCurrentAmount(Math.min(totalAmount, PAGINATION_AMOUNT));
  }, [totalAmount]);

  function handleLoadMoreClick() {
    setCurrentAmount(Math.min((currentAmount + PAGINATION_AMOUNT), totalAmount));
  }

  function renderList() {
    return clockingDays.slice(0, currentAmount).map(clockingDay => {
      const clockingDayId = oFetch(clockingDay, 'id');
      return React.cloneElement(clockingDayRenderer(clockingDay), {
        key: clockingDayId,
      });
    });
  }
  return (
    <>
      {renderList()}
      <LoadMoreButton
        currentAmount={currentAmount}
        totalAmount={totalAmount}
        onClick={handleLoadMoreClick}
        buttonText={"Show More"}
      />
    </>
  );
}
