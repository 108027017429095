import React from 'react';
import { UpdateBankDetailsForm } from './update-bank-details-form';
import { UpdateBankDetailsFormValues, UpdateBankDetailsModalContentProps } from '../types';

export function UpdateBankDetailsModalContent(props: UpdateBankDetailsModalContentProps) {
  const onSubmit = props.onSubmit;
  const bankDetailsLastUpdatedAt = props.bankDetailsLastUpdatedAt;
  const bankDetailsLastUpdatedBy = props.bankDetailsLastUpdatedBy;
  const altBankingFullname = props.altBankingFullname;
  const initialValues: UpdateBankDetailsFormValues = {
    bankSortCode: '',
    bankAccountNumber: '',
    altBankingFullname: altBankingFullname,
  };

  return (
    <div className="boss-modal-window__content">
      <UpdateBankDetailsForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        bankDetailsLastUpdatedAt={bankDetailsLastUpdatedAt}
        bankDetailsLastUpdatedBy={bankDetailsLastUpdatedBy}
      />
    </div>
  );
}