import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalWrapper from './modal-wrapper';
import ContentWrapper from '@/components/content-wrapper';
import RotaDailyGraphFilter from './rota-daily-graph-filter';
import RotaGraph from './rota-graph';
import AddShifts from './add-shifts';
import GraphDetails from './graph-details';
import oFetch from 'o-fetch';

import {
  showGraphDetails,
  closeGraphDetails,
  openMultipleShift,
  closeMultipleShift,
  setStaffTypesFilter,
  createEntertainment,
  updateEntertainmentShift,
  disableEntertainmentShift,
} from '../actions';
import { useModal } from '@/components/hooks-components/modals';
import { NewEntertainmentModal } from './new-entertainment-modal';
import { CloseOutsideModalWrapper } from '@/components/modal-wrappers';
import { EditEntertainmentModal } from './edit-entertainment-modal';
import { AcceptedEntertainmentShiftModal } from './accepted-entertainment-shift-modal';

const mapStateToProps = state => {
  return {
    staffTypes: state.getIn(['page', 'staffTypes']),
    sRotaDate: state.getIn(['page', 'rota', 'date']),
    rota: state.getIn(['page', 'rota']),
    rotaStatus: state.getIn(['page', 'rota', 'status']),
    rotaShifts: state.getIn(['page', 'rotaShifts']),
    entertainmentShifts: state.getIn(['page', 'entertainmentShifts']),
    staffMembers: state.getIn(['page', 'staffMembers']),
    isAddingNewShift: state.getIn(['page', 'isAddingNewShift']),
    isGraphDetailsOpen: state.getIn(['page', 'isGraphDetailsOpen']),
    graphDetails: state.getIn(['page', 'graphDetails']),
    isMultipleShift: state.getIn(['page', 'isMultipleShift']),
    staffTypesFilterIds: state.getIn(['page', 'staffTypesFilterIds']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        showGraphDetails,
        closeGraphDetails,
        openMultipleShift,
        closeMultipleShift,
        setStaffTypesFilter,
        createEntertainment,
        updateEntertainmentShift,
        disableEntertainmentShift
      },
      dispatch,
    ),
  };
};

function RotaDailyContent(props) {
  const { openProcessingModal, openModal } = useModal();
  const {
    isAddingNewShift,
    staffTypes,
    staffMembers,
    rotaStatus,
    isGraphDetailsOpen,
    graphDetails,
    isMultipleShift,
    staffTypesFilterIds,
    rotaShifts,
    rota,
  } = props;
  const actions = oFetch(props, 'actions');
  const entertainmentShifts = oFetch(props, 'entertainmentShifts');

  const [
    createEntertainment,
    openMultipleShift,
    closeMultipleShift,
    setStaffTypesFilter,
    updateEntertainmentShift,
    disableEntertainmentShift
  ] = oFetch(
    actions,
    'createEntertainment',
    'openMultipleShift',
    'closeMultipleShift',
    'setStaffTypesFilter',
    'updateEntertainmentShift',
    'disableEntertainmentShift'
  );

  const jsRota = useMemo(() => {
    return rota.toJS();
  }, [rota]);

  function handleGraphStaffTypeChange(staffTypeIds) {
    setStaffTypesFilter(staffTypeIds);
  }

  function getRotaShifts() {
    const staffTypeFilter = staffTypesFilterIds;
    if (staffTypeFilter.size === 0) {
      return rotaShifts.toJS();
    } else {
      return rotaShifts
        .filter(rotaShift => {
          const staffMember = staffMembers.find(
            staffMember => staffMember.get('id') === rotaShift.get('staff_member'),
          );
          if (!staffTypeFilter.includes(staffMember.get('staff_type'))) {
            return false;
          }
          return true;
        })
        .toJS();
    }
  }

  function handleShiftClick(shift) {
    props.actions.showGraphDetails(shift);
  }

  function closeGraphDetails() {
    props.actions.closeGraphDetails();
  }

  function openAddNewEntertainmentModal() {
    return openProcessingModal({
      onSubmit(closeModal, setProcessing, values) {
        setProcessing(true);
        return createEntertainment({
          values,
          onSuccess() {
            closeModal();
            setProcessing(false);
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        title: 'New Entertainment',
      },
      props: {
        rota: jsRota,
      },
      ModalContent: NewEntertainmentModal,
    });
  }

  function openEditEntertainmentShiftModal(entertainmentShift) {
    return openProcessingModal({
      onSubmit(closeModal, setProcessing, values) {
        setProcessing(true);
        return updateEntertainmentShift({
          values,
          onSuccess() {
            closeModal();
            setProcessing(false);
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        title: 'Edit Entertainment',
      },
      props: {
        entertainmentShift,
        onDisable: disableEntertainmentShift
      },
      ModalContent: EditEntertainmentModal,
      ModalComponent: CloseOutsideModalWrapper,
    });
  }

  function openAcceptedEntertainmentShiftModal(entertainmentShift) {
    return openModal({
      onSubmit(closeModal) {
        closeModal();
      },
      props: {
        entertainmentShift,
      },
      ModalContent: AcceptedEntertainmentShiftModal,
      ModalComponent: CloseOutsideModalWrapper,
    });
  }

  function handleEntertainmentShiftClick(entertainmenShift) {
    const entertainmenShiftStatus = oFetch(entertainmenShift, 'status');
    if (entertainmenShiftStatus === 'accepted') {
      return openAcceptedEntertainmentShiftModal(entertainmenShift);
    } else {
      return openEditEntertainmentShiftModal(entertainmenShift);
    }
  }

  const sRotaDate = oFetch(props, 'sRotaDate');


  const rotaGraphClassName = isAddingNewShift ? 'boss-rotas__graphs_state_mobile-hidden' : '';
  const addShiftsClassName = !isAddingNewShift ? 'boss-rotas__manager_state_mobile-hidden' : '';

  const entertainmentShiftsJS = useMemo(() => {
    return entertainmentShifts.toJS();
  }, [entertainmentShifts]);

  return (
    <ContentWrapper>
      <div className="boss-rotas">
        <div className={`boss-rotas__graphs ${rotaGraphClassName}`}>
          <ModalWrapper
            show={isGraphDetailsOpen}
            onClose={closeGraphDetails}
          >
            {isGraphDetailsOpen && (
              <GraphDetails
                rotaShift={graphDetails.get('originalShiftObject')}
                staffMember={graphDetails.get('staff')}
                rotaStatus={rotaStatus}
                staffTypes={staffTypes}
              />
            )}
          </ModalWrapper>
          <RotaDailyGraphFilter
            selectedTypes={props.staffTypesFilterIds.toJS()}
            staffTypes={staffTypes.toJS()}
            sRotaDate={sRotaDate}
            onStaffTypesChange={handleGraphStaffTypeChange}
          />
          <RotaGraph
            rotaShifts={getRotaShifts()}
            staffTypes={staffTypes.toJS()}
            staffMembers={staffMembers.toJS()}
            entertainmentShifts={entertainmentShiftsJS}
            onShiftClick={handleShiftClick}
            onNewEntertainment={openAddNewEntertainmentModal}
            onEntertainmentShiftClick={handleEntertainmentShiftClick}
          />
        </div>
        <AddShifts
          staffTypes={staffTypes}
          staffMembers={props.staffMembers}
          sRotaDate={sRotaDate}
          rotaStatus={rotaStatus}
          className={addShiftsClassName}
          isMultipleShift={isMultipleShift}
          onOpenMultipleShift={openMultipleShift}
          onCloseMultipleShift={closeMultipleShift}
        />
      </div>
    </ContentWrapper>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RotaDailyContent);
