import React, { useState } from 'react';
import oFetch from 'o-fetch';
const LegacyGlobalContext = React.createContext();

function LegacyGlobalProvider(props) {
  const [global, children] = oFetch(props, 'global', 'children');
  const [globalState, setGlobalState] = useState(global);

  function updateGlobalState(key, updateFunction) {
    setGlobalState((previousState) => {
      const toUpdate = previousState[key];
      if (!toUpdate) {
        throw new Error(`[${key}] doesn't exist in [${Object.keys(previousState).join(', ')}]`);
      }
      return {
        ...previousState,
        [key]: updateFunction(toUpdate)
      };
    });
  }

  return <LegacyGlobalContext.Provider value={{ ...globalState, updateGlobalState }}>{children}</LegacyGlobalContext.Provider>;
}

export { LegacyGlobalContext, LegacyGlobalProvider };
