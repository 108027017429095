import React from 'react';
import oFetch from 'o-fetch';
import cn from 'classnames';

import { usePagination, DOTS, PaginationRangeValue } from './use-pagination';

Pagination.defaultProps = {
  siblingCount: 1,
};

export type PaginationProps = {
  onPageChange: (newPage: PaginationRangeValue) => void,
  totalCount: number,
  siblingCount?: number,
  currentPage: number,
  pageSize: number,
};

export function Pagination(props: PaginationProps) {
  const onPageChange = oFetch(props, 'onPageChange');
  const totalCount = oFetch(props, 'totalCount');
  const siblingCount = oFetch(props, 'siblingCount');
  const currentPage = oFetch(props, 'currentPage');
  const pageSize = oFetch(props, 'pageSize');

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const lastPage = Math.max(
    oFetch(paginationRange, 'length'),
    0,
  );
  const showingCount = `${currentPage * pageSize + 1 - pageSize}-${currentPage * pageSize > totalCount ? totalCount : currentPage * pageSize
  }`;

  if (currentPage === 0 || oFetch(paginationRange, 'length') < 2) {
    return (
      <div className="boss-page-main__count boss-page-main__count_space_large">
        <span className="boss-page-main__count-text">Showing </span>
        <span className="boss-page-main__count-text boss-page-main__count-text_marked">{showingCount}</span>
        <span className="boss-page-main__count-text"> of </span>
        <span className="boss-page-main__count-text boss-page-main__count-text_marked">{totalCount}</span>
      </div>
    );
  }

  const previousButtonDisabled = currentPage <= 1;
  const nextButtonDisabled = currentPage >= lastPage;

  const previousButtonClassNames = cn('boss-paginator__action boss-paginator__action_role_prev', {
    'boss-paginator__action_state_disabled': previousButtonDisabled,
  });

  const nextButtonClassNames = cn('boss-paginator__action boss-paginator__action_role_next', {
    'boss-paginator__action_state_disabled': nextButtonDisabled,
  });

  function onNext() {
    onPageChange(
      Math.min(currentPage + 1, lastPage)
    );
  }

  function onPrevious() {
    onPageChange(
      Math.max(currentPage - 1, 1)
    );
  }

  function renderPages() {
    return (
      <>
        {(paginationRange ?? [1]).map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <button
                key={index}
                className="boss-paginator__action boss-paginator__action_role_delimiter"
              >
                ...
              </button>
            );
          }
          const pageClassNames = cn('boss-paginator__action', {
            'boss-paginator__action_role_current boss-paginator__action_state_active':
              pageNumber === currentPage,
          });
          return (
            <button
              key={index}
              onClick={() => onPageChange(pageNumber)}
              className={pageClassNames}
            >
              {pageNumber}
            </button>
          );
        })}
      </>
    );
  }

  return (
    <>
      <div className="boss-page-main__count boss-page-main__count_space_large">
        <span className="boss-page-main__count-text">Showing </span>
        <span className="boss-page-main__count-text boss-page-main__count-text_marked">{showingCount}</span>
        <span className="boss-page-main__count-text"> of </span>
        <span className="boss-page-main__count-text boss-page-main__count-text_marked">{totalCount}</span>
      </div>
      <nav className="boss-paginator boss-paginator_position_last">
        <button
          onClick={onPrevious}
          className={previousButtonClassNames}
          disabled={previousButtonDisabled}
        >
          Previous
        </button>
        {renderPages()}
        <button
          onClick={onNext}
          className={nextButtonClassNames}
          disabled={nextButtonDisabled}
        >
          Next
        </button>
      </nav>
    </>
  );
}
