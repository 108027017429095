import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { Tooltip } from 'react-tippy';

type Props = {
  onClose: () => void;
  entertainmentShift: any;
};

export function AcceptedEntertainmentShiftModal(props: Props) {
  const onClose = oFetch(props, 'onClose');
  const entertainmentShift = oFetch(props, 'entertainmentShift');
  const startTime = oFetch(entertainmentShift, 'startTime');
  const endTime = oFetch(entertainmentShift, 'endTime');
  const entertainer = oFetch(entertainmentShift, 'entertainer');
  const name = oFetch(entertainer, 'name');
  const entertainerType = oFetch(entertainer, 'entertainerType');
  const entertainmentShiftAcceptance = oFetch(entertainmentShift, 'entertainmentShiftAcceptance');
  const paymentTypeLabel = oFetch(entertainmentShift, 'paymentTypeLabel');
  const payRateTypeLabel = oFetch(entertainmentShift, 'payRateTypeLabel');
  const displayedPayment = oFetch(entertainmentShift, 'displayedPayment');
  const acceptedBy = oFetch(entertainmentShiftAcceptance, 'acceptedBy');
  const acceptanceDisplayedPayment = oFetch(entertainmentShiftAcceptance, 'displayedPayment');
  const paymentTypeTitle = oFetch(entertainmentShiftAcceptance, 'paymentTypeTitle');
  const payRateTypeTitle = oFetch(entertainmentShiftAcceptance, 'payRateTypeTitle');
  const acceptedAt = oFetch(entertainmentShiftAcceptance, 'acceptedAt');
  const acceptanceDescription = oFetch(entertainmentShiftAcceptance, 'acceptanceDescription');
  const entertainmentShiftAcceptanceStatus = oFetch(entertainmentShift, 'status');
  const shiftAcceptancePayRateType = oFetch(entertainmentShiftAcceptance, 'payRateType');
  const isShiftAcceptanceFixed = shiftAcceptancePayRateType === 'fixed';

  const entertainmentShiftPayRateType = oFetch(entertainmentShift, 'payRateType');
  const entertainmentShiftPaymentType = oFetch(entertainmentShift, 'paymentType');
  const entertainmentShiftAcceptancePayRateType = oFetch(entertainmentShiftAcceptance, 'payRateType');
  const entertainmentShiftAcceptancePaymentType = oFetch(entertainmentShiftAcceptance, 'paymentType');

  const payRateChanged = entertainmentShiftPayRateType !== entertainmentShiftAcceptancePayRateType;
  const paymentTypeChanged = entertainmentShiftPaymentType !== entertainmentShiftAcceptancePaymentType;
  const paymentChanged = displayedPayment !== acceptanceDisplayedPayment;

  function renderChangedMark(fromValue: any) {
    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={<span>Changed from <b>{fromValue}</b></span>}
      >
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  const displayedTime = useMemo(() => {
    const startTimeFormatted = safeMoment.iso8601Parse(startTime).format('HH:mm');
    const endTimeFormatted = safeMoment.iso8601Parse(endTime).format('HH:mm');
    return ` ${startTimeFormatted} - ${endTimeFormatted}`;
  }, [startTime, endTime]);

  const formattedAcceptedAt = useMemo(() => {
    return safeMoment.iso8601Parse(acceptedAt).format(utils.humanDateFormatWithTime());
  }, [acceptedAt]);

  const acceptedTimeAndAmount = useMemo(() => {
    const displayedPayment = oFetch(entertainmentShiftAcceptance, 'displayedPayment');
    const displayedTotalPayment = oFetch(entertainmentShiftAcceptance, 'displayedTotalPayment');

    if (isShiftAcceptanceFixed) {
      return {
        displayedPayment,
        displayedTotalPayment
      };
    } else {
      return {
        displayedPayment,
        displayedTotalPayment,
        displayedAcceptedHours: oFetch(entertainmentShiftAcceptance, 'displayedAcceptedHours'),
      };
    }
  }, [entertainmentShiftAcceptanceStatus]);

  return (
    <>
      <div className="boss-modal-window__group">
        <div className="boss-user-summary boss-user-summary_role_rotas-daily">
          <div className="boss-user-summary__side">
            <div className="boss-user-summary__avatar boss-user-summary__avatar_type_scannable">
              <div className="boss-user-summary__avatar-inner">
                <img
                  src="https://thispersondoesnotexist.com/"
                  alt=""
                  className="boss-user-summary__pic"
                />
              </div>
            </div>
          </div>
          <div className="boss-user-summary__content">
            <div className="boss-user-summary__header">
              <h2 className="boss-user-summary__name">{name}</h2>
              <p
                className="boss-button boss-button_type_label boss-button_type_no-behavior boss-user-summary__label"
                style={{ backgroundColor: 'rgb(187, 77, 255)' }}
              >{entertainerType}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='boss-modal-window__group'>
        <p
          className="boss-modal-window__group-label"
          style={{ marginBottom: '10px' }}
        >
          <span>Shift</span>
        </p>
        <div className='boss-summary'>
          <p className="boss-timeline__text">
            <span className="boss-timeline__text-faded">From/To:</span> {displayedTime}
          </p>
        </div>
      </div>
      <div className='boss-modal-window__group'>
        <p
          className="boss-modal-window__group-label"
          style={{ marginBottom: '10px' }}
        >
          <span>Acceptance</span>
        </p>
        <div className='boss-summary'>
          {!isShiftAcceptanceFixed && (
            <p className="boss-timeline__text">
              <span className="boss-timeline__text-faded">Accepted: </span>{oFetch(acceptedTimeAndAmount, 'displayedAcceptedHours')}
            </p>
          )}
          <p className="boss-timeline__text">
            <span className="boss-timeline__text-faded">Accepted By: </span>{acceptedBy} at {formattedAcceptedAt}
          </p>
          <p className="boss-timeline__text">
            <span className="boss-timeline__text-faded">Payment type:</span> {paymentTypeTitle} {paymentTypeChanged && renderChangedMark(paymentTypeLabel)}
          </p>
          <p className="boss-timeline__text">
            <span className="boss-timeline__text-faded">Payrate:</span> {payRateTypeTitle} {payRateChanged && renderChangedMark(payRateTypeLabel)}
          </p>
          <p className="boss-timeline__text">
            <span className="boss-timeline__text-faded">Payment:</span> {acceptanceDisplayedPayment} {paymentChanged && renderChangedMark(displayedPayment)}
          </p>
        </div>
      </div>
      <div className="boss-modal-window__form">
        <div className="boss-form">
          {acceptanceDescription && (
            <div className="boss-hrc__notes">
              <div className="boss-notes boss-notes_role_clock-in">
                <div className="boss-notes__header">
                  <h4 className="boss-notes__label">Note</h4>
                </div><div className="boss-notes__content">
                  <div className="boss-notes__content-inner">
                    <p>{acceptanceDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="boss-form__field boss-form__field_role_controls">
            <button
              type="button"
              onClick={onClose}
              className="boss-button boss-button_role_success boss-form__button_adjust_max boss-form__button_adjust_row"
            >Dismiss</button>
          </div>
        </div>
      </div>
    </>
  );
}
