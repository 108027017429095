import React, { useMemo } from 'react';
import { RouterProvider } from "react-router-dom-v6";
import oFetch from 'o-fetch';
import { createProfileRoutes } from './routes';
import { ModalsProvider } from '@/components/hooks-components/modals';
import { LegacyGlobalProvider } from '@/components/hooks-components/global';
import { $Entertainer, $EntertainerTypesHash, $PermissionsData } from './pages/profile/types';
import { z } from 'zod';
import getVenueColor from '@/lib/get-venue-color';

export function EntertainerApp(props: any) {
  const rawEntertainer = oFetch(props, 'entertainer');
  const venues = oFetch(props, 'venues');
  const isAdminPlus = oFetch(props, 'isAdminPlus');
  const entertainmentShiftStatusTitles = oFetch(props, 'entertainmentShiftStatusTitles');
  const entertainer = $Entertainer.parse(rawEntertainer);
  const rawAccessToken = oFetch(props, 'accessToken');
  const accessToken = z.string().min(5).parse(rawAccessToken);
  const rawEntertainerTypes = oFetch(props, 'entertainerTypes');
  const entertainerTypes = $EntertainerTypesHash.parse(rawEntertainerTypes);
  const rawPermissionsData = oFetch(props, 'permissionsData');
  const permissionsData = $PermissionsData.parse(rawPermissionsData);

  window.boss.accessToken = accessToken;

  const entertainerTypesOptions = useMemo(() => {
    return Object.entries(entertainerTypes).map((entry) => {
      const [value, label] = entry;
      return {
        value,
        label
      };
    });
  }, []);

  const router = useMemo(() => {
    return createProfileRoutes();
  }, []);

  const venuesOptions = useMemo(() => {
    return venues.map((venue: any) => {
      return {
        value: `${oFetch(venue, 'type')}_${oFetch(venue, 'id')}`,
        label: oFetch(venue, 'name'),
        color: getVenueColor(oFetch(venue, 'id'))
      };
    });
  }, []);

  return (
    <LegacyGlobalProvider global={
      {
        entertainerTypesOptions,
        venuesOptions,
        entertainmentShiftStatusTitles,
        isAdminPlus,
        permissionsData,
        entertainer: entertainer
      }
    }
    >
      <ModalsProvider>
        <RouterProvider router={router} />
      </ModalsProvider>
    </LegacyGlobalProvider>
  );
}
