import { normalizeFinalFormErrors } from "@/lib/normalize-final-form-errors";
import { bossRequestHttp } from "@/lib/request-api";
import oFetch from "o-fetch";

type EntertainerCreateValues = {
  name: string;
  description: string;
  entertainerType: string;
};

type EntertainerCreateParams = {
  values: EntertainerCreateValues;
  queryParams: any,
  onSuccess: (entertainers: any[], pagination: any) => void;
  onFailure: () => void;
};

export function createEntertainerRequest(params: EntertainerCreateParams) {
  const values = oFetch(params, 'values');
  const queryParams = oFetch(params, 'queryParams');
  const onSuccess = oFetch(params, 'onSuccess');
  const onFailure = oFetch(params, 'onFailure');

  return bossRequestHttp({
    successHandler(params: any) {
      const data = oFetch(params, 'data');
      const entertainers = oFetch(data, 'entertainers');
      const pagination = oFetch(data, 'pagination');
      const globalNotifications = oFetch(params, 'globalNotifications');
      globalNotifications.showDefaultSuccessMessage({ message: 'Add New Entertainer Success' });
      onSuccess(entertainers, pagination);
    },
    errorHandler(params: any) {
      const statusCode = oFetch(params, 'statusCode');
      const globalNotifications = oFetch(params, 'globalNotifications');

      const errors = oFetch(params, 'errors');
      globalNotifications.showDefaultFailureMessage({ message: 'Add New Entertainer Failed' });
      const supportedKeyChecker = oFetch(params, 'supportedKeyChecker');

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [
            'base',
            'name',
            'entertainerType',
            'contacts',
            'bankAccountNumber',
            'bankSortCode',
            'altBankingFullname'
          ],
        });
        const normalizedErrors = normalizeFinalFormErrors(errors);
        onFailure();
        return normalizedErrors;
      }
      return false;
    }
  }).post('/api/v1/entertainers', { ...values, queryParams });
}
