import React from 'react';
import { HolidaysHeader } from './components';
type Props = {};

export function Payments(props: Props) {
  return (
    <section className='boss-board'>
      <HolidaysHeader />
    </section>
  );
}
