import React, { useState, Fragment } from "react";
import Popover from 'react-popover';
import { format } from 'date-fns';
import classNames from 'classnames';
import { EntertainerFinanceReportsFilter } from "./entertainer-finance-reports-filter";
import { HUMAN_DATE_FORMAT_WITH_TIME, UI_DATE_FORMAT } from '@/lib/date-fns-formats';
import { DashboardProps } from "../types";
import BossWeekPicker from "@/components/react-dates/boss-week-picker";
import { $OnDateChangeFuncArgs } from "@/types/boss-week-picker-types";
import {
  entertainerFinanceReports as importedEntertainerFinanceReports,
} from '@/lib/app-routes';
const appRoutes = {
  entertainerFinanceReports: importedEntertainerFinanceReports,
} as const;
import { ALL_TAB_FILTER_OPTION } from '../constants';
import utils from "@/lib/utils";
import AsyncButton from 'react-async-button';
import { Tooltip } from "react-tippy";
import { formatAsCurrency } from "@/lib/currency-helpers";

export function Dashboard(props: DashboardProps): JSX.Element {
  const isFinanceReportWeekFinished = props.entertainerFinanceReportWeek.isFinished;
  const canFinishFinanceReportWeek = props.permissions.canFinishEntertainerFinanceReportWeek;
  const canFinishEntertainerFinanceReportWeek = props.permissions.canFinishEntertainerFinanceReportWeek
  const startDate = props.entertainerFinanceReportWeek.startDate;
  const endDate = props.entertainerFinanceReportWeek.endDate;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const formattedStartDate = format(startDate, UI_DATE_FORMAT);
  const formattedEndDate = format(endDate, UI_DATE_FORMAT);
  const popoverClass = classNames({
    'boss-page-dashboard__meta-item boss-page-dashboard__meta-item_type_faded boss-page-dashboard__meta-item_role_date boss-page-dashboard__meta-item_role_popover': true,
    'boss-page-dashboard__meta-item_state_opened': isCalendarOpen,
  });
  const entertainerFinanceReportWeek = props.entertainerFinanceReportWeek;

  let payableReportCount = 0;
  let payableReportCompletedCount = 0;
  let payableReportWithBankDetailsCount = 0;
  let cashCents = 0;
  let bankTransferCents = 0;
  props.compositeEntertainerFinanceReports.forEach((report) => {
    if (report.totalBankTransferCents > 0) {
      payableReportCount += 1;
      if (report.isDone) {
        payableReportCompletedCount += 1;
        if (report.entertainerHasBankDetails) {
          payableReportWithBankDetailsCount += 1;
        }
      }
    }
    cashCents += report.totalCashCents;
    bankTransferCents += report.totalBankTransferCents;
  });
  const totalCents =  cashCents + bankTransferCents;

  async function onFinishFinanceReportWeek(): Promise<void> {
    console.log(`TODO: now on FinishFinanceReportWeek`);
  }
  async function onUndoFinanceReportWeek(): Promise<void> {
    console.log(`TODO: now on UndoFinanceReportWeek`);
  }

  function onDateChange(rawArgs: unknown): void {
    const args = $OnDateChangeFuncArgs.parse(rawArgs);
    const href = appRoutes.entertainerFinanceReports({
      weekStart: args.startDate,
      tab: ALL_TAB_FILTER_OPTION,
    });
    window.location.href = href;
  }

  function renderCalendar(): JSX.Element {
    return (
      <div className="boss-popover__inner">
        <BossWeekPicker
          selectionStartUIDate={format(startDate, UI_DATE_FORMAT)}
          onChange={(selection: unknown) => onDateChange(selection)}
          onCancelClick={togglePopover}
        />
      </div>
    );
  }

  function togglePopover(): void {
    setIsCalendarOpen(!isCalendarOpen);
  }

  function renderSummarySection(): JSX.Element {
    const formattedPayableReportBreakdown = props.allDataExists ? `${payableReportCompletedCount} / ${payableReportCount}` : 'N/A';
    const formattedWithBankDetailsReportBreakdown = props.allDataExists ?  `${payableReportWithBankDetailsCount} / ${payableReportCompletedCount}` : 'N/A';

    return (
      <div
      className="boss-page-dashboard__group"
        style={{
          border: '1px solid #e5e5e5',
          borderRadius: '20px',
          padding: '10px',
        }}
      >
        <h3>
          <u>Totals</u>
        </h3>

        <h4>Cash</h4>
        <div className="boss-page-dashboard__group">
          <div className="boss-table boss-table_page_finance-reports-overview">
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_flow">
                    <div className="boss-number__content">
                      <h3 className="boss-number__value boss-number__value_size_s">
                        {formatAsCurrency({ cents: cashCents })}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4>Bank Payment</h4>
        <div className="boss-page-dashboard__group">
          <div className="boss-table boss-table_page_finance-reports-overview">
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_flow">
                    <div className="boss-number__content">
                      <h3 className="boss-number__value boss-number__value_size_s">
                        {formatAsCurrency({ cents: bankTransferCents })}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4>Grand Total</h4>
        <div className="boss-page-dashboard__group">
          <div className="boss-table boss-table_page_finance-reports-overview">
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_flow">
                    <div className="boss-number__content">
                      <h3 className="boss-number__value boss-number__value_size_s">
                        {formatAsCurrency({ cents: totalCents })}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boss-page-dashboard__group">
          <div className="boss-table boss-table_page_finance-reports-overview">
            <div className="boss-table__row">
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_flow">
                    <div className="boss-number__content">
                      <p className="boss-number__label boss-number__label_position_before">
                        Payable Completed:
                      </p>
                      <h3 className="boss-number__value boss-number__value_size_s">
                        { formattedPayableReportBreakdown }
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boss-table__cell">
                <div className="boss-table__info">
                  <div className="boss-number boss-number_layout_flow">
                    <div className="boss-number__content">
                      <p className="boss-number__label boss-number__label_position_before">
                        Setup for Payment:
                      </p>
                      <h3 className="boss-number__value boss-number__value_size_s">
                        { formattedWithBankDetailsReportBreakdown }
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderFinanceReportWeekStatus(): JSX.Element {
    if (entertainerFinanceReportWeek.isFinished) {
      if (!entertainerFinanceReportWeek.finishedAt) {
        throw new Error('entertainerFinanceReportWeek.finishedAt is required');
      }
      if (!entertainerFinanceReportWeek.finishedByName) {
        throw new Error('entertainerFinanceReportWeek.finishedBy is required');
      }
      return (
        <Fragment>
          <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_status">
            <span className="boss-page-dashboard__meta-text">Week Status: </span>
            <span className="boss-page-dashboard__meta-text boss-page-dashboard__meta-text-accent-green">
              &nbsp;FINISHED
            </span>
            { canFinishEntertainerFinanceReportWeek && (
              <AsyncButton
                className="boss-button boss-button_type_extra-small boss-button_role_undo-light boss-table__action"
                style={{ marginLeft: '15px' }}
                text="Undo Completion"
                pendingText="Processing ..."
                onClick={onUndoFinanceReportWeek}
              />
            )}
          </p>
          <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_details">
            <span className="boss-page-dashboard__meta-text">
              Finished by {entertainerFinanceReportWeek.finishedByName} at {format(entertainerFinanceReportWeek.finishedAt, HUMAN_DATE_FORMAT_WITH_TIME)}
            </span>
          </p>
        </Fragment>
      );
    } else {
      const financeReportWeekStatusText = utils.capitalizeFirstCharacter(entertainerFinanceReportWeek.uiStatus);
      return (
        <p className="boss-page-dashboard__meta-item boss-page-dashboard__meta-item_role_status">
          <span className="boss-page-dashboard__meta-text">Week Status: </span>
          <span className="boss-page-dashboard__meta-text">
            &nbsp;{financeReportWeekStatusText.toUpperCase()}
          </span>
      </p>
      );
    }
  }

  function renderMarkFinanceReportWeekButton(): JSX.Element | null {
    if (isFinanceReportWeekFinished) {
      return null;
    }
    if (!canFinishFinanceReportWeek) {
      const content = <span>Cannot mark as finished while incomplete finance reports exist.</span>;
      return (
        <Tooltip
          arrow
          theme="dark"
          position="bottom"
          interactive
          html={content}
        >
          <button
            className="boss-button boss-button_role_mark-finished boss-page-dashboard__button"
            disabled={true}
          >
            Mark Finished
          </button>
        </Tooltip>
      );
    }
    return (
      <AsyncButton
        className="boss-button boss-button_role_mark-finished boss-page-dashboard__button"
        text="Mark Finished"
        pendingText="Processing ..."
        onClick={onFinishFinanceReportWeek}
      />
    );
  }

  return (
    <div className="boss-page-main__dashboard">
      <div className="boss-page-main__inner">
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_finance-reports">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">{props.title}</h1>
          </div>

          { renderSummarySection() }

          <div className="boss-page-dashboard__group">
            <div className="boss-page-dashboard__sub-group">
              <div className="boss-page-dashboard__meta">
                <Popover
                  isOpen={isCalendarOpen}
                  body={renderCalendar()}
                  appendTarget={document.body}
                  place="below"
                  tipSize={0.01}
                  onOuterAction={togglePopover}
                  className="boss-popover boss-popover_context_dashboard-week-picker boss-popover_state_opened"
                  style={{ marginTop: '10px' }}
                >
                  <p
                    className={popoverClass}
                    onClick={togglePopover}
                  >
                    <span className="boss-page-dashboard__meta-text">{formattedStartDate}</span>
                    {' - '}
                    <span className="boss-page-dashboard__meta-text">{formattedEndDate}</span>
                  </p>
                </Popover>
              </div>
              <div className="boss-page-dashboard__meta">
                {renderFinanceReportWeekStatus()}
              </div>
            </div>
            <div className="boss-page-dashboard__buttons-group">{renderMarkFinanceReportWeekButton()}</div>
            <div className="boss-page-dashboard__controls-group">
              <EntertainerFinanceReportsFilter
                venueOptions={props.venueOptions}
                activeFilter={props.filter}
                onTabChange={props.onTabChange}
                onVenueIdsChange={props.onVenueIdsChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}