import React from 'react';
import {
  createBrowserRouter,
} from 'react-router-dom-v6';

import { ProfileHeader } from './entertainer-header';
import { Payments, ProfilePage, ShiftsPage } from './pages';
import LoadingRoute, { loader, shiftsLoader } from './loader';
import { paymentsPageInitialLoad } from './pages/payments/requests';
import { profilePageInitialLoad } from './pages/profile/requests';

export function createProfileRoutes() {
  return createBrowserRouter([
    {
      path: `/entertainers/:id`,
      element: <ProfileHeader />,
      children: [
        {
          index: true,
          loader: loader({ pageInitialLoadRequest: profilePageInitialLoad }),
          element: <LoadingRoute Component={ProfilePage} />,
        },
        {
          path: "shifts",
          loader: shiftsLoader,
          element: <LoadingRoute Component={ShiftsPage} />,
        },
        {
          path: "payments",
          loader: loader({ pageInitialLoadRequest: paymentsPageInitialLoad }),
          element: <LoadingRoute Component={Payments} />,
        },
      ],
    },
  ]);
}
