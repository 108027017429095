import React from 'react';

type Props = {};

export function HolidaysHeader({ }: Props) {
  return (
    <header className="boss-board__header">
      <h2 className="boss-board__title">Holidays and holiday requests</h2>
      <div className="boss-board__button-group">
        <p className="boss-button boss-button_role_add">Add Holiday</p>
      </div>
    </header>
  );
}
