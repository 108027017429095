import React, { useMemo } from 'react';
import { LegacyDefaultModalWrapper } from './legacy-default-modal-wrapper';
import { z } from 'zod';

const $FooProps = z.object({
  children: z.custom<React.ReactNode>(),
  onClose: z.function(),
  baseClassName: z.string().optional(),
  useSchema: z.boolean().optional(),
}).strict();

type FooProps = z.infer<typeof $FooProps>;

export function CloseOutsideModalWrapper(props: FooProps): React.ReactElement {
  const parsedProps: FooProps = useMemo(() => {
    if (props.useSchema !== undefined && props.useSchema === false) {
      return props;
    } else {
      return $FooProps.parse(props);
    }
  }, [props]);

  return (
    <LegacyDefaultModalWrapper
      onRequestClose={parsedProps.onClose}
      baseClassName={'boss-modal-window boss-modal-window_role_rota'}
    >
      <button
        type="button"
        onClick={parsedProps.onClose}
        className="boss-modal-window__close boss-modal-window__close_primary"
      />
      <div className="boss-modal-window__content">{parsedProps.children}</div>
    </LegacyDefaultModalWrapper>
  );
}
