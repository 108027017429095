import { useModal } from '@/components/hooks-components/modals';
import oFetch from 'o-fetch';
import React from 'react';
import { EntertainerContactModal } from './entertainer-contact-modal';
import { Field } from 'react-final-form';
import { MdDeleteForever, MdEdit } from "react-icons/md";

function CellField(props: any) {
  const input = oFetch(props, 'input');
  const meta = oFetch(props, 'meta');
  const value = oFetch(input, 'value');
  const title = oFetch(props, 'title');
  const { touched, submitError } = meta;
  const hasError = touched && submitError;

  return (
    <div className="boss-table__cell">
      <div
        className="boss-table__info"
        data-role="name"
      >
        <>
          <p className="boss-table__label">{title}</p>
          <p className="boss-table__text">
            {value}
          </p>
        </>
        {hasError && (
          <p
            className="boss-form__error-text"
            style={{ color: '#fd554f', paddingLeft: 0 }}
          >
            {(submitError).map((errorItem: any, index: number) => {
              return (
                <span
                  key={index}
                  className="boss-form__error-line"
                >
                  {errorItem}
                </span>
              );
            })}
          </p>
        )}
      </div>
    </div>
  );
}


export function EntertainerContactsField(props: any) {
  const fields = oFetch(props, 'fields');
  const disabled = oFetch(props, 'disabled');
  const values = oFetch(props, 'values');
  const { openProcessingModal } = useModal();
  const contactExist = fields.length > 0;
  function addContact(values: any) {
    fields.push({ ...values });
  }

  function updateContact(index: number, values: any) {
    fields.update(index, { ...values });
  }

  function openAddContactModal() {
    return openProcessingModal({
      onSubmit: (handleCloseModal: any, setProcessing: any, values: any) => {
        addContact(values);
        handleCloseModal();
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Add Contact',
      },
      props: {
        initialValues: {
          firstName: '',
          surname: '',
          email: '',
          phoneNumber: '',
        }
      },
      ModalContent: EntertainerContactModal,
    });
  }

  function openEditContactModal(index: number) {
    const contactValues = oFetch(values, 'contacts')[index];

    return openProcessingModal({
      onSubmit: (handleCloseModal: any, setProcessing: any, values: any) => {
        updateContact(index, values);
        handleCloseModal();
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Edit Contact',
      },
      props: {
        initialValues: contactValues
      },
      ModalContent: EntertainerContactModal,
    });
  }

  return (
    <>
      <div className="boss-form__row">
        <div className="boss-table boss-table_page_staff-members-index">
          <div className="boss-table__row">
            <div className="boss-table__cell boss-table__cell_role_header">First Name</div>
            <div className="boss-table__cell boss-table__cell_role_header">Surname</div>
            <div className="boss-table__cell boss-table__cell_role_header">Email</div>
            <div className="boss-table__cell boss-table__cell_role_header">Phone Number</div>
            <div className="boss-table__cell boss-table__cell_role_header" />
          </div>
          {fields.map((fieldName: string, index: number) => {
            return (
              <div
                key={`${fieldName}.${index}`}
                className="boss-table__row test-staff-members-index-listing"
              >
                <Field
                  name={`${fieldName}.firstName`}
                  title={'First Name'}
                  component={CellField}
                />
                <Field
                  name={`${fieldName}.surname`}
                  title={'Surname'}
                  component={CellField}
                />
                <Field
                  name={`${fieldName}.email`}
                  title={'Email'}
                  component={CellField}
                />
                <Field
                  name={`${fieldName}.phoneNumber`}
                  title={'Phone Number'}
                  component={CellField}
                />
                <div className="boss-table__cell">
                  <div
                    className="boss-table__info"
                    data-role="name"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p className="boss-table__label">Action</p>
                    <p className="boss-table__text">
                      <button
                        className="boss-button boss-button_type_ultra-small boss-form__submit boss-form__submit_adjust_single"
                        disabled={disabled}
                        style={{ marginRight: '10px' }}
                        onClick={() => openEditContactModal(index)}
                        type="button"
                      >
                        <MdEdit size={20} />
                      </button>
                      <button
                        className="boss-button boss-button_type_ultra-small boss-form__submit boss-form__submit_adjust_single boss-button_role_not-trading"
                        disabled={disabled}
                        onClick={() => fields.remove(index)}
                        type="button"
                      >
                        <MdDeleteForever size={20} />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!contactExist && (
        <div className="boss-form__row">
          <button
            className="boss-button boss-form__submit boss-form__submit_adjust_single boss-button_role_add"
            disabled={disabled}
            onClick={openAddContactModal}
            type="button"
          >
            Add Contact
          </button>
        </div>
      )}
    </>
  );
}
