import oFetch from 'o-fetch';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { BossFormCheckbox, BossFormInput, BossFormSelectNew, BossFormTextArea, Fields } from '@/components/boss-form';
import { EntertainerContactsField } from './entertainer-contacts-field';
import { BankDetailsField } from '../../EntertainerApp/pages/profile/components/bank-details-field';

type Props = {
  onSubmit: () => void;
  initialValues: any;
  entertainerTypesOptions: any[];
  processing: boolean;
  buttonTitle: string;
  disableNameEditing: boolean;
  showBankDetails: boolean;
};

export function EntertainerForm(props: Props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const processing = oFetch(props, 'processing');
  const initialValues = oFetch(props, 'initialValues');
  const buttonTitle = oFetch(props, 'buttonTitle');
  const disableNameEditing = oFetch(props, 'disableNameEditing');
  const showBankDetails = oFetch(props, 'showBankDetails');
  const entertainerTypesOptions = oFetch(props, 'entertainerTypesOptions');

  return (
    <Form
      destroyOnUmount={false}
      enableReinitialize={false}
      forceUnregisterOnUnmount={false}
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, submitting, submitError, values, ...rest }) => {
        const disabled = processing || submitting;

        return (
          <>
            {submitError && (
              <div className="boss-alert boss-alert_role_area boss-alert_context_above">
                <p className="boss-alert__text">{submitError}</p>
              </div>
            )}
            <Field
              name={'name'}
              label={'Name'}
              disabled={disabled || disableNameEditing}
              parse={value => value}
              component={BossFormInput}
            />
            <Field
              name={'entertainerType'}
              parse={value => value}
              disabled={disabled}
              label={'Type'}
              options={entertainerTypesOptions}
              component={BossFormSelectNew}
            />
            <Field
              name={'description'}
              parse={value => value}
              disabled={disabled}
              label={'Description'}
              component={BossFormTextArea}
            />
            {showBankDetails && (
              <>
                <Field
                  name={'addBankDetails'}
                  disabled={disabled}
                  label="Add Bank Details"
                  component={BossFormCheckbox}
                />
                {values.addBankDetails && (
                  <Fields
                    names={['bankAccountNumber', 'bankSortCode', 'altBankingFullname']}
                    originalRender={BankDetailsField}
                    disabled={disabled}
                  />
                )}
              </>
            )}
            <FieldArray
              name="contacts"
              values={values}
              disabled={disabled}
              component={EntertainerContactsField}
            />
            <div className="boss-form__row">
              <button
                className="boss-button boss-form__submit boss-form__submit_adjust_single"
                disabled={disabled}
                onClick={handleSubmit}
                type="button"
              >
                {buttonTitle}
              </button>
            </div>
          </>
        );
      }}
    />
  );
}
