import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { Tooltip } from 'react-tippy';

type Props = {
  entertainmentShift: any;
};

export function EntertainmentShiftAccepted(props: Props) {
  const entertainmentShift = oFetch(props, 'entertainmentShift');
  const entertainmentShiftAcceptance = oFetch(entertainmentShift, 'entertainmentShiftAcceptance');
  const shiftAcceptancePayRateType = oFetch(entertainmentShiftAcceptance, 'payRateType');
  const acceptedBy = oFetch(entertainmentShiftAcceptance, 'acceptedBy');
  const acceptedAt = oFetch(entertainmentShiftAcceptance, 'acceptedAt');
  const isShiftAcceptanceFixed = shiftAcceptancePayRateType === 'fixed';
  const displayedPayment = oFetch(entertainmentShift, 'displayedPayment');
  const acceptanceDisplayedPayment = oFetch(entertainmentShiftAcceptance, 'displayedPayment');
  const paymentTypeLabel = oFetch(entertainmentShift, 'paymentTypeTitle');
  const payRateTypeLabel = oFetch(entertainmentShift, 'payRateTypeTitle');

  const paymentTypeTitle = oFetch(entertainmentShiftAcceptance, 'paymentTypeTitle');
  const payRateTypeTitle = oFetch(entertainmentShiftAcceptance, 'payRateTypeTitle');

  const entertainmentShiftPayRateType = oFetch(entertainmentShift, 'payRateType');
  const entertainmentShiftPaymentType = oFetch(entertainmentShift, 'paymentType');
  const entertainmentShiftAcceptancePayRateType = oFetch(entertainmentShiftAcceptance, 'payRateType');
  const entertainmentShiftAcceptancePaymentType = oFetch(entertainmentShiftAcceptance, 'paymentType');

  const payRateChanged = entertainmentShiftPayRateType !== entertainmentShiftAcceptancePayRateType;
  const paymentTypeChanged = entertainmentShiftPaymentType !== entertainmentShiftAcceptancePaymentType;
  const paymentChanged = displayedPayment !== acceptanceDisplayedPayment;

  const formattedAcceptedAt = useMemo(() => {
    return safeMoment.iso8601Parse(acceptedAt).format(utils.commonDateFormatWithTime());
  }, []);

  const acceptedTimeAndAmount = useMemo(() => {
    const displayedPayment = oFetch(entertainmentShiftAcceptance, 'displayedPayment');
    const displayedTotalPayment = oFetch(entertainmentShiftAcceptance, 'displayedTotalPayment');

    if (isShiftAcceptanceFixed) {
      return {
        displayedPayment,
        displayedTotalPayment
      };
    } else {
      return {
        displayedPayment,
        displayedTotalPayment,
        displayedAcceptedHours: oFetch(entertainmentShiftAcceptance, 'displayedAcceptedHours'),
      };
    }
  }, []);

  function renderChangedMark(fromValue: any) {
    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={<span>Changed from <b>{fromValue}</b></span>}
      >
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  return (
    <div>
      <p
        className="boss-time-shift__status boss-time-shift__status_state_visible"
        style={{
          display: 'flex'
        }}
      >
        <span
          className="boss-time-shift__status-count"
          style={{ marginRight: '10px' }}
        >Accepted</span>
        {!isShiftAcceptanceFixed && (
          <span className="boss-time-shift__status-count boss-time-shift__status-count_role_breaks"> {oFetch(acceptedTimeAndAmount, 'displayedAcceptedHours')}</span>
        )}
      </p>
      <p className="boss-timeline__text">
        <span className="boss-timeline__text-faded">by {acceptedBy} at {formattedAcceptedAt}</span>
      </p>
      <p className="boss-timeline__text">
        <span className="boss-timeline__text-faded">Payment type:</span> {paymentTypeTitle} {paymentTypeChanged && renderChangedMark(paymentTypeLabel)}
      </p>
      <p className="boss-timeline__text">
        <span className="boss-timeline__text-faded">Payrate:</span> {payRateTypeTitle} {payRateChanged && renderChangedMark(payRateTypeLabel)}
      </p>
      <p className="boss-timeline__text">
        <span className="boss-timeline__text-faded">Payment:</span> {acceptanceDisplayedPayment} {paymentChanged && renderChangedMark(displayedPayment)}
      </p>

    </div>
  );
}
