/* eslint-disable react/prop-types */
import React from 'react';
import { $EntertainerFinanceReportsAppProps } from './types';
import { EntertainerFinanceReportsPage } from './components/entertainer_finance_reports_page';

export function EntertainerFinanceReportsApp(rawProps: unknown): unknown {
  const props = $EntertainerFinanceReportsAppProps.parse(rawProps);

  return (
    <>
      <EntertainerFinanceReportsPage {...props} />
    </>
  );
}