import React, { Fragment } from 'react';
import { Form } from 'react-final-form';
import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import { UpdateBankDetailsFormProps, UpdateBankDetailsFormValues } from '../types';
import { Fields } from '@/components/boss-form';
import { BankDetailsField } from './bank-details-field';

function normalizeFormFields(values: Record<string, any>, initialValues: Record<string, any>): Record<string, NonNullable<unknown> | null> {
  return Object.keys(initialValues).reduce((acc, key) => {
    return {
      ...acc,
      [key]: values[key] ? values[key] : null,
    };
  }, {});
}

export function UpdateBankDetailsForm(props: UpdateBankDetailsFormProps) {
  const initialValues = props.initialValues;
  const onSubmit = props.onSubmit;
  const bankDetailsLastUpdatedAt = props.bankDetailsLastUpdatedAt;
  const bankDetailsLastUpdatedBy = props.bankDetailsLastUpdatedBy;

  const hasLastUpdated = bankDetailsLastUpdatedAt && bankDetailsLastUpdatedBy;

  function renderLastUpdated() {
    const formattedLastUpdatedAt = safeMoment
      .iso8601Parse(bankDetailsLastUpdatedAt)
      .format(utils.commonDateFormatWithTime());
    return (
      <div className="boss-form__field">
        <p className="boss-form__text">
          Last updated at: <span className="boss-form__text-primary">{formattedLastUpdatedAt}</span> by{' '}
          <span className="boss-form__text-primary">{bankDetailsLastUpdatedBy}</span>
        </p>
      </div>
    );
  }

  function renderBaseError(errors: string[]) {
    return (
      <div className="boss-modal-window__alert">
        <div className="boss-alert boss-alert_role_area boss-alert_context_above">
          {errors.map((error, index) => {
            return (
              <p
                key={index}
                className="boss-alert__text"
              >
                {error}
              </p>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <Form<UpdateBankDetailsFormValues>
      onSubmit={values => onSubmit(normalizeFormFields(values, initialValues))}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <Fragment>
            {submitError ? renderBaseError(submitError) : null}
            <div className="boss-modal-window__form">
              <div className="boss-form">
                <Fields
                  names={['bankAccountNumber', 'bankSortCode', 'altBankingFullname']}
                  originalRender={BankDetailsField}
                  disabled={submitting}
                />
                {hasLastUpdated && renderLastUpdated()}
                <div className="boss-form__field">
                  <button
                    onClick={handleSubmit}
                    disabled={submitting}
                    className="boss-button boss-form__submit"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
}
