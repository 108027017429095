import oFetch from 'o-fetch';
import React from 'react';
import { EntertainmentForm } from './entertainment-form';
import { PAY_RATE_FIXED, PAY_RATE_HOURLY } from './fields/entertainment-payment-type-field';

type Props = {
  onSubmit: () => void;
  processing: boolean;
  entertainmentShift: any;
  onClose: () => void;
  onDisable: (entertainmentShiftId: number) => void;
};

export function EditEntertainmentModal(props: Props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const onClose = oFetch(props, 'onClose');
  const onDisable = oFetch(props, 'onDisable');
  const processing = oFetch(props, 'processing');
  const entertainmentShift = oFetch(props, 'entertainmentShift');
  const entertainmentShiftId = oFetch(entertainmentShift, 'id');
  const startTime = oFetch(entertainmentShift, 'startTime');
  const endTime = oFetch(entertainmentShift, 'endTime');
  const description = oFetch(entertainmentShift, 'description');
  const entertainmentType = oFetch(entertainmentShift, 'entertainmentType');
  const paymentType = oFetch(entertainmentShift, 'paymentType');
  const fixedPaymentCents = oFetch(entertainmentShift, 'fixedPaymentCents');
  const paymentCentsPerHour = oFetch(entertainmentShift, 'paymentCentsPerHour');
  const entertainer = oFetch(entertainmentShift, 'entertainer');
  const payRateType = fixedPaymentCents ? PAY_RATE_FIXED : PAY_RATE_HOURLY;

  const initialValues = {
    entertainmentShiftId: entertainmentShiftId,
    entertainerId: entertainer ? oFetch(entertainer, 'id') : null,
    startTime: startTime,
    endTime: endTime,
    description: description,
    entertainmentType: entertainmentType,
    paymentType: paymentType,
    fixedPaymentCents: fixedPaymentCents,
    paymentCentsPerHour: paymentCentsPerHour,
    payRateType: payRateType
  };

  return (
    <EntertainmentForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      onCloseForm={onClose}
      processing={processing}
      showDisableButton={true}
      onDisable={onDisable}
    />
  );
}
