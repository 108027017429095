import { createAction } from 'redux-actions';
import oFetch from 'o-fetch';
import { submit } from 'redux-form/immutable';

import {
  INITIAL_LOAD,
  ADD_NEW_SHIFT,
  CANCEL_ADD_NEW_SHIFT,
  SHOW_GRAPH_DETAILS,
  CLOSE_GRAPH_DETAILS,
  UPDATE_ROTA_SHIFT,
  DELETE_ROTA_SHIFT,
  ADD_ROTA_SHIFT,
  OPEN_MULTIPLE_SHIFT,
  CLOSE_MULTIPLE_SHIFT,
  SET_MULTIPLE_SHIFT_STAFF_ID,
  SET_STAFF_TYPES_FILTER,
  UPDATE_STAFF_MEMBER_SHIFT_INFO,
  ADD_ROTA,
  UPDATE_TRADING_STATUS,
  UPDATE_DELIVERY_DAY_STATUS,
  ADD_ENTERTAINMET_SHIFT,
  UPDATE_ENTERTAINMET_SHIFT,
  DELETE_ENTERTAINMET_SHIFT,
} from './constants';

import {
  updateStaffMemberShiftRequest,
  deleteStaffMemberShiftRequest,
  addShiftRequest,
  setRotaStatusRequest,
  updateTradingStatusRequest,
  updateDeliveryDayStatusRequest,
  createEntertainmentRequest,
  updateEntertainmentRequest,
  disableEntertainmentShiftRequest,
} from './requests';

export const addNewShift = createAction(ADD_NEW_SHIFT);
export const cancelAddNewShift = createAction(CANCEL_ADD_NEW_SHIFT);
export const showGraphDetails = createAction(SHOW_GRAPH_DETAILS);
export const closeGraphDetails = createAction(CLOSE_GRAPH_DETAILS);
export const updateRotaShift = createAction(UPDATE_ROTA_SHIFT);
export const addRotaShift = createAction(ADD_ROTA_SHIFT);
export const addEntertainmentShift = createAction(ADD_ENTERTAINMET_SHIFT);
export const updateEntertainmentShiftInState = createAction(UPDATE_ENTERTAINMET_SHIFT);
export const deleteEntertainmentShiftFromState = createAction(DELETE_ENTERTAINMET_SHIFT);
export const deleteRotaShift = createAction(DELETE_ROTA_SHIFT);
export const openMultipleShift = createAction(OPEN_MULTIPLE_SHIFT);
export const closeMultipleShift = createAction(CLOSE_MULTIPLE_SHIFT);
export const setMultipleShiftStaffId = createAction(SET_MULTIPLE_SHIFT_STAFF_ID);
export const setStaffTypesFilter = createAction(SET_STAFF_TYPES_FILTER);
export const updateStaffMemberShiftInfo = createAction(UPDATE_STAFF_MEMBER_SHIFT_INFO);
export const addRota = createAction(ADD_ROTA);
export const updateTradingStatusAction = createAction(UPDATE_TRADING_STATUS);
export const updateDeliveryDayStatusAction = createAction(UPDATE_DELIVERY_DAY_STATUS);

export const initialLoad = createAction(INITIAL_LOAD);

export const updateTradingStatus = params => (dispatch, getState) => {
  const trading = oFetch(params, 'trading');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');
  const page = oFetch(getState(), 'page');
  const venueId = oFetch(page, 'currentVenue.id');
  const date = oFetch(page, 'rota.date');

  return updateTradingStatusRequest({
    values: {
      trading,
      venueId,
      date,
    },
    onSuccess(data) {
      const rota = oFetch(data, 'rota');
      onSuccess();
      dispatch(updateTradingStatusAction({ rota }));
    },
    onFailure(data) {
      onFailure();
    },
  });
};

export const createEntertainment = params => (dispatch, getState) => {
  const values = oFetch(params, 'values');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');

  return createEntertainmentRequest({
    values,
    onSuccess(data) {
      const entertainmentShift = oFetch(data, 'entertainmentShift');
      onSuccess();
      dispatch(addEntertainmentShift(entertainmentShift));
    },
    onFailure(data) {
      onFailure();
    },
  });
};

export const updateEntertainmentShift = params => (dispatch, getState) => {
  const values = oFetch(params, 'values');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');

  return updateEntertainmentRequest({
    values,
    onSuccess(data) {
      const entertainmentShift = oFetch(data, 'entertainmentShift');
      onSuccess();
      dispatch(updateEntertainmentShiftInState(entertainmentShift));
    },
    onFailure(data) {
      onFailure();
    },
  });
};

export const disableEntertainmentShift = params => (dispatch, getState) => {
  const values = oFetch(params, 'values');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');

  return disableEntertainmentShiftRequest({
    values,
    onSuccess(data) {
      const entertainmentShift = oFetch(data, 'entertainmentShift');
      onSuccess();
      dispatch(deleteEntertainmentShiftFromState(entertainmentShift));
    },
    onFailure(data) {
      onFailure();
    },
  });
};

export const updateDeliveryDayStatus = params => (dispatch, getState) => {
  const isDeliveryDay = oFetch(params, 'deliveryDay');
  const [onSuccess, onFailure] = oFetch(params, 'onSuccess', 'onFailure');
  const page = oFetch(getState(), 'page');
  const venueId = oFetch(page, 'currentVenue.id');
  const rota = oFetch(page, 'rota');
  const date = oFetch(rota, 'date');

  return updateDeliveryDayStatusRequest({
    values: {
      isDeliveryDay,
      venueId,
      date,
    },
    onSuccess(data) {
      const rota = oFetch(data, 'rota');
      onSuccess();
      dispatch(updateDeliveryDayStatusAction({ rota }));
    },
    onFailure(data) {
      onFailure();
    },
  });
};

export const updateStaffMemberShift = values => (dispatch, getState) => {
  return updateStaffMemberShiftRequest(values).then(resp => {
    const rota = oFetch(resp, 'data.rota');
    const rotaShift = oFetch(resp, 'data.rotaShift');
    const staffMemberId = oFetch(rotaShift, 'staff_member');
    dispatch(addRota({ rota }));
    dispatch(updateRotaShift(rotaShift));
    dispatch(updateStaffMemberShiftInfo(staffMemberId));
    dispatch(closeGraphDetails());
  });
};

export const deleteStaffMemberShift = (shift_id, staffMemberId) => (dispatch, getState) => {
  return deleteStaffMemberShiftRequest(shift_id).then(resp => {
    dispatch(deleteRotaShift(shift_id));
    dispatch(updateStaffMemberShiftInfo(staffMemberId));
    dispatch(closeGraphDetails());
  });
};

export const addShift = values => (dispatch, getState) => {
  const venueId = getState().getIn(['page', 'currentVenue', 'id']);
  const rotaDate = getState().getIn(['page', 'rota', 'date']);

  const isMultipleShift = getState().getIn(['page', 'isMultipleShift']);

  if (isMultipleShift) {
    const staffMemberId = getState().getIn(['page', 'multipleShiftStaffId']);
    values = { ...values, staff_member_id: staffMemberId };
  }

  return addShiftRequest(values, venueId, rotaDate).then(resp => {
    const rota = oFetch(resp, 'data.rota');
    const rotaShift = oFetch(resp, 'data.rotaShift');
    dispatch(addRota({ rota }));
    dispatch(addRotaShift(rotaShift));
    dispatch(updateStaffMemberShiftInfo(values.staff_member_id));
    return resp;
  });
};

export const submitMultipleStaffShift = staffMemberId => (dispatch, getState) => {
  dispatch(setMultipleShiftStaffId(staffMemberId));
  dispatch(submit('add-multiple-shift-form'));
};

export const setRotaStatus = status => (dispatch, getState) => {
  const venueId = getState().getIn(['page', 'currentVenue', 'id']);
  const rotaDate = getState().getIn(['page', 'rota', 'date']);

  return setRotaStatusRequest(status, venueId, rotaDate);
};
