import { handleActions } from 'redux-actions';
import oFetch from 'o-fetch';
import * as types from '../types';

const initialState = [];

export default handleActions(
  {
    [types.LOAD_INITIAL_STATE]: (state, action) => {
      const entertainmentShifts = oFetch(action, 'payload.entertainmentShifts');
      return entertainmentShifts;
    },
    [types.REMOVE_ENTERTAINMENT_SHIFT]: (state, action) => {
      const removedEntertainmentShift = oFetch(action, 'payload.entertainmentShift');
      return state.filter(entertainmentShift => {
        return oFetch(entertainmentShift, 'id') !== oFetch(removedEntertainmentShift, 'id');
      });
    },
    [types.UPDATE_ENTERTAINMENT_SHIFT]: (state, action) => {
      const updatedEntertainmentShift = oFetch(action, 'payload.entertainmentShift');
      return state.map(entertainmentShift => {
        if (oFetch(entertainmentShift, 'id') === oFetch(updatedEntertainmentShift, 'id')) {
          return updatedEntertainmentShift;
        }
        return entertainmentShift;
      });
    },
  },
  initialState,
);
