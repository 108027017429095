import oFetch from 'o-fetch';
import React, { ReactElement } from 'react';

type Props = {
  entertainmentShifts: any[],
  renderItem: (entertainmentShifts: any) => ReactElement,
};

export function EntertainmentShiftList(props: Props) {
  const entertainmentShifts = oFetch(props, 'entertainmentShifts');
  const renderItem = oFetch(props, 'renderItem');

  function renderItems() {
    return entertainmentShifts.map((entertainmentShifts: any) => {
      const entertainmentShiftId = oFetch(entertainmentShifts, 'id');
      return React.cloneElement(renderItem(entertainmentShifts), { key: entertainmentShiftId });
    });
  }

  return (
    <div className='boss-table boss-table_page_staff-members-index'>
      <div className="boss-table__row">
        <div className="boss-table__cell boss-table__cell_role_header">Name</div>
        <div className="boss-table__cell boss-table__cell_role_header">Time</div>
        <div className="boss-table__cell boss-table__cell_role_header">Status</div>
        <div className="boss-table__cell boss-table__cell_role_header" />
      </div>
      {renderItems()}
    </div>
  );
}
