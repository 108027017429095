export const ALL_TAB_FILTER_OPTION = 'all' as const;
export const CASH_TAB_FILTER_OPTION = 'cash' as const;
export const BANK_TRANSFER_TAB_FILTER_OPTION = 'bank_transfer' as const;

export const CASH_PAYMENT_TYPE = 'cash' as const;
export const BANK_TRANSFER_PAYMENT_TYPE = 'invoice' as const;

export const FIXED_PAY_RATE_TYPE = 'fixed' as const;
export const HOURLY_PAY_RATE_TYPE = 'hourly' as const;

export const WEEK_PENDING_UI_STATUS = 'pending' as const;
export const WEEK_FINISHED_UI_STATUS = 'finished' as const;
export const WEEK_READY_UI_STATUS = 'ready' as const;
export const WEEK_INCOMPLETE_UI_STATUS = 'incomplete' as const;

export const REPORT_REQUIRING_UPDATE_UI_STATUS = 'requiring_update' as const;
export const REPORT_PROCESSING_UI_STATUS = 'processing' as const;
export const REPORT_READY_UI_STATUS = 'ready' as const;
export const REPORT_DONE_UI_STATUS = 'done' as const;
export const REPORT_PENDING_UI_STATUS = 'pending' as const;
export const REPORT_INCOMPLETE_UI_STATUS = 'incomplete' as const;

export const SPOTIFY_ENTERTAINMENT_TYPE = 'spotify' as const;
export const DJ_ENTERTAINMENT_TYPE = 'dj' as const;
export const BAND_ENTERTAINMENT_TYPE = 'band' as const;
export const SINGER_ENTERTAINMENT_TYPE = 'singer' as const;
export const OTHER_ENTERTAINMENT_TYPE = 'other' as const;

export const PENDING_BREAKDOWN_ITEM_STATUS = 'pending' as const;
export const ACCEPTED_BREAKDOWN_ITEM_STATUS = 'accepted' as const;
export const ACTIVE_BREAKDOWN_ITEM_STATUS = 'active' as const;
export const FINISHED_BREAKDOWN_ITEM_STATUS = 'finished' as const;
export const DISABLED_BREAKDOWN_ITEM_STATUS = 'disabled' as const;