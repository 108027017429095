import React from 'react';
import oFetch from 'o-fetch';
import { EntertainerForm } from '@/apps/Entertainers/EntertainersListApp/components';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function EditEntertainerModal(props: any) {
  const onSubmit = oFetch(props, 'onSubmit');
  const processing = oFetch(props, 'processing');
  const entertainer = oFetch(props, 'entertainer');
  const entertainerTypesOptions = oFetch(useLegacyGlobal(), 'entertainerTypesOptions');
  const isAdminPlus = oFetch(useLegacyGlobal(), 'isAdminPlus');

  const initialValues = {
    name: oFetch(entertainer, 'name'),
    description: oFetch(entertainer, 'description'),
    contacts: oFetch(entertainer, 'contacts'),
    entertainerType: oFetch(entertainer, 'entertainerType'),
  };

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <EntertainerForm
          onSubmit={onSubmit}
          processing={processing}
          initialValues={initialValues}
          entertainerTypesOptions={entertainerTypesOptions}
          buttonTitle={'Update'}
          disableNameEditing={!isAdminPlus}
          showBankDetails={false}
        />
      </div>
    </div>
  );
}
