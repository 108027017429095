import React, { useContext } from 'react';
import { v1 as uuid } from 'uuid';
import { ModalsContext } from './modal-provider';
import { ProcessingModalComponent } from '../../modal-wrappers/processing-modal-component';
import { CloseOutsideModalWrapper } from '@/components/modal-wrappers';
import { AreYouSure } from '@/components/modal-content';

export default function useModal() {
  const context = useContext(ModalsContext);

  function handleClose() {
    context.closeModal();
  }

  function handleCloseWithResolve(resolve: any) {
    return () => {
      resolve();
      handleClose();
    };
  }

  function handleSubmit(submitFn: any) {
    return (...args: any) => {
      return submitFn(handleClose, ...args);
    };
  }

  function handleProcessingSubmit(submitFn: any) {
    return (values: any) => {
      return submitFn(handleClose, context.setProcessing, values);
    };
  }

  function handlePromiseResolveSubmit(submitFn: any, resolve: any, reject: any) {
    return (...args: any) => {
      return Promise.resolve(submitFn(handleClose, ...args))
        .then(resp => {
          resolve(resp);
          return resp;
        })
        .catch(e => {
          reject(e);
        });
    };
  }

  function handleCloseCallback(closeCb: any) {
    return () => {
      handleClose();
      closeCb();
    };
  }

  function openModal({
    onSubmit = () => { },
    props = {},
    config = {},
    onClose = () => { },
    ModalComponent,
    ModalContent,
  }: any) {
    const key = uuid();
    if (ModalComponent === undefined) {
      throw new Error('ModalComponent is required');
    }
    if (ModalContent === undefined) {
      throw new Error('ModalContent is required');
    }

    context.showModal(
      <ModalComponent
        key={key}
        onClose={handleCloseCallback(onClose)}
        {...config}
      >
        <ModalContent
          {...props}
          onClose={handleCloseCallback(onClose)}
          onSubmit={handleSubmit(onSubmit)}
        />
      </ModalComponent>,
    );
  }

  function openAreYouSureModal({
    onSubmit = () => { },
    props = {},
    config = {
      title: 'ARE YOU SURE?',
      baseClassName: 'boss-modal-window boss-modal-window_role_danger',
    },
    onClose = () => { },
    ModalComponent = CloseOutsideModalWrapper,
    ModalContent = AreYouSure,
  }: any) {
    const key = uuid();
    if (ModalComponent === undefined) {
      throw new Error('ModalComponent is required');
    }
    if (ModalContent === undefined) {
      throw new Error('ModalContent is required');
    }

    context.showModal(
      <ModalComponent
        key={key}
        onClose={handleCloseCallback(onClose)}
        {...config}
      >
        <ModalContent
          {...props}
          onClose={handleCloseCallback(onClose)}
          onSubmit={handleSubmit(onSubmit)}
        />
      </ModalComponent>,
    );
  }

  function openProcessingModal({
    onSubmit = () => { },
    props = {},
    config = {},
    onClose = () => { },
    ModalContent,
  }: any) {
    const key = uuid();
    context.showModal(
      <ProcessingModalComponent
        key={key}
        onClose={handleCloseCallback(onClose)}
        {...config}
      >
        {(processing: boolean) => {
          return (
            <ModalContent
              {...props}
              processing={processing}
              onClose={handleCloseCallback(onClose)}
              onSubmit={handleProcessingSubmit(onSubmit)}
            />
          );
        }}
      </ProcessingModalComponent>,
    );
  }

  function openPromiseModal({ onSubmit = () => { }, props = {}, config = {}, ModalComponent, ModalContent }: any) {
    return new Promise((resolve, reject) => {
      const key = uuid();
      context.showModal(
        <ModalComponent
          key={key}
          onClose={handleCloseWithResolve(resolve)}
          {...config}
        >
          <ModalContent
            {...props}
            onClose={handleCloseWithResolve(resolve)}
            onSubmit={handlePromiseResolveSubmit(onSubmit, resolve, reject)}
          />
        </ModalComponent>,
      );
    });
  }

  return {
    openModal,
    openPromiseModal,
    openProcessingModal,
    openAreYouSureModal
  };
}
