import { $BossUiTime } from '@/lib/schema-funcs';
import { z } from 'zod';

export const $EntertainerTypesHash = z.record(z.string());

export const $PermissionsData = z.object({
  canUpdateBankDetails: z.boolean(),
}).strict();

export type PermissionsData = z.infer<typeof $PermissionsData>;

const $EntertainerContact = z.object({
  id: z.number().int().positive(),
  firstName: z.string(),
  surname: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
}).strict();

// must be kept in sync with $NullableVariableEntertainerBankDetailsParams
export const $VariableEntertainerBankDetailsParams = z.object({
  bankDetailsLastUpdatedAt: $BossUiTime(z),
  bankDetailsLastUpdatedBy: z.string().min(1),
}).strict();

// must be kept in sync with $VariableEntertainerBankDetailsParams
export const $NullableVariableEntertainerBankDetailsParams = z.object({
  bankDetailsLastUpdatedAt: $BossUiTime(z).nullable(),
  bankDetailsLastUpdatedBy: z.string().min(1).nullable(),
}).strict();

export const $BaseEntertainerBankDetailsParams = z.object({
  hasBankDetails: z.boolean(),
  altBankingFullname: z.string().min(1).nullable(),
});

export const $NullableEntertainerBankDetailsParams = $BaseEntertainerBankDetailsParams
  .merge($NullableVariableEntertainerBankDetailsParams)
  .strict();

export const $EntertainerBankDetailsParams = $BaseEntertainerBankDetailsParams
  .merge($VariableEntertainerBankDetailsParams)
  .strict();

export type EntertainerBankDetailsParams = z.infer<typeof $EntertainerBankDetailsParams>;

export const $Entertainer = z.object({
  id: z.number().int().positive(),
  name: z.string(),
  entertainerType: z.string(),
  entertainerTypeLabel: z.string(),
  description: z.string().nullable(),
  contacts: z.array($EntertainerContact).max(1),
})
  .merge($NullableEntertainerBankDetailsParams)
  .strict();

export type Entertainer = z.infer<typeof $Entertainer>;

type ProfilePagePageProps = {
  entertainer: Entertainer;
};

export type ProfilePageProps = {
  page: ProfilePagePageProps;
  // permissionsData: PermissionsData;
};

export type ProfileHeaderProps = {
  entertainer: Entertainer
};

export type BankDetailsListItemProps = {
  hasBankDetails: boolean,
  canUpdateBankDetails: boolean,
  onEditClick: () => void,
};

export const $UpdateBankDetailsFormValues = z.object({
  bankSortCode: z.string().nullable(),
  bankAccountNumber: z.string().nullable(),
  altBankingFullname: z.string().nullable(),
}).strict();

export type UpdateBankDetailsFormValues = z.infer<typeof $UpdateBankDetailsFormValues>;

export type UpdateBankDetailsFormProps = {
  initialValues: UpdateBankDetailsFormValues;
  onSubmit: (values: unknown) => void;
  bankDetailsLastUpdatedAt: Date | null;
  bankDetailsLastUpdatedBy: string | null;
};

export const $UpdateBankDetailsResultData = z.object({
  bankAccountNumber: z.string(),
  bankName: z.string(),
  bankSortCode: z.string(),
  branchName: z.string(),
  altBankingFullname: z.string().nullable(),
});

export const $UpdateEntertainerBankDetailsSuccessData = $EntertainerBankDetailsParams
  .merge(
    $UpdateBankDetailsResultData
  );

export type UpdateEntertainerBankDetailsSuccessData = z.infer<typeof $UpdateEntertainerBankDetailsSuccessData>;

export type UpdateEntertainerBankDetailsParams = {
  entertainer: Entertainer,
  values: UpdateBankDetailsFormValues,
  onSuccess: (data: UpdateEntertainerBankDetailsSuccessData) => void,
};

export const $UpdateBankDetailsModalContentPropsWithoutSubmit = z.object({
  bankDetailsLastUpdatedAt: z.date().nullable(),
  bankDetailsLastUpdatedBy: z.string().nullable(),
  altBankingFullname: z.string().nullable(),
});

export type UpdateBankDetailsModalContentPropsWithoutSubmit = z.infer<typeof $UpdateBankDetailsModalContentPropsWithoutSubmit>;

export const $UpdateBankDetailsModalContentProps = $UpdateBankDetailsModalContentPropsWithoutSubmit
  .merge(
    z.object({
      onSubmit: z.function().args(z.unknown()).returns(z.void()),
    })
  );

export type UpdateBankDetailsModalContentProps = z.infer<typeof $UpdateBankDetailsModalContentProps>;

export const $ConfirmBankDetailsModalContentPropsWithoutSubmit = $UpdateBankDetailsResultData.
  merge(
    z.object({
      ownerName: z.string(),
    })
  );

export type ConfirmBankDetailsModalContentPropsWithoutSubmit = z.infer<typeof $ConfirmBankDetailsModalContentPropsWithoutSubmit>;

export const $ConfirmBankDetailsModalContentProps = $ConfirmBankDetailsModalContentPropsWithoutSubmit
  .merge(
    z.object({ onSubmit: z.function().args(z.unknown()).returns(z.void()) })
  );

export type ConfirmBankDetailsModalContentProps = z.infer<typeof $ConfirmBankDetailsModalContentProps>;

const $GlobalNotifications = z.object({
  showDefaultSuccessMessage: z.function().args(z.unknown()).returns(z.void()),
  showDefaultFailureMessage: z.function().args(z.unknown()).returns(z.void()),
});

export type GlobalNotifications = z.infer<typeof $GlobalNotifications>;

const $SupportedKeyChecker = z.object({
  validateKeys: z.function()
    .args(
      z.object({
        suppliedKeys: z.array(z.string()),
        supportedKeys: z.array(z.string()),
      })
    )
    .returns(
      z.void()
    )
});

export const $ErrorHandlerParams = z.object({
  statusCode: z.number().int().positive(),
  errors: z.unknown(),
  globalNotifications: $GlobalNotifications,
  supportedKeyChecker: $SupportedKeyChecker,
});

export type ErrorHandlerParams = z.infer<typeof $ErrorHandlerParams>;

export type SuccessHandlerParams = {
  globalNotifications: GlobalNotifications,
  data: unknown,
};

export type UseEntertainerArgs = {
  entertainer: Entertainer;
};
