import React from 'react';
import cn from 'classnames';
import { NavLink as RRNavLink } from "react-router-dom-v6";

type NavLinkProps = {
  href: string
  text: string
  className: string
  end: boolean
};

NavLink.defaultProps = {
  end: false
};

export function NavLink(props: NavLinkProps) {
  const { text, href, className, end } = props;
  return (
    <RRNavLink
      to={href}
      end={end}
      className={({ isActive, isPending }: any) => {
        const linkClassNames = cn(`boss-button boss-button_type_small boss-page-dashboard__switch ${className}`, { 'boss-button_state_active': isActive });
        return linkClassNames;
      }}
    >
      {text}
    </RRNavLink>
  );
}

type Props = {};

export function ProfileNav(props: Props) {
  return (
    <div className="boss-page-dashboard__switches">
      <NavLink
        href={``}
        end
        className="boss-button_role_profile"
        text="Profile"
      />
      <NavLink
        href={`shifts`}
        className="boss-button_role_holidays"
        text="Shifts"
      />
      <NavLink
        href={`payments`}
        className="boss-button_role_timelog"
        text="Payments"
      />
    </div>
  );
}
