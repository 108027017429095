import React from 'react';
import { connect } from 'react-redux';
import oFetch from 'o-fetch';
import { bindActionCreators } from 'redux';
import BossSelect from '@/components/boss-select';
import DashboardWrapper from '@/components/dashboard-wrapper';
import { addNewShift, cancelAddNewShift, setRotaStatus, updateTradingStatus, updateDeliveryDayStatus } from '../actions';
import { VenueTradingForm } from './venue-trading-form';
import { DeliveryDayForm } from './delivery-day-form';

const mapStateToProps = state => {
  return {
    currentVenue: oFetch(state, 'page.currentVenue'),
    rotaStatus: oFetch(state, 'page.rota.status'),
    venueTrading: oFetch(state, 'page.rota.venueTrading'),
    isDeliveryDay: oFetch(state, 'page.rota.isDeliveryDay'),
    isAddingNewShift: oFetch(state, 'page.isAddingNewShift'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        addNewShift,
        cancelAddNewShift,
        setRotaStatus,
        updateTradingStatus,
        updateDeliveryDayStatus,
      },
      dispatch,
    ),
  };
};

class RotaDailyDashboard extends React.Component {
  state = {
    changingStatus: false,
  };

  setRotaStatus = status => {
    const [currentRotaStatus, setRotaStatus] = oFetch(this.props, 'rotaStatus', 'actions.setRotaStatus');
    const selectedStatus = oFetch(status, 'value');
    if (currentRotaStatus === selectedStatus) {
      return;
    }

    this.setState({ changingStatus: true });

    setRotaStatus(selectedStatus).then(() => {
      this.setState({ changingStatus: false });
    });
  };

  render() {
    const [
      currentVenue,
      rotaStatus,
      addNewShift,
      cancelAddNewShift,
      updateDeliveryDayStatus,
      updateTradingStatus,
      isAddingNewShift,
      venueTrading,
      isDeliveryDay,
    ] = oFetch(
      this.props,
      'currentVenue',
      'rotaStatus',
      'actions.addNewShift',
      'actions.cancelAddNewShift',
      'actions.updateDeliveryDayStatus',
      'actions.updateTradingStatus',
      'isAddingNewShift',
      'venueTrading',
      'isDeliveryDay'
    );
    const rotaStatuses = [
      { value: 'in_progress', label: 'In Progress' },
      { value: 'finished', label: 'Finished' },
    ];
    const selectedStatus = rotaStatuses.find(status => status.value === rotaStatus);
    const changingStatus = oFetch(this.state, 'changingStatus');

    return (
      <DashboardWrapper>
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_rotas-daily">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text">Rota for{'\u00A0'}</span>
              <span className="boss-page-dashboard__title-text boss-page-dashboard__title-text_marked">
                {currentVenue.get('name')}
              </span>
            </h1>
            <div className="boss-page-dashboard__sub-group">
              <DeliveryDayForm
                isDeliveryDay={isDeliveryDay}
                onUpdate={updateDeliveryDayStatus}
              />
              <VenueTradingForm
                trading={venueTrading}
                onUpdate={updateTradingStatus}
              />
            </div>
          </div>
          <div className="boss-page-dashboard__group">
            <div className="boss-page-dashboard__controls-group">
              <div className="boss-form">
                <div className="boss-form__field boss-form__field_role_control">
                  <p className="boss-form__label boss-form__label_type_light boss-form__label_desktop">
                    <span className="boss-form__label-text">Rota Status</span>
                  </p>
                  {rotaStatus === 'published' ? (
                    <span className="boss-form__label boss-form__label_type_light boss-form__label_desktop">
                      Published
                    </span>
                  ) : (
                    <BossSelect
                      className="boss-form__select_size_small"
                      options={rotaStatuses}
                      selected={selectedStatus}
                      disabled={changingStatus}
                      onChange={this.setRotaStatus}
                      mappedProps={{
                        clearable: false,
                        searchable: false,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="boss-page-dashboard__buttons-group">
              {isAddingNewShift ? (
                <button
                  onClick={cancelAddNewShift}
                  className="boss-button boss-button_role_cancel boss-page-dashboard__button"
                >
                  Cancel
                </button>
              ) : (
                <button
                  onClick={addNewShift}
                  className="boss-button boss-button_role_add boss-page-dashboard__button"
                >
                  Add New Shift Hours
                </button>
              )}
            </div>
            <div className="boss-page-dashboard__buttons-group">
              <button
                onClick={addNewShift}
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >
                Add New Entertainment Shift
              </button>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RotaDailyDashboard);
