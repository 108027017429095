import React, { Fragment } from 'react';
import { CompositeEntertainerFinanceReport, DayBreakdownItem, ReportDataRowProps } from '../types';
import classNames from 'classnames';
import {
  entertainerProfile as importedEntertainerProfile,
  entertainersHoursOverview as importedEntertainersHoursOverview,
} from "@/lib/app-routes";
import { formatAsCurrency } from '@/lib/currency-helpers';
import { StatusColumn } from './status-column';
import { Tooltip } from 'react-tippy';
const appRoutes = {
  entertainerProfile: importedEntertainerProfile,
  entertainersHoursOverview: importedEntertainersHoursOverview,
} as const;
import { CSSProperties } from 'react';
import { ConditionalWrap } from '@/components/conditional-wrap';
import { BANK_TRANSFER_PAYMENT_TYPE, CASH_PAYMENT_TYPE, FIXED_PAY_RATE_TYPE, HOURLY_PAY_RATE_TYPE } from '../constants';
import { SHORT_TIME_FORMAT } from '@/lib/date-fns-formats';
import { format } from 'date-fns';

const cellStyle: CSSProperties = { flexDirection: 'row', alignItems: 'center' };

export function ReportDataRow(props: ReportDataRowProps): JSX.Element {
  const compositeEntertainerFinanceReport = props.compositeEntertainerFinanceReport;
  const weekDates = props.rotaWeek.weekDates();
  const note = compositeEntertainerFinanceReport.note;

  const validationErrorsExist = compositeEntertainerFinanceReport.mondayValidationErrors.size > 0 ||
    compositeEntertainerFinanceReport.tuesdayValidationErrors.size > 0 ||
    compositeEntertainerFinanceReport.wednesdayValidationErrors.size > 0 ||
    compositeEntertainerFinanceReport.thursdayValidationErrors.size > 0 ||
    compositeEntertainerFinanceReport.fridayValidationErrors.size > 0 ||
    compositeEntertainerFinanceReport.saturdayValidationErrors.size > 0 ||
    compositeEntertainerFinanceReport.sundayValidationErrors.size > 0;
  const pendingCalculation = compositeEntertainerFinanceReport.pendingCalculation;
  const entertainerDisabled = compositeEntertainerFinanceReport.entertainerDisabled;

  const canViewBankDetails = props.permissions.canViewBankDetails;
  const entertainerHasBankDetails = compositeEntertainerFinanceReport.entertainerHasBankDetails;

  const rowClassNames = classNames({
    'boss-table__row': true,
    'boss-table__row_state_alert': validationErrorsExist,
    'boss-table__row_state_pre-calculated': pendingCalculation,
  });

  const nameCellClassNames = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_user-disabled': entertainerDisabled,
  });

  function getTextClassNames(params: { alertStyles: boolean }): string {
    const alertStyles = params.alertStyles;
    return classNames({
      'boss-boss-table__text': true,
      'boss-table__text_state_alert': alertStyles,
    });
  }


  function getCellClassNames(params: { alertStyles: boolean }): string {
    const alertStyles = params.alertStyles;
    return classNames({
      'boss-table__cell': true,
      'boss-table__cell_state_alert': alertStyles,
    });
  }

  function getLinkClassNames(params: { alertStyles: boolean }): string {
    return `${getTextClassNames({ alertStyles: params.alertStyles })} boss-table__link`;
  }

  function onMarkCompleted() {
    console.log(`TODO: now onMarkCompleted`);
  }

  function onUncomplete() {
    console.log(`TODO: now onUncomplete`);
  }

  function onViewBankDetailsClick(compositeEntertainerFinanceReport: CompositeEntertainerFinanceReport): void {
    console.log(`TODO: now onViewBankDetailsClick. entertainerFinanceReport:`, compositeEntertainerFinanceReport);
  }

  function onNoteClick(args: { compositeEntertainerFinanceReport: CompositeEntertainerFinanceReport, edit: boolean }): void {
    console.log(`TODO: now onNoteClick. args: `, args);
  }

  function renderQuestionMarkTooltip(args: { errors: Set<string> }): JSX.Element {
    const content = (
      <ul>
        {Array.from(args.errors).map((text, index) => (
          <li key={`val-err-li-${index}`}>{text}</li>
        ))}
      </ul>
    );

    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={content}
      >
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <span className="boss-tooltip__icon" />
          </span>
        </span>
      </Tooltip>
    );
  }

  function renderNoteTooltip(note: string): JSX.Element {
    return (
      <Tooltip
        arrow
        theme="light"
        position="bottom"
        trigger="click"
        interactive
        html={
          <div className="boss-overview">
            <p
              className="boss-overview__text"
              style={{ whiteSpace: 'pre-line' }}
            >
              {note}
            </p>
          </div>
        }
      >
        <span className="boss-table__tooltip">
          <p className="boss-table__text boss-table__text_role_action">
            <span className="boss-indicator boss-table__indicator">
              <span className="boss-indicator__icon boss-indicator__icon_envelope" />
            </span>
          </p>
        </span>
      </Tooltip>
    );
  }

  function renderWeekDayCells() {
    const mondayDate = weekDates.monday;
    const mondayCashCents = compositeEntertainerFinanceReport.mondayCashCents;
    const mondayBankTransferCents = compositeEntertainerFinanceReport.mondayBankTransferCents;
    const mondayTotalCents =  mondayCashCents + mondayBankTransferCents;
    const mondayHasDeletedHours = compositeEntertainerFinanceReport.mondayHasDeletedHours;
    const mondayErrors = compositeEntertainerFinanceReport.mondayValidationErrors;
    const mondayBreakdownItems = compositeEntertainerFinanceReport.mondayDayBreakdownItems;

    const tuesdayDate = weekDates.tuesday;
    const tuesdayCashCents = compositeEntertainerFinanceReport.tuesdayCashCents;
    const tuesdayBankTransferCents = compositeEntertainerFinanceReport.tuesdayBankTransferCents;
    const tuesdayTotalCents = tuesdayCashCents + tuesdayBankTransferCents;
    const tuesdayHasDeletedHours = compositeEntertainerFinanceReport.tuesdayHasDeletedHours;
    const tuesdayErrors = compositeEntertainerFinanceReport.tuesdayValidationErrors;
    const tuesdayBreakdownItems = compositeEntertainerFinanceReport.tuesdayDayBreakdownItems;

    const wednesdayDate = weekDates.wednesday;
    const wednesdayCashCents = compositeEntertainerFinanceReport.wednesdayCashCents;
    const wednesdayBankTransferCents = compositeEntertainerFinanceReport.wednesdayBankTransferCents;
    const wednesdayTotalCents = wednesdayCashCents + wednesdayBankTransferCents;
    const wednesdayHasDeletedHours = compositeEntertainerFinanceReport.wednesdayHasDeletedHours;
    const wednesdayErrors = compositeEntertainerFinanceReport.wednesdayValidationErrors;
    const wednesdayBreakdownItems = compositeEntertainerFinanceReport.wednesdayDayBreakdownItems;

    const thursdayDate = weekDates.thursday;
    const thursdayCashCents = compositeEntertainerFinanceReport.thursdayCashCents;
    const thursdayBankTransferCents = compositeEntertainerFinanceReport.thursdayBankTransferCents;
    const thursdayTotalCents = thursdayCashCents + thursdayBankTransferCents;
    const thursdayHasDeletedHours = compositeEntertainerFinanceReport.thursdayHasDeletedHours;
    const thursdayErrors = compositeEntertainerFinanceReport.thursdayValidationErrors;
    const thursdayBreakdownItems = compositeEntertainerFinanceReport.thursdayDayBreakdownItems;

    const fridayDate = weekDates.friday;
    const fridayCashCents = compositeEntertainerFinanceReport.fridayCashCents;
    const fridayBankTransferCents = compositeEntertainerFinanceReport.fridayBankTransferCents;
    const fridayTotalCents = fridayCashCents + fridayBankTransferCents;
    const fridayHasDeletedHours = compositeEntertainerFinanceReport.fridayHasDeletedHours;
    const fridayErrors = compositeEntertainerFinanceReport.fridayValidationErrors;
    const fridayBreakdownItems = compositeEntertainerFinanceReport.fridayDayBreakdownItems;

    const saturdayDate = weekDates.saturday;
    const saturdayCashCents = compositeEntertainerFinanceReport.saturdayCashCents;
    const saturdayBankTransferCents = compositeEntertainerFinanceReport.saturdayBankTransferCents;
    const saturdayTotalCents = saturdayCashCents + saturdayBankTransferCents;
    const saturdayHasDeletedHours = compositeEntertainerFinanceReport.saturdayHasDeletedHours;
    const saturdayErrors = compositeEntertainerFinanceReport.saturdayValidationErrors;
    const saturdayBreakdownItems = compositeEntertainerFinanceReport.saturdayDayBreakdownItems;

    const sundayDate = weekDates.sunday;
    const sundayCashCents = compositeEntertainerFinanceReport.sundayCashCents;
    const sundayBankTransferCents = compositeEntertainerFinanceReport.sundayBankTransferCents;
    const sundayTotalCents = sundayCashCents + sundayBankTransferCents;
    const sundayHasDeletedHours = compositeEntertainerFinanceReport.sundayHasDeletedHours;
    const sundayErrors = compositeEntertainerFinanceReport.sundayValidationErrors;
    const sundayBreakdownItems = compositeEntertainerFinanceReport.sundayBreakdownItems;

    const dayData: { date: Date, cashCents: number, bankTransferCents: number, totalCents: number, hasDeletedHours: boolean, errors: Set<string>, breakdownItems: DayBreakdownItem[] }[] = [
      { date: mondayDate, cashCents: mondayCashCents, bankTransferCents: mondayBankTransferCents, totalCents: mondayTotalCents, hasDeletedHours: mondayHasDeletedHours, errors: mondayErrors, breakdownItems: mondayBreakdownItems },
      { date: tuesdayDate, cashCents: tuesdayCashCents, bankTransferCents: tuesdayBankTransferCents, totalCents: tuesdayTotalCents, hasDeletedHours: tuesdayHasDeletedHours, errors: tuesdayErrors, breakdownItems: tuesdayBreakdownItems },
      { date: wednesdayDate, cashCents: wednesdayCashCents, bankTransferCents: wednesdayBankTransferCents, totalCents: wednesdayTotalCents, hasDeletedHours: wednesdayHasDeletedHours, errors: wednesdayErrors, breakdownItems: wednesdayBreakdownItems },
      { date: thursdayDate, cashCents: thursdayCashCents, bankTransferCents: thursdayBankTransferCents, totalCents: thursdayTotalCents, hasDeletedHours: thursdayHasDeletedHours, errors: thursdayErrors, breakdownItems: thursdayBreakdownItems },
      { date: fridayDate, cashCents: fridayCashCents, bankTransferCents: fridayBankTransferCents, totalCents: fridayTotalCents, hasDeletedHours: fridayHasDeletedHours, errors: fridayErrors, breakdownItems: fridayBreakdownItems },
      { date: saturdayDate, cashCents: saturdayCashCents, bankTransferCents: saturdayBankTransferCents, totalCents: saturdayTotalCents, hasDeletedHours: saturdayHasDeletedHours, errors: saturdayErrors, breakdownItems: saturdayBreakdownItems },
      { date: sundayDate, cashCents: sundayCashCents, bankTransferCents: sundayBankTransferCents, totalCents: sundayTotalCents, hasDeletedHours: sundayHasDeletedHours, errors: sundayErrors, breakdownItems: sundayBreakdownItems },
    ] as const;
    return dayData.map((dataItem) => {
      const key = `${compositeEntertainerFinanceReport.entertainerId}-${dataItem.date}`;
      const cellClassNames = getCellClassNames({ alertStyles: false });
      const linkClassNames = getLinkClassNames({ alertStyles: false });
      const textClassNames = getTextClassNames({ alertStyles: false });
      const href = appRoutes.entertainersHoursOverview({
        entertainerId: compositeEntertainerFinanceReport.entertainerId,
        date: dataItem.date,
      });
      const errors = dataItem.errors;
      const renderLink = dataItem.breakdownItems.length > 0;
      const showBreakdown = dataItem.breakdownItems.length > 0;

      const breakdownTooltipContent: React.ReactNode = showBreakdown ? (
        <ol>
          {dataItem.breakdownItems.map((breakdownItem, index) => {
            if (breakdownItem.paymentData.paymentType === CASH_PAYMENT_TYPE || breakdownItem.paymentData.paymentType === BANK_TRANSFER_PAYMENT_TYPE) {
              return (
                <li key={`breakdown-item-${index}`}>
                  <h3>{breakdownItem.venueName}</h3>
                  <p>{format(breakdownItem.startTime, SHORT_TIME_FORMAT)} - {format(breakdownItem.endTime, SHORT_TIME_FORMAT)} ({breakdownItem.entertainmentType})</p>
                  <a href={breakdownItem.linkUrl}>View</a>
                  <p>Status: {breakdownItem.status}</p>
                  <p>Payment Type: {breakdownItem.paymentData.paymentType}</p>
                  { breakdownItem.paymentData.payRate.payRateType === HOURLY_PAY_RATE_TYPE && (
                     <p>{formatAsCurrency({ cents: breakdownItem.paymentData.payRate.hourlyRateCents })}/h</p>
                  ) }
                  { breakdownItem.paymentData.payRate.payRateType === FIXED_PAY_RATE_TYPE && (
                    <p>{formatAsCurrency({ cents: breakdownItem.paymentData.payRate.fixedCents })}</p>
                  )}
                </li>
              );
            } else {
              throw new Error(`Unknown payment type: ${breakdownItem.paymentData.paymentType}`);
            }
          })}
        </ol>
      ) : <div />;

      return (
        <div
          key={key}
          className={cellClassNames}
          style={cellStyle}
        >
          <p
            style={{ marginBottom: 0 }}
            className={textClassNames}
          >
            <ConditionalWrap
              condition={showBreakdown}
              wrap={(content) => (
                <Tooltip
                  arrow
                  theme="light"
                  position="right"
                  interactive
                  html={breakdownTooltipContent}
                >
                  {content}
                </Tooltip>
              )}
            >
              <ConditionalWrap
                condition={renderLink}
                wrap={(content) => (
                  <a
                    href={href}
                    className={linkClassNames}
                  >
                    {content}
                  </a>
                )}
              >
                { formatAsCurrency({ cents: dataItem.totalCents }) }
              </ConditionalWrap>
            </ConditionalWrap>
          </p>
          {dataItem.hasDeletedHours && (
            <div className="boss-corner boss-corner_position_bottom-left boss-corner_color_accent-red-saturated">
              <span className="boss-corner__icon boss-corner__icon_trash" />
            </div>
          )}
          { errors.size > 0 && (
            renderQuestionMarkTooltip({ errors })
          )}
        </div>
      );
    });
  }

  return (
    <div
      id={compositeEntertainerFinanceReport.id.toString()}
      className={rowClassNames}
    >
      <div className={nameCellClassNames}>
        <p className="boss-table__text">
          <a
            href={appRoutes.entertainerProfile({ id: compositeEntertainerFinanceReport.entertainerId })}
            className="boss-table__link"
            style={{ textTransform: 'capitalize' }}
          >
            {compositeEntertainerFinanceReport.entertainerName}
          </a>
        </p>
      </div>
      { renderWeekDayCells()}
      <div className={getCellClassNames({ alertStyles: false })}>
        <p className={getTextClassNames({ alertStyles: false })}>
          {formatAsCurrency({ cents: compositeEntertainerFinanceReport.tuesdayCashCents })}
        </p>
      </div>
      <div className={getCellClassNames({ alertStyles: false })}>
        <p className={getTextClassNames({ alertStyles: false })}>
          {formatAsCurrency({ cents: compositeEntertainerFinanceReport.totalBankTransferCents })}
        </p>
      </div>
      <div className={getCellClassNames({ alertStyles: false })}>
        <p className={getTextClassNames({ alertStyles: false })}>
          {formatAsCurrency({ cents: compositeEntertainerFinanceReport.totalCents })}
        </p>
      </div>
      <div className="boss-table__cell">
        <StatusColumn
          allDataExists={props.allDataExists}
          permissions={props.permissions}
          isWeekFinished={props.isWeekFinished}
          onMarkCompleted={onMarkCompleted}
          onUncomplete={onUncomplete}
          compositeEntertainerFinanceReport={props.compositeEntertainerFinanceReport}
        />
      </div>
      <div className={'boss-table__cell'}>
        {!entertainerHasBankDetails && <p className="boss-table__text">N/A</p>}
        {entertainerHasBankDetails && (
          <Fragment>
            <p className="boss-table__text">
              <span className="boss-indicator boss-table__indicator">
                <span className={'boss-indicator__icon boss-indicator__icon_check-bold'} />
              </span>
            </p>
            {canViewBankDetails && (
              <div className="boss-table__actions">
                <button
                  onClick={() => onViewBankDetailsClick(props.compositeEntertainerFinanceReport)}
                  className="boss-button boss-button_type_extra-small boss-button_role_view-bank-details boss-table__action"
                >
                  View
                </button>
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div className="boss-table__cell">
        { note && renderNoteTooltip(note) }
        <div className="boss-table__actions">
          {note && (
            <button
              onClick={() => onNoteClick({ compositeEntertainerFinanceReport: props.compositeEntertainerFinanceReport, edit: !!note })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Edit Note
            </button>
          )}
          {!note && (
            <button
              onClick={() => onNoteClick({ compositeEntertainerFinanceReport: props.compositeEntertainerFinanceReport, edit: !!note })}
              className="boss-button boss-button_type_extra-small boss-button_role_primary-light boss-table__action"
            >
              Add Note
            </button>
          )}
        </div>
      </div>
    </div>
  );
}