import { BossFormInput } from '@/components/boss-form';
import oFetch from 'o-fetch';
import React from 'react';
import { Field } from 'react-final-form';

export function BankDetailsField(props: any) {
  const bankAccountNumberInput = oFetch(props, 'bankAccountNumber.input');
  const bankSortCodeInput = oFetch(props, 'bankSortCode.input');
  const altBankingFullnameInput = oFetch(props, 'altBankingFullname.input');
  const disabled = oFetch(props, 'disabled');

  return (
    <>
      <Field
        parse={value => value}
        name={bankAccountNumberInput.name}
        disabled={disabled}
        label="Account Number"
        component={BossFormInput}
      />
      <Field
        parse={value => value}
        disabled={disabled}
        name={bankSortCodeInput.name}
        label="Sort Code"
        component={BossFormInput}
      />
      <Field
        parse={value => value}
        disabled={disabled}
        name={altBankingFullnameInput.name}
        label="Name as on Bank Account (optional)"
        component={BossFormInput}
      />
    </>
  );
}

BankDetailsField.defaultProps = {
  disabled: false,
};
