import oFetch from 'o-fetch';
import React, { ReactElement } from 'react';

type Props = {
  entertainers: any[];
  renderItem: (entertainer: any) => ReactElement;
};

export function List(props: Props) {
  const entertainers = oFetch(props, 'entertainers');
  const entertainersExist = entertainers.length > 0;

  const renderItem = oFetch(props, 'renderItem');

  function renderItems() {
    return entertainers.map((entertainer: any) => {
      const entertainerId = oFetch(entertainer, 'id');

      return React.cloneElement(renderItem(entertainer), {
        key: entertainerId
      });
    });
  }
  if (!entertainersExist) {
    return (
      <h4>No Entertainers Exist</h4>
    );
  }
  return (
    <div className="boss-table boss-table_page_staff-members-index">
      <div className="boss-table__row">
        <div className="boss-table__cell boss-table__cell_role_header" />
        <div className="boss-table__cell boss-table__cell_role_header">Name</div>
        <div className="boss-table__cell boss-table__cell_role_header">Type</div>
        <div className="boss-table__cell boss-table__cell_role_header">Description</div>
      </div>
      {renderItems()}
    </div>
  );
}
