import z from 'zod';
import { $BossUiDate, $BossUiTime } from '@/lib/schema-funcs';
import { ALL_TAB_FILTER_OPTION, CASH_TAB_FILTER_OPTION, BANK_TRANSFER_TAB_FILTER_OPTION, CASH_PAYMENT_TYPE, BANK_TRANSFER_PAYMENT_TYPE, FIXED_PAY_RATE_TYPE, HOURLY_PAY_RATE_TYPE, SPOTIFY_ENTERTAINMENT_TYPE, DJ_ENTERTAINMENT_TYPE, BAND_ENTERTAINMENT_TYPE, SINGER_ENTERTAINMENT_TYPE, OTHER_ENTERTAINMENT_TYPE, PENDING_BREAKDOWN_ITEM_STATUS, ACCEPTED_BREAKDOWN_ITEM_STATUS, ACTIVE_BREAKDOWN_ITEM_STATUS, FINISHED_BREAKDOWN_ITEM_STATUS, DISABLED_BREAKDOWN_ITEM_STATUS } from './constants';
import { WEEK_PENDING_UI_STATUS, WEEK_FINISHED_UI_STATUS, WEEK_READY_UI_STATUS, WEEK_INCOMPLETE_UI_STATUS } from './constants';
import { $TRotaWeek } from '@/lib/rota-date';
import { REPORT_REQUIRING_UPDATE_UI_STATUS, REPORT_PROCESSING_UI_STATUS, REPORT_READY_UI_STATUS, REPORT_DONE_UI_STATUS, REPORT_PENDING_UI_STATUS, REPORT_INCOMPLETE_UI_STATUS } from './constants';

const $AllTabFilterOption = z.literal(ALL_TAB_FILTER_OPTION);
const $CashTabFilterOption = z.literal(CASH_TAB_FILTER_OPTION);
const $BankTransferTabFilterOption = z.literal(BANK_TRANSFER_TAB_FILTER_OPTION);
const $ALL_TABLE_FILTER_OPTIONS = [
  $AllTabFilterOption,
  $CashTabFilterOption,
  $BankTransferTabFilterOption,
] as const;
export const FILTER_TAB_OPTIONS = $ALL_TABLE_FILTER_OPTIONS.map((tabFilterOption) => tabFilterOption._def.value);
export const $TabFilterOption = z.union($ALL_TABLE_FILTER_OPTIONS);
export type TabFilterOption = z.infer<typeof $TabFilterOption>;

export const $VenueFilter = z.object({
  allVenues: z.boolean(),
  venueIds: z.number().int().nonnegative().array().min(1),
}).strict();

export const $PageFilter = z.object({
  venueFilter: $VenueFilter,
  tab: $TabFilterOption,
}).strict();

export type PageFilter = z.infer<typeof $PageFilter>;

export const $OnTabChangeArgs = z.object({
  tab: $TabFilterOption,
}).strict();
export type OnTabChangeArgs = z.infer<typeof $OnTabChangeArgs>;
const $OnTabChangeFunc = z.function().args(
  $OnTabChangeArgs
).returns(z.void());
type OnTabChangeFunc = z.infer<typeof $OnTabChangeFunc>;

const $OnVenueIdsChangeFuncArgs = z.object({
  venueIds: z.number().int().nonnegative().array()
}).strict();
export type OnVenueIdsChangeFuncArgs = z.infer<typeof $OnVenueIdsChangeFuncArgs>;

export const $OnVenueIdsChangeFunc = z.function().args($OnVenueIdsChangeFuncArgs).returns(z.void());
type OnVenueIdsChangeFunc = z.infer<typeof $OnVenueIdsChangeFunc>;

const $VenueOption = z.object({
  name: z.string().min(1),
  id: z.number().int().nonnegative(),
});
type VenueOption = z.infer<typeof $VenueOption>;

const $StringyInteger = z.string().regex(
  new RegExp(/^\d+$/),
  'invalid string format expected "3"'
).transform((value) => {
  return parseInt(value, 10);
});

export const $VenueFilterChangeFormValue = z.string().regex(new RegExp(/^(\d|,\d)*$/), 'invalid string format expected "3,2,3"').transform((value) => {
  if (value === '') {
    return [] as number[];
  }
  const splitValues = value.split(',');
  return $StringyInteger.array().parse(splitValues);
});

export type EntertainerFinanceReportsFilterParams = {
  venueOptions: VenueOption[];
  onTabChange: OnTabChangeFunc;
  onVenueIdsChange: OnVenueIdsChangeFunc;
  activeFilter: PageFilter;
};

const $WeekUiStatus = z.union([
  z.literal(WEEK_PENDING_UI_STATUS),
  z.literal(WEEK_FINISHED_UI_STATUS),
  z.literal(WEEK_READY_UI_STATUS),
  z.literal(WEEK_INCOMPLETE_UI_STATUS),
]);

const $EntertainerFinanceReportWeek = z.object({
  startDate: $BossUiDate(z),
  endDate: $BossUiDate(z),
  uiStatus: $WeekUiStatus,
  isFinished: z.boolean(),
  finishedByName: z.string().min(1).nullable(),
  finishedAt: $BossUiTime(z).nullable(),
}).strict();

const $EffectiveEntertainerFinanceReportStatus = z.union([
  z.literal(REPORT_REQUIRING_UPDATE_UI_STATUS),
  z.literal(REPORT_PROCESSING_UI_STATUS),
  z.literal(REPORT_READY_UI_STATUS),
  z.literal(REPORT_DONE_UI_STATUS),
  z.literal(REPORT_PENDING_UI_STATUS),
  z.literal(REPORT_INCOMPLETE_UI_STATUS),
]);

const $FixedBreakdownPayRate = z.object({
  payRateType: z.literal(FIXED_PAY_RATE_TYPE),
  fixedCents: z.number().int().nonnegative(),
}).strict();
const $HourlyBreakdownPayRate = z.object({
  payRateType: z.literal(HOURLY_PAY_RATE_TYPE),
  hourlyRateCents: z.number().int().nonnegative(),
}).strict();

const $DayBreakdownPayRate = z.union([
  $FixedBreakdownPayRate,
  $HourlyBreakdownPayRate,
]);

const $DayBreakdownPaymentData = z.object({
  paymentType: z.union([
    z.literal(CASH_PAYMENT_TYPE),
    z.literal(BANK_TRANSFER_PAYMENT_TYPE),
  ]),
  payRate: $DayBreakdownPayRate,
}).strict();

const $BreakDownItemStatus = z.union([
  z.literal(PENDING_BREAKDOWN_ITEM_STATUS),
  z.literal(ACCEPTED_BREAKDOWN_ITEM_STATUS),
  z.literal(ACTIVE_BREAKDOWN_ITEM_STATUS),
  z.literal(FINISHED_BREAKDOWN_ITEM_STATUS),
  z.literal(DISABLED_BREAKDOWN_ITEM_STATUS),
]);

const $BreakDownItemEntertainmentType = z.union([
  z.literal(SPOTIFY_ENTERTAINMENT_TYPE),
  z.literal(DJ_ENTERTAINMENT_TYPE),
  z.literal(BAND_ENTERTAINMENT_TYPE),
  z.literal(SINGER_ENTERTAINMENT_TYPE),
  z.literal(OTHER_ENTERTAINMENT_TYPE),
]);

export const $DayBreakdownItem = z.object({
  status: $BreakDownItemStatus,
  entertainmentType: $BreakDownItemEntertainmentType,
  paymentData: $DayBreakdownPaymentData,
  venueName: z.string().min(1),
  venueId: z.number().int().nonnegative(),
  startTime: $BossUiTime(z),
  endTime: $BossUiTime(z),
  acceptedAt: $BossUiTime(z).nullable(),
  linkUrl: z.string().min(1),
}).strict();
export type DayBreakdownItem = z.infer<typeof $DayBreakdownItem>;

export const $EntertainerFinanceReportVenuePart = z.object({
  date: $BossUiDate(z),
  venueId: z.number().int().nonnegative(),
  entertainerFinanceReportId: z.number().int().nonnegative(),
  payableCashCents: z.number().int().nonnegative(),
  payableBankTransferCents: z.number().int().nonnegative(),
  hasDeletedHours: z.boolean(),
  validationErrors: z.string().min(1).array(),
  dayBreakdownItem: $DayBreakdownItem,
}).strict();
export type EntertainerFinanceReportVenuePart = z.infer<typeof $EntertainerFinanceReportVenuePart>;

const $EntertainerFinanceReport = z.object({
  id: z.number().int().nonnegative(),
  isDone: z.boolean(),
  isCompletable: z.boolean(),
  pendingCalculation: z.boolean(),
  entertainerDisabled: z.boolean(),
  entertainerHasBankDetails: z.boolean(),
  entertainerName: z.string().min(1),
  entertainerId: z.number().int().nonnegative(),
  completedAt: $BossUiTime(z).nullable(),
  effectiveStatus: $EffectiveEntertainerFinanceReportStatus,
  note: z.string().min(1).nullable(),
  entertainerFinanceReportVenueParts: $EntertainerFinanceReportVenuePart.array(),
}).strict();
export type EntertainerFinanceReport = z.infer<typeof $EntertainerFinanceReport>;

export const $CompositeEntertainerFinanceReport = $EntertainerFinanceReport
  .merge(
    z.object({
      mondayDayBreakdownItems: $DayBreakdownItem.array(),
      tuesdayDayBreakdownItems: $DayBreakdownItem.array(),
      wednesdayDayBreakdownItems: $DayBreakdownItem.array(),
      thursdayDayBreakdownItems: $DayBreakdownItem.array(),
      fridayDayBreakdownItems: $DayBreakdownItem.array(),
      saturdayDayBreakdownItems: $DayBreakdownItem.array(),
      sundayBreakdownItems: $DayBreakdownItem.array(),
      mondayCashCents: z.number().int().nonnegative(),
      tuesdayCashCents: z.number().int().nonnegative(),
      wednesdayCashCents: z.number().int().nonnegative(),
      thursdayCashCents: z.number().int().nonnegative(),
      fridayCashCents: z.number().int().nonnegative(),
      saturdayCashCents: z.number().int().nonnegative(),
      sundayCashCents: z.number().int().nonnegative(),
      mondayBankTransferCents: z.number().int().nonnegative(),
      tuesdayBankTransferCents: z.number().int().nonnegative(),
      wednesdayBankTransferCents: z.number().int().nonnegative(),
      thursdayBankTransferCents: z.number().int().nonnegative(),
      fridayBankTransferCents: z.number().int().nonnegative(),
      saturdayBankTransferCents: z.number().int().nonnegative(),
      sundayBankTransferCents: z.number().int().nonnegative(),
      mondayHasDeletedHours: z.boolean(),
      tuesdayHasDeletedHours: z.boolean(),
      wednesdayHasDeletedHours: z.boolean(),
      thursdayHasDeletedHours: z.boolean(),
      fridayHasDeletedHours: z.boolean(),
      saturdayHasDeletedHours: z.boolean(),
      sundayHasDeletedHours: z.boolean(),
      mondayValidationErrors: z.set(z.string().min(1)),
      tuesdayValidationErrors: z.set(z.string().min(1)),
      wednesdayValidationErrors: z.set(z.string().min(1)),
      thursdayValidationErrors: z.set(z.string().min(1)),
      fridayValidationErrors: z.set(z.string().min(1)),
      saturdayValidationErrors: z.set(z.string().min(1)),
      sundayValidationErrors: z.set(z.string().min(1)),
      totalCashCents: z.number().int().nonnegative(),
      totalBankTransferCents: z.number().int().nonnegative(),
      totalCents: z.number().int().nonnegative(),
    }).strict()
  ).omit({
    entertainerFinanceReportVenueParts: true,
  }).strict();
export type CompositeEntertainerFinanceReport = z.infer<typeof $CompositeEntertainerFinanceReport>;

const $EntertainerFinanceReportsPageData = z.object({
  containsAllReports: z.boolean(),
  tab: $TabFilterOption,
}).strict();

const $Venue = z.object({
  id: z.number().int().nonnegative(),
  name: z.string().min(1),
}).strict();

const $Permissions = z.object({
  accessibleVenues: $Venue.array(),
  canViewBankDetails: z.boolean(),
  canCompleteEntertainerFinanceReports: z.boolean(),
  canFinishEntertainerFinanceReportWeek: z.boolean(),
}).strict();
type Permissions = z.infer<typeof $Permissions>;

export const $EntertainerFinanceReportsAppProps = z.object({
  permissions: $Permissions,
  pageData: $EntertainerFinanceReportsPageData,
  accessToken: z.string().min(1),
  entertainerFinanceReportWeek: $EntertainerFinanceReportWeek,
  entertainerFinanceReports: $EntertainerFinanceReport.array(),
  venueOptions: $VenueOption.array(),
}).strict();

export type EntertainerFinanceReportsAppProps = z.infer<typeof $EntertainerFinanceReportsAppProps>;

export type EntertainerFinanceReportsPageProps = EntertainerFinanceReportsAppProps;

const $DashboardProps = z.object({
  allDataExists: z.boolean(),
  permissions: $Permissions,
  pageData: $EntertainerFinanceReportsPageData,
  title: z.string(),
  filter: $PageFilter,
  onTabChange: $OnTabChangeFunc,
  onVenueIdsChange: $OnVenueIdsChangeFunc,
  entertainerFinanceReportWeek: $EntertainerFinanceReportWeek,
  compositeEntertainerFinanceReports: $CompositeEntertainerFinanceReport.array(),
  venueOptions: $VenueOption.array(),
}).strict();

export type DashboardProps = z.infer<typeof $DashboardProps>;

export const $EntertainerFinanceReportsTableProps = z.object({
  allDataExists: z.boolean(),
  permissions: $Permissions,
  isWeekFinished: z.boolean(),
  rotaWeek: $TRotaWeek,
  compositeEntertainerFinanceReports: $CompositeEntertainerFinanceReport.array(),
}).strict();
export type EntertainerFinanceReportsTableProps = z.infer<typeof $EntertainerFinanceReportsTableProps>;

export const $ReportDataRowProps = z.object({
  allDataExists: z.boolean(),
  permissions: $Permissions,
  isWeekFinished: z.boolean(),
  rotaWeek: $TRotaWeek,
  compositeEntertainerFinanceReport: $CompositeEntertainerFinanceReport,
}).strict();
export type ReportDataRowProps = z.infer<typeof $ReportDataRowProps>;

export type StatusColumnProps = {
  allDataExists: boolean;
  permissions: Permissions;
  isWeekFinished: boolean;
  onMarkCompleted: (arg0: { entertainerId: number }) => void;
  onUncomplete: (arg0: { entertainerId: number }) => void;
  compositeEntertainerFinanceReport: CompositeEntertainerFinanceReport;
};

const $MarkAllReportsCompleteSectionProps = z.object({
  onMarkAllPageCompleted: z.function().args().returns(z.void()),
}).strict();
export type MarkAllReportsCompleteSectionProps = z.infer<typeof $MarkAllReportsCompleteSectionProps>;