import safeMoment from '@/lib/safe-moment';
import utils from '@/lib/utils';
import oFetch from 'o-fetch';
import React, { useCallback, useMemo } from 'react';

type Props = {
  entertainmentShifts: any[];
  renderShiftItem: (entertainmentShift: any) => React.JSX.Element;
  date: string;
};

export function DateItem(props: Props) {
  const entertainmentShifts = oFetch(props, 'entertainmentShifts');
  const renderShiftItem = oFetch(props, 'renderShiftItem');
  const date = oFetch(props, 'date');

  const formattedDate = useMemo(() => {
    return safeMoment.uiDateParse(date).format(utils.fullDayFormat);
  }, [date]);

  const renderShifts = useCallback(() => {
    return entertainmentShifts.map((entertainmentShift: any) => {
      const entertainmentShiftId = oFetch(entertainmentShift, 'id');
      return React.cloneElement(renderShiftItem(entertainmentShift), { key: entertainmentShiftId });
    });
  }, [entertainmentShifts]);

  return (
    <li className='boss-timeline__item boss-timeline__item_role_card'>
      <div className='boss-timeline__inner boss-timeline__inner_role_card'>
        <div className="boss-timeline__header boss-timeline__header_role_card">
          <h3 className="boss-timeline__title">
            <span className="boss-timeline__title-primary">{formattedDate}</span>
          </h3>
        </div>
        <div className='boss-timeline__content boss-timeline__content_role_card'>
          <div className='boss-timeline__records'>
            {renderShifts()}
          </div>
        </div>
      </div>
    </li>
  );
}
