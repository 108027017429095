import React, { useMemo } from 'react';
import Select, { Async as SelectAsync } from '@/lib/boss-react-select';
import oFetch from 'o-fetch';
import { SelectTimeInputRenderer } from '@/components/boss-form/time-input-render';
import safeMoment from '@/lib/safe-moment';
import { TimeSelectOption, TimeSelectValue } from '../boss-form/colored-select';

export function BossFormShiftTimeInput(props: any) {
  const options = oFetch(props, 'options');
  const disabled = oFetch(props, 'disabled');
  const startTimeField = oFetch(props, oFetch(props, 'startTimeFieldName'));
  const endTimeField = oFetch(props, oFetch(props, 'endTimeFieldName'));
  const timeStartTime = startTimeField.input.value;
  const timeEndTime = endTimeField.input.value;

  function getStartTimeOptions(_input: any, callback: any) {
    callback(null, {
      options: options,
      complete: true,
    });
  }

  const endTimeOptions = useMemo(() => {
    if (timeStartTime) {
      return options.filter((option: any) => {
        return safeMoment.iso8601Parse(option.value).isAfter(safeMoment.iso8601Parse(timeStartTime));
      });
    }
    return options;
  }, [timeStartTime]);

  function updateStartsTime(newValue: string) {
    if (!newValue) {
      return;
    }
    startTimeField.input.onChange(newValue);
  }

  function updateEndsTime(newValue: string) {
    if (!newValue) {
      return;
    }
    endTimeField.input.onChange(newValue);
  }

  return (
    <div className={`boss-form__field ${props.className}`}>
      <div className="boss-form__interval">
        <div className="boss-form__interval-item">
          <p className="boss-form__label">
            <span className="boss-form__label-text">Start</span>
          </p>
          <div
            className={`boss-form__select boss-form__select_role_time ${startTimeField.meta.touched && startTimeField.meta.submitError && 'boss-form__select_state_error'
              }`}
          >
            <SelectAsync
              value={timeStartTime}
              loadOptions={getStartTimeOptions}
              inputRenderer={SelectTimeInputRenderer}
              simpleValue
              clearable={false}
              disabled={disabled}
              searchable={true}
              matchPos="start"
              onChange={updateStartsTime}
              valueComponent={TimeSelectValue}
              optionComponent={TimeSelectOption}
            />
          </div>
          {startTimeField.meta.touched && startTimeField.meta.submitError && (
            <div className="boss-form__error">
              <p className="boss-form__error-text">
                <span className="boss-form__error-line">{startTimeField.meta.submitError}</span>
              </p>
            </div>
          )}
        </div>
        <div className="boss-form__interval-delimiter" />
        <div className="boss-form__interval-item">
          <p className="boss-form__label">
            <span className="boss-form__label-text">End</span>
          </p>
          <div
            className={`boss-form__select boss-form__select_role_time ${endTimeField.meta.touched && endTimeField.meta.submitError && 'boss-form__select_state_error'
              }`}
          >
            <Select
              value={timeEndTime}
              options={endTimeOptions}
              inputRenderer={SelectTimeInputRenderer}
              valueComponent={TimeSelectValue}
              optionComponent={TimeSelectOption}
              simpleValue
              matchPos="start"
              disabled={disabled}
              clearable={false}
              searchable={true}
              onChange={updateEndsTime}
            />
          </div>
          {endTimeField.meta.touched && endTimeField.meta.submitError && (
            <div className="boss-form__error">
              <p className="boss-form__error-text">
                <span className="boss-form__error-line">{endTimeField.meta.submitError}</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BossFormShiftTimeInput.defaultProps = {
  granularityInMinutes: 30,
  className: '',
  startTimeFieldName: 'starts_at',
  endTimeFieldName: 'ends_at',
  disabled: false
};
