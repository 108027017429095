import React from 'react';
import { EntertainerForm } from './entertainer-form';
import oFetch from 'o-fetch';

export function AddNewEntertainerModal(props: any) {
  const onSubmit = oFetch(props, 'onSubmit');
  const processing = oFetch(props, 'processing');
  const entertainerTypesOptions = oFetch(props, 'entertainerTypesOptions');

  const initialValues = {
    name: '',
    description: '',
    contacts: [],
    entertainerType: null,
    altBankingFullname: null,
    bankSortCode: null,
    bankAccountNumber: null,
    addBankDetails: false
  };

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <EntertainerForm
          onSubmit={onSubmit}
          processing={processing}
          initialValues={initialValues}
          entertainerTypesOptions={entertainerTypesOptions}
          buttonTitle={'Create'}
          disableNameEditing={false}
          showBankDetails={true}
        />
      </div>
    </div>
  );
}
