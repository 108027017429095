import React from 'react';
import { Filter, ShiftsContainer, TimeLine } from './components';
import oFetch from 'o-fetch';
import { shiftsHooks } from './shifts-hooks';

type PageProps = {
  entertainmentShifts: any[],
  entertainmentShiftVenues: any[],
};

type LoaderData = {
  startDate: string;
  endDate: string;
};

type Props = {
  page: PageProps;
  loaderData: LoaderData;
};

export function ShiftsPage(props: Props) {
  const page = oFetch(props, 'page');
  const loaderData = oFetch(props, 'loaderData');
  const startDate = oFetch(loaderData, 'startDate');
  const endDate = oFetch(loaderData, 'endDate');

  const initialEntertainmentShifts = oFetch(page, 'entertainmentShifts');
  const entertainmentShiftVenues = oFetch(page, 'entertainmentShiftVenues');

  const { groupedEntertainmentShifts, venuesOptions } = shiftsHooks({
    entertainmentShifts: initialEntertainmentShifts,
    entertainmentShiftVenues
  });

  return (
    <section className='boss-board'>
      <ShiftsContainer>
        <Filter
          startDate={startDate}
          endDate={endDate}
          venuesOptions={venuesOptions}
        />
        <TimeLine groupedEntertainmentShifts={groupedEntertainmentShifts} />
      </ShiftsContainer>
    </section>
  );
}
