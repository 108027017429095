import React from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';
import { EntertainersFilter, FilterValues } from './entertainers-filter';

type Props = {
  onAddNew: (params: any) => void;
  entertainersCount: number;
};

export function EntertainersDashboard(props: Props) {
  const onAddNew = oFetch(props, 'onAddNew');
  const entertainersCount = oFetch(props, 'entertainersCount');

  function handleFilter(filterValues: FilterValues) {
    const parsedHash = queryString.stringify(filterValues, { skipNull: true, skipEmptyString: true });
    const url = parsedHash ? `${window.location.pathname}?${parsedHash}` : window.location.pathname;
    window.location.href = url;
    return new Promise((resolve, reject) => { });
  }

  return (
    <div className="boss-page-main__dashboard">
      <div className='boss-page-main__inner'>
        <div className="boss-page-dashboard boss-page-dashboard_updated boss-page-dashboard_page_staff-members-index">
          <div className="boss-page-dashboard__group">
            <h1 className="boss-page-dashboard__title">
              <span className="boss-page-dashboard__title-text">Entertainers</span>
              <span className="boss-page-dashboard__title-info">{entertainersCount}</span>
            </h1>
            <div className="boss-page-dashboard__buttons-group">
              <button
                onClick={onAddNew}
                className="boss-button boss-button_role_add boss-page-dashboard__button"
              >
                Add New Entertainer
              </button>
            </div>
          </div>
          <div className="boss-page-dashboard__group">
            <EntertainersFilter
              onSubmit={handleFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
