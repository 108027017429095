import { BossFormSelectNew } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import oFetch from 'o-fetch';
import React, { useEffect, useMemo, useRef } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

type Props = {
  entertainerId: FieldRenderProps<number | null, any>;
  entertainmentType: FieldRenderProps<string | null, any>;
  noEntertainerTypes: string[];
  disabled: boolean;
};

export function EntertainerEntertainmentType(props: Props) {
  const entertainerId = oFetch(props, 'entertainerId');
  const entertainmentType = oFetch(props, 'entertainmentType');
  const noEntertainerTypes = oFetch(props, 'noEntertainerTypes');
  const entertainmentTypeOptions = oFetch(useLegacyGlobal(), 'entertainmentTypeOptions');
  const entertainersOptions = oFetch(useLegacyGlobal(), 'entertainers');
  const entertainmentTypeEntertainers = oFetch(useLegacyGlobal(), 'entertainmentTypeEntertainers');
  const disabled = oFetch(props, 'disabled');
  const entertainerIdInput = oFetch(entertainerId, 'input');
  const entertainmentTypeValue = oFetch(entertainmentType, 'input.value');
  const displayEntertainers = entertainmentTypeValue && !noEntertainerTypes.includes(entertainmentTypeValue);
  const previousSelectedEntertainmentTypeRef = useRef(entertainmentTypeValue);

  useEffect(() => {
    if (previousSelectedEntertainmentTypeRef) {
      if (previousSelectedEntertainmentTypeRef.current !== entertainmentTypeValue || !displayEntertainers) {
        entertainerIdInput.onChange(null);
      }
    }
  }, [entertainmentTypeValue]);


  const filteredEntertainersOptions = useMemo(() => {
    if (!entertainmentTypeValue) {
      return [];
    }
    const entertainerTypes = entertainmentTypeEntertainers[entertainmentTypeValue];
    if (!entertainerTypes) {
      throw new Error(`Selected Entertainment Type doesn't have entertainers`);
    }

    return entertainersOptions.filter((entertainersOption: any) => {
      const entertainerType = oFetch(entertainersOption, 'entertainerType');
      return entertainerTypes.includes(entertainerType);
    });
  }, [entertainmentTypeValue]);

  return (
    <>
      <Field
        name={'entertainmentType'}
        label="Entertainment Type"
        options={entertainmentTypeOptions}
        disabled={disabled}
        component={BossFormSelectNew}
      />
      {displayEntertainers && (
        <Field
          name={'entertainerId'}
          label="Entertainer"
          options={filteredEntertainersOptions}
          valueKey={'id'}
          labelKey={'name'}
          disabled={disabled}
          component={BossFormSelectNew}
        />
      )}
    </>
  );
}
