import { useModal } from "@/components/hooks-components/modals";
import oFetch from "o-fetch";
import { AddNewEntertainerModal } from './components';
import { useState } from "react";
import { createEntertainerRequest } from "./requests";
import { useLegacyGlobal } from "@/components/hooks-components/global";

export function entertainersListHook(params: any) {
  const entertainersFromProps = oFetch(params, 'entertainers');
  const totalCount = oFetch(params, 'totalCount');
  const allCount = oFetch(params, 'allCount');
  const pageSize = oFetch(params, 'pageSize');
  const currentPage = oFetch(params, 'currentPage');
  const [entertainers, setEntertainers] = useState(entertainersFromProps);
  const [pagination, setPagination] = useState({
    totalCount,
    allCount,
    pageSize,
    currentPage
  });
  const initialFilterValues = oFetch(useLegacyGlobal(), 'initialFilterValues');
  const entertainerTypesOptions = oFetch(useLegacyGlobal(), 'entertainerTypesOptions');

  const { openProcessingModal } = useModal();

  function updatePagination(newPagination: any) {
    setPagination(() => {
      return newPagination;
    });
  }

  function openAddNewEntertainerModal() {
    return openProcessingModal({
      onSubmit: (handleCloseModal: any, setProcessing: any, values: any) => {
        setProcessing(true);
        return createEntertainerRequest({
          queryParams: initialFilterValues,
          values: values,
          onSuccess(entertainers: any[], pagination: any) {
            setProcessing(false);
            handleCloseModal();
            setEntertainers(entertainers);
            updatePagination(pagination);
          },
          onFailure() {
            setProcessing(false);
          },
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Add New Entertainer',
      },
      props: {
        entertainerTypesOptions
      },
      ModalContent: AddNewEntertainerModal,
    });
  }

  return {
    entertainers,
    openAddNewEntertainerModal,
    entertainerTypesOptions,
    pagination
  };
}
