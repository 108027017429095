import { BossFormTextArea, Fields } from '@/components/boss-form';
import { BossFormShiftTimeInput } from '@/components/final-form-fields/boss-form-shift-time-input';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import oFetch from 'o-fetch';
import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { EntertainmentPaymentTypeField } from './fields/entertainment-payment-type-field';
import { EntertainerEntertainmentType } from './fields/entertainer-entertainment-type';
import { useModal } from '@/components/hooks-components/modals';

type Props = {
  onSubmit: () => void;
  initialValues: any;
  processing: boolean;
  onCloseForm: () => void;
  onDisable?: (params: any) => void;
  showDisableButton: boolean;
};



export function EntertainmentForm(props: Props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const onCloseForm = oFetch(props, 'onCloseForm');
  const showDisableButton = oFetch(props, 'showDisableButton');
  const initialValues = oFetch(props, 'initialValues');
  const processing = oFetch(props, 'processing');
  const timeSelectOptions = oFetch(useLegacyGlobal(), 'timeSelectOptions');
  const entertainmentPaymentTypeOptions = oFetch(useLegacyGlobal(), 'entertainmentPaymentTypeOptions');
  const noEntertainerTypes = oFetch(useLegacyGlobal(), 'noEntertainerTypes');
  const { openAreYouSureModal } = useModal();

  const entertainmentTypeDecorator = useMemo(() => {
    return createDecorator({
      field: 'entertainmentType',
      updates: {
        paymentType: (entertainmentTypeValue, allValues: any) => {
          if (entertainmentTypeValue && noEntertainerTypes.includes(entertainmentTypeValue)) {
            return 'none';
          }
          return allValues.paymentType;
        },
      }
    });
  }, []);

  function openAreYouSureToDisable(entertainmentShiftId: number) {
    return openAreYouSureModal({
      props: {
        text: 'This action will disable entertainment shift',
        buttonText: 'DISABLE',
        buttonPendingText: 'DISABLING ...',
      },
      onSubmit: (closeModal: any) => {
        const onDisable = oFetch(props, 'onDisable');
        return onDisable?.({
          values: { entertainmentShiftId },
          onSuccess() {
            closeModal();
            onCloseForm();
          },
          onFailure() {
            closeModal();
          }
        });
      },
    });
  }

  return (
    <div className="boss-modal-window__form">
      <div className="boss-form">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          decorators={[entertainmentTypeDecorator]}
          render={({ handleSubmit, submitting, submitError, values, ...rest }) => {
            const disabled = submitting || processing;
            const disabledPaymentType = noEntertainerTypes.includes((values as any).entertainmentType);

            return (
              <>
                {submitError && (
                  <div className="boss-alert boss-alert_role_area boss-alert_context_above">
                    <p className="boss-alert__text">{submitError}</p>
                  </div>
                )}
                <Fields
                  names={['entertainerId', 'entertainmentType']}
                  noEntertainerTypes={noEntertainerTypes}
                  disabled={disabled}
                  originalRender={EntertainerEntertainmentType}
                />
                <Fields
                  names={['startTime', 'endTime']}
                  originalRender={BossFormShiftTimeInput}
                  options={timeSelectOptions}
                  disabled={disabled}
                  startTimeFieldName={'startTime'}
                  endTimeFieldName={'endTime'}
                />
                <Field
                  name={'description'}
                  label="Description"
                  disabled={disabled}
                  parse={value => value}
                  component={BossFormTextArea}
                />
                <Fields
                  names={['paymentType', 'fixedPaymentCents', 'paymentCentsPerHour', 'payRateType']}
                  processing={disabled}
                  disabledPaymentType={disabledPaymentType}
                  options={entertainmentPaymentTypeOptions}
                  originalRender={EntertainmentPaymentTypeField}
                />
                <div
                  className="boss-form__field"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <button
                    onClick={handleSubmit}
                    disabled={disabled}
                    className="boss-button boss-button_role_confirm boss-form__submit"
                    type="button"
                  >
                    Submit
                  </button>
                  {showDisableButton && (
                    <button
                      onClick={() => openAreYouSureToDisable(oFetch(values as any, 'entertainmentShiftId'))}
                      disabled={disabled}
                      className="boss-button boss-button_role_disable boss-form__submit"
                      type="button"
                    >
                      Disable
                    </button>
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
