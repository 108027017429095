import oFetch from 'o-fetch';
import React from 'react';
import { EntertainmentForm } from './entertainment-form';
import { PAY_RATE_FIXED } from './fields/entertainment-payment-type-field';

type Props = {
  onSubmit: () => void;
  processing: boolean;
  rota: any;
  onClose: () => void;
};

export function NewEntertainmentModal(props: Props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const onClose = oFetch(props, 'onClose');
  const processing = oFetch(props, 'processing');
  const rota = oFetch(props, 'rota');
  const venueId = oFetch(rota, 'venue');
  const date = oFetch(rota, 'date');

  const initialValues = {
    entertainerId: null,
    venueId,
    rotaDate: date,
    startTime: null,
    endTime: null,
    description: '',
    entertainmentType: null,
    paymentType: null,
    fixedPaymentCents: null,
    paymentCentsPerHour: null,
    payRateType: PAY_RATE_FIXED
  };

  return (
    <EntertainmentForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      processing={processing}
      onCloseForm={onClose}
      showDisableButton={false}
    />
  );
}
