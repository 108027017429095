import oFetch from 'o-fetch';
import React from 'react';

type Props = {
  groupedEntertainmentShifts: any;
  renderItem: (params: any) => React.JSX.Element;
};

export function ShiftsList(props: Props) {
  const groupedEntertainmentShifts = oFetch(props, 'groupedEntertainmentShifts');
  const renderItem = oFetch(props, 'renderItem');

  function renderItems() {
    return Object.entries(groupedEntertainmentShifts).map((entertainmentShiftsEntry: any[]) => {
      const [date, entertainmentShifts] = entertainmentShiftsEntry;
      return React.cloneElement(renderItem({ entertainmentShifts, date }), { key: date });
    });
  }
  return (
    <div className='boss-timeline boss-timeline_role_shifts'>
      <ul className='boss-timeline__list'>
        {renderItems()}
      </ul>
    </div>
  );
}
