import oFetch from 'o-fetch';
import React from 'react';

type Props = {
  children: React.JSX.Element | React.JSX.Element[];
};

export function ShiftsContainer(props: Props) {
  const children = oFetch(props, 'children');
  return (
    <section className='boss-board'>
      <header className="boss-board__header">
        <h2 className="boss-board__title">Shifts</h2>
      </header>
      <div className='boss-board__main'>
        <div className='boss-board__manager'>
          <div className='boss-board__manager-group boss-board__manager-group_role_data'>
            {children}
          </div>
        </div>
      </div>
    </section>
  );
}
