import React from 'react';
import { MarkAllReportsCompleteSectionProps } from '../types';

export function MarkAllReportsCompleteSection(props: MarkAllReportsCompleteSectionProps): JSX.Element {
  return (
    <div className="boss-page-main__note boss-page-main__note_role_info">
      <h3 className="boss-page-main__note-title">Complete All Reports</h3>
      <p className="boss-page-main__note-text">All Reports on this page are completable</p>
      <p className="boss-page-main__note-text">
        Clicking here will complete all reports on this week after which no edits will be possible.
      </p>
      <p className="boss-page-main__note-text boss-page-main__note-text_role_important">
        Do not click unless you are sure the numbers are correct.
      </p>
      <div className="boss-page-main__note-actions">
        <button
          onClick={props.onMarkAllPageCompleted}
          type="button"
          className="boss-button boss-button_role_confirm"
        >
          Mark All Complete
        </button>
      </div>
    </div>
  );
}