import { bossRequestHttp } from '@/lib/request-api';
import utils from '@/lib/utils';
import { $ErrorHandlerParams, $UpdateEntertainerBankDetailsSuccessData, ErrorHandlerParams, SuccessHandlerParams, UpdateBankDetailsFormValues, UpdateEntertainerBankDetailsParams } from './types';
import { OutputValidationErrors } from '@/lib/normalize-final-form-errors';
import { updateEntertainerBankDetails as importedUpdateEntertainerBankDetails } from '@/lib/api-routes';
const apiRoutes = {
  updateEntertainerBankDetails: importedUpdateEntertainerBankDetails,
};

export const updateEntertainerBankDetails = (params: UpdateEntertainerBankDetailsParams) => {
  const entertainer = params.entertainer;
  const values = params.values;
  const onSuccess = params.onSuccess;
  const entertainerId = entertainer.id;

  return bossRequestHttp({
    errorHandler(rawParams: ErrorHandlerParams): OutputValidationErrors<any> | void {
      const params = $ErrorHandlerParams.parse(rawParams);
      const statusCode = params.statusCode;
      const errors = params.errors;
      const globalNotifications = params.globalNotifications;
      const supportedKeyChecker = params.supportedKeyChecker;

      if (statusCode === 422 && errors) {
        supportedKeyChecker.validateKeys({
          suppliedKeys: Object.keys(errors),
          supportedKeys: [utils.BASE_ERROR_KEY, 'bankAccountNumber', 'bankSortCode', 'altBankingFullname'],
        });
        const normalizedErrors = utils.normalizeFinalFormErrors(errors);
        globalNotifications.showDefaultFailureMessage({
          message: 'There was a problem updating bank details',
        });
        return normalizedErrors;
      }
    },
    successHandler(params: SuccessHandlerParams) {
      const rawData = params.data; //this will be parsed in onSuccess
      const data = $UpdateEntertainerBankDetailsSuccessData.parse(rawData);

      const globalNotifications = params.globalNotifications;
      globalNotifications.showDefaultSuccessMessage({
        message: 'Updating bank details successful',
      });
      onSuccess(data);
    },
  }).post<UpdateBankDetailsFormValues>(
    apiRoutes.updateEntertainerBankDetails.getPath({ entertainerId }),
    { ...values },
  );
};