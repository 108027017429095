import React from 'react';
import { ConfirmBankDetailsModalContentProps } from '../types';

export function ConfirmBankDetailsModalContent(props: ConfirmBankDetailsModalContentProps) {
  const onSubmit = props.onSubmit;

  const altBankingFullname = props.altBankingFullname;
  const ownerName = props.ownerName;
  const bankAccountNumber = props.bankAccountNumber;
  const bankName = props.bankName;
  const bankSortCode = props.bankSortCode;
  const branchName = props.branchName;

  return (
    <div
      className="boss-modal-window__content boss-modal-window__content_role_success"
      data-test-marker-pin-modal="true"
    >
      <div className="boss-modal-window__message-block">
        <span className="boss-modal-window__message-text">
          Please make sure that the details below are correct.
        </span>
      </div>
      <div className="boss-modal-window__message-block">
        <span
          className="boss-modal-window__message-text"
          style={{ textAlign: 'left' }}
        >
          <span className="boss-modal-window__message-text-marked">Official Name: </span>
          {altBankingFullname || ownerName } {altBankingFullname && "(alt)"}
        </span>
        <span
          className="boss-modal-window__message-text"
          style={{ textAlign: 'left' }}
        >
          <span className="boss-modal-window__message-text-marked">Bank Name: </span>
          {bankName}
        </span>
        <span
          className="boss-modal-window__message-text"
          style={{ textAlign: 'left' }}
        >
          <span className="boss-modal-window__message-text-marked">Branch Name: </span>
          {branchName}
        </span>
        <span
          className="boss-modal-window__message-text"
          style={{ textAlign: 'left' }}
        >
          <span className="boss-modal-window__message-text-marked">Account Number: </span>
          {bankAccountNumber}
        </span>
        <span
          className="boss-modal-window__message-text"
          style={{ textAlign: 'left' }}
        >
          <span
            className="boss-modal-window__message-text-marked"
          >Sort Code: </span>
          {bankSortCode}
        </span>
      </div>

      <div className="boss-modal-window__actions">
        <button
          onClick={() => onSubmit({ altBankingFullname })}
          type="button"
          className="boss-button boss-modal-window__button"
        >
          Accept
        </button>
      </div>
    </div>
  );
}
