import oFetch from 'o-fetch';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { CollapsibleFilter } from '@/components/hooks-components';
import { BossFormInput, BossFormSelectNew } from '@/components/boss-form';
import { useLegacyGlobal } from '@/components/hooks-components/global';

type Props = {
  onSubmit: (values: any) => void;
};

export type FilterValues = {
  name?: string;
  type?: string;
};

export function EntertainersFilter(props: Props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const entertainerTypesOptions = oFetch(useLegacyGlobal(), 'entertainerTypesOptions');
  const initialFilterValues = oFetch(useLegacyGlobal(), 'initialFilterValues');

  return (
    <CollapsibleFilter>
      <Form
        onSubmit={onSubmit}
        initialValues={initialFilterValues}
        render={({ handleSubmit, submitting, submitError, ...rest }) => {
          return (
            <div
              className="boss-form"
            >
              <div className="boss-form__row boss-form__row_position_last">
                <div className="boss-form__group boss-form__group_layout_half">
                  <Field
                    name={'name'}
                    label={'Name'}
                    disabled={submitting}
                    parse={value => value}
                    component={BossFormInput}
                  />
                </div>
                <div className="boss-form__group boss-form__group_layout_half">
                  <Field
                    name={'type'}
                    label={'Type'}
                    disabled={submitting}
                    parse={value => value}
                    options={entertainerTypesOptions}
                    component={BossFormSelectNew}
                    clearable
                  />
                  <div className="boss-form__row">
                    <div className="boss-form__field boss-form__field_layout_max boss-form__field_justify_end boss-form__field_no-label">
                      <button
                        className="boss-button boss-form__submit boss-form__submit_adjust_single"
                        onClick={handleSubmit}
                        disabled={submitting}
                      >
                        {submitting ? 'Filtering ...' : 'Filter'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    </CollapsibleFilter>
  );
}
