import React, { useMemo } from 'react';
import { RadioGroup, Radio } from '@/components/radio-group';
import { EntertainerFinanceReportsFilterParams, FILTER_TAB_OPTIONS, $TabFilterOption, $VenueFilterChangeFormValue } from '../types';
import { ALL_TAB_FILTER_OPTION, CASH_TAB_FILTER_OPTION, BANK_TRANSFER_TAB_FILTER_OPTION } from '../constants';
import Select from '@/lib/boss-react-select';

const FilterTypeDisplayNames = {
  [ALL_TAB_FILTER_OPTION]: 'All',
  [CASH_TAB_FILTER_OPTION]: 'Cash',
  [BANK_TRANSFER_TAB_FILTER_OPTION]: 'Bank Transfer',
};

export function EntertainerFinanceReportsFilter(props: EntertainerFinanceReportsFilterParams): JSX.Element {
  const allVenueIds = useMemo(() => {
    return props.venueOptions.map((venue) => venue.id);
  }, [props.venueOptions]);

  function renderFilterTabs() {
    return FILTER_TAB_OPTIONS.map((filterTab, index) => {
      return (
        <label
          key={index}
          className="boss-form__switcher-label"
        >
          <Radio
            value={filterTab}
            className="boss-form__switcher-radio"
          />
          <span
            className="boss-form__switcher-label-text"
          >{FilterTypeDisplayNames[filterTab]}
          </span>
        </label>
      );
    });
  }

  function handleTabChange(value: unknown): void {
    const parsedValue = $TabFilterOption.parse(value);
    props.onTabChange({ tab: parsedValue });
  }

  function handleVenueChange(value: unknown) {
    const newVenueIds = $VenueFilterChangeFormValue.parse(value);
    if (newVenueIds.length > 0) {
      props.onVenueIdsChange({ venueIds: newVenueIds });
    } else {
      // Deleting the last venueId should be treated as selecting all venues
      props.onVenueIdsChange({ venueIds: allVenueIds });
    }
  }

  const selectedValue = props.activeFilter.venueFilter.venueIds;

  return (
    <div className="boss-form">
      <div className="boss-form__field boss-form__field_layout_fluid">
        <div className="boss-form__select">
          <Select
            valueKey="id"
            labelKey="name"
            disabled={props.venueOptions.length === 0}
            value={selectedValue}
            label="Venues"
            simpleValue
            options={props.venueOptions}
            multi
            clearable={false}
            onChange={handleVenueChange}
          />
        </div>
      </div>
      <div className="boss-form__field boss-form__field_layout_fluid">
        <RadioGroup
          name="filterType"
          // className="boss-form__switcher"
          selectedValue={props.activeFilter.tab}
          onChange={handleTabChange}
        >
          {renderFilterTabs()}
        </RadioGroup>
      </div>
    </div>
  );
}