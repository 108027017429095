import getVenueColor from "@/lib/get-venue-color";
import utils from "@/lib/utils";
import oFetch from "o-fetch";
import { useEffect, useMemo, useState } from "react";

type ShiftsHooks = {
  entertainmentShifts: any[];
  entertainmentShiftVenues: any[];
};

export function shiftsHooks(params: ShiftsHooks) {
  const initialEntertainmentShifts = oFetch(params, 'entertainmentShifts');
  const entertainmentShiftVenues = oFetch(params, 'entertainmentShiftVenues');
  const [entertainmentShifts, setEntertainmentShifts] = useState<any[]>([]);
  const venuesOptions = useMemo(() => {
    return entertainmentShiftVenues.map((venue: any) => {
      return {
        value: `${oFetch(venue, 'type')}_${oFetch(venue, 'id')}`,
        label: oFetch(venue, 'name'),
        color: getVenueColor(oFetch(venue, 'id'))
      };
    });
  }, []);

  useEffect(() => {
    setEntertainmentShifts(initialEntertainmentShifts);
  }, [initialEntertainmentShifts]);

  const groupedByDateEntertainmentShifts = useMemo(() => {
    const groupByDate = utils.groupBy('date');

    const unordered = groupByDate(entertainmentShifts);
    return unordered;
  }, [entertainmentShifts]);

  return {
    groupedEntertainmentShifts: groupedByDateEntertainmentShifts,
    venuesOptions
  };
}
