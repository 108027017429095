import oFetch from 'o-fetch';
import React, { JSXElementConstructor, ReactElement } from 'react';

type Props = {
  itemRender: (entertainmentShift: any) => ReactElement<{ key: any; }, string | JSXElementConstructor<any>>;
  entertainmentShifts: any[];
};

export function EntertainmentShiftList(props: Props) {
  const itemRender = oFetch(props, 'itemRender');
  const entertainmentShifts = oFetch(props, 'entertainmentShifts');

  function renderItems() {
    return entertainmentShifts.map((entertainmentShift: any) => {
      const entertainmentShiftId = oFetch(entertainmentShift, 'id');

      return React.cloneElement(itemRender(entertainmentShift), {
        key: entertainmentShiftId
      });
    });
  }

  return (
    <>{renderItems()}</>
  );
}
