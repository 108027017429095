import {
  Await,
  defer,
  useLoaderData,
} from "react-router-dom-v6";
import React from 'react';
import queryString from 'query-string';
import { Fallback } from "./fallback";
import { shiftsPageInitialLoad } from "./pages/shifts/requests";
import moment from "moment";
import utils from "@/lib/utils";

export function loader(params: any) {
  const { pageInitialLoadRequest } = params;
  return async ({ params }: any) => {
    const pagePromise = pageInitialLoadRequest({ staffMemberId: params.id });
    return defer({
      page: pagePromise,
    });
  };
}

export async function shiftsLoader({ params, request }: any) {
  const [, searchParams] = request.url.split("?");
  const currentQueryString = queryString.parse(searchParams);

  let startDate = currentQueryString.start_date;
  let endDate = currentQueryString.end_date;

  if (!startDate || !endDate) {
    startDate = moment().startOf('year').format(utils.uiRotaDateFormat);
    endDate = moment().endOf('year').format(utils.uiRotaDateFormat);
    const url = `${window.location.pathname}?${queryString.stringify({
      ...currentQueryString,
      start_date: startDate,
      end_date: endDate,
    })}`;
    window.history.pushState(
      'state',
      'title',
      url,
    );
  }

  const pagePromise = shiftsPageInitialLoad({ staffMemberId: params.id, startDate, endDate });
  return defer({
    page: pagePromise,
    startDate,
    endDate
  });
}

export default function LoadingRoute(props: any) {
  const data = useLoaderData() as any;
  const { page, ...otherLoaderData } = data;
  return (
    <section className='boss-board'>
      <React.Suspense
        fallback={<Fallback />}
      >
        <Await
          resolve={page}
          errorElement={
            <p>Error loading package location!</p>
          }
        >
          {(page) => {
            return (
              <props.Component
                page={page.data}
                loaderData={otherLoaderData}
              />
            );
          }}
        </Await>
      </React.Suspense>
    </section>
  );
}
