import { bossRequestHttp } from '@/lib/request-api';

type TPageInitialLoad = {
  staffMemberId: number;
  startDate: string;
  endDate: string;
};

export function shiftsPageInitialLoad(params:TPageInitialLoad) {
  const { staffMemberId, startDate, endDate } = params;

  return bossRequestHttp({
    successHandler(params: any) {
      return params;
    },
    errorHandler() {},
  }).get(`/api/v1/entertainers/${staffMemberId}/shifts`, { params: { startDate, endDate } });
}
