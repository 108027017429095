import React from 'react';
import { Section, SectionItem } from './components';
import { $UpdateBankDetailsFormValues, ConfirmBankDetailsModalContentPropsWithoutSubmit, Entertainer, ProfilePageProps, UpdateBankDetailsModalContentPropsWithoutSubmit, UpdateEntertainerBankDetailsSuccessData } from './types';
import { BankDetailsListItem } from './components/bank-details-list-item';
import { openCustomContentModal } from '@/components/modals';
import { UpdateBankDetailsModalContent } from './components/update-bank-details-modal-content';
import { ConfirmBankDetailsModalContent } from './components/confirm-bank-details-modal-content';
import { updateEntertainerBankDetails } from './actions';
import { useEntertainer } from './use-entertainer';
import oFetch from 'o-fetch';
import { useLegacyGlobal } from '@/components/hooks-components/global';

export function ProfilePage(props: ProfilePageProps) {
  const page = oFetch(props, 'page');

  const { entertainer, updateBankDetails } = useEntertainer({
    entertainer: page.entertainer
  });
  const permissionsData = oFetch(useLegacyGlobal(), 'permissionsData');

  const contact = entertainer.contacts[0];

  const displayContactName = contact ? `${contact.firstName}${contact.surname ? ` ${contact.surname}` : ''}` : 'n/a';
  const displayContactEmail = contact ? contact.email : 'n/a';
  const displayContactPhone = contact ? contact.phoneNumber : 'n/a';

  function openUpdateBankDetailsModal(args: { entertainer: Entertainer }) {
    const entertainer = args.entertainer;
    const altBankingFullname = entertainer.altBankingFullname;
    const bankDetailsLastUpdatedAt = entertainer.bankDetailsLastUpdatedAt;
    const bankDetailsLastUpdatedBy = entertainer.bankDetailsLastUpdatedBy;

    const updateBankDetailsModalContentProps: UpdateBankDetailsModalContentPropsWithoutSubmit = {
      altBankingFullname,
      bankDetailsLastUpdatedAt,
      bankDetailsLastUpdatedBy,
    };
    return openCustomContentModal({
      submit: (closeModal: () => void, rawValues: unknown) => {
        const values = $UpdateBankDetailsFormValues.parse(rawValues);

        return updateEntertainerBankDetails({
          entertainer,
          values,
          onSuccess: (data: UpdateEntertainerBankDetailsSuccessData) => {
            updateBankDetails(data);
            closeModal();
            openConfirmBankDetailsModal({ data });
          },
        });
      },
      config: {
        title: 'Update Bank Details',
        modalClass: 'boss-modal-window_role_edit',
        titleClass: 'boss-modal-window__header',
      },
      props: updateBankDetailsModalContentProps,
    })(UpdateBankDetailsModalContent);
  }

  function openConfirmBankDetailsModal(args: { data: UpdateEntertainerBankDetailsSuccessData }) {
    const data = args.data;

    const confirmBankDetailsModalContentProps: ConfirmBankDetailsModalContentPropsWithoutSubmit = {
      ...data,
      ownerName: entertainer.name,
    };

    return openCustomContentModal({
      submit: (closeModal: () => void) => {
        closeModal();
      },
      config: {
        title: 'Bank Details Update Successful',
        modalClass: 'boss-modal-window_role_success',
        innerClose: false,
      },
      props: confirmBankDetailsModalContentProps,
    })(ConfirmBankDetailsModalContent);
  }

  return (
    <div className="boss-page-main__flow">
      <Section
        number={1}
        title="Details"
      >
        <SectionItem
          label="Name"
          value={entertainer.name}
        />
        <SectionItem
          label="Type"
          value={entertainer.entertainerType}
        />
        <SectionItem
          label="Description"
          value={entertainer.description || 'n/a'}
        />
        <SectionItem
          label="Contact Name"
          value={displayContactName}
        />
        <SectionItem
          label="Contact Email"
          value={displayContactEmail}
        />
        <SectionItem
          label="Contact Phone"
          value={displayContactPhone}
        />
        <BankDetailsListItem
          hasBankDetails={page.entertainer.hasBankDetails}
          canUpdateBankDetails={permissionsData.canUpdateBankDetails}
          onEditClick={() => {
            openUpdateBankDetailsModal({ entertainer });
          }}
        />
      </Section>
    </div>
  );
}
