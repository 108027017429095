import { RotaAppType, RotaDateUIUtils } from "@/lib/rota-date";
import utils from "@/lib/utils";
import safeMoment from '@/lib/safe-moment';
import moment from 'moment';
import oFetch from 'o-fetch';

type CalculateChartBoundaries = {
  entertainmentShifts: any[]
  appType: RotaAppType,
};

export function calculateEntertainmentChartBoundaries(props: CalculateChartBoundaries) {
  const entertainmentShifts = oFetch(props, 'entertainmentShifts');
  const appType = oFetch(props, 'appType');
  if (!Array.isArray(entertainmentShifts)) {
    throw new Error("entertainmentShifts must be array");
  }

  // Values indicating how many hours we're into the day
  let startOffset = 23; // means 7am based on an 8am start
  let endOffset = 1; // means 9am based on an 8am start

  let rotaDateUIUtils: RotaDateUIUtils;

  if (entertainmentShifts.length === 0) {
    startOffset = 0;
    endOffset = 24;
    rotaDateUIUtils = RotaDateUIUtils.sFromDate({
      sDate: moment().format(utils.commonDateFormat),
      appType,
    });
  } else {
    rotaDateUIUtils = RotaDateUIUtils.sFromDate({
      sDate: safeMoment.iso8601Parse(entertainmentShifts[0].startTime).format(utils.commonDateFormat),
      appType,
    });
    // Adjust offset range everytime we find a shift that's not contained inside it
    entertainmentShifts.forEach(function (rotaShift) {
      const shiftStartOffset = rotaDateUIUtils.mGetHoursSinceStartOfDay(safeMoment.iso8601Parse(rotaShift.startTime));
      const shiftEndOffset = rotaDateUIUtils.mGetHoursSinceStartOfDay(safeMoment.iso8601Parse(rotaShift.endTime));
      if (shiftStartOffset < startOffset) {
        startOffset = Math.floor(shiftStartOffset);
      }
      if (shiftEndOffset > endOffset) {
        endOffset =  Math.ceil(shiftEndOffset);
      }
    });

    startOffset -= 1;
    startOffset = utils.containNumberWithinRange(startOffset, [0, 24]);
    endOffset += 1;
    endOffset = utils.containNumberWithinRange(endOffset, [0, 24]);
  }
  const boundaries = {
    start: rotaDateUIUtils.mStartTime.clone().add(startOffset, 'hours'),
    end: rotaDateUIUtils.mStartTime.clone().add(endOffset, 'hours'),
  };
  return boundaries;
}
