import oFetch from 'o-fetch';
import React from 'react';
import { connect } from 'react-redux';
import ContentWrapper from '@/components/content-wrapper';
import { useModal } from '@/components/hooks-components/modals';
import { LegacyCloseOutsideModalWrapper } from '@/components/modal-wrappers';
import {
  ClockingDayItem,
  ClockingDayList,
  Filter,
  HoursConfirmationDashboard,
  WarningModal,
} from './components';
import * as actions from './redux/actions';
import * as selectors from './redux/selectors';
import { MOSS_APP_TYPE } from '@/lib/rota-date';
import { HoursOverviewOwedHoursSection } from './components/hours-overview-owed-hours-section';
import { EntertainmentShiftItem, EntertainmentShiftList } from './components/entertainment-shifts';

function HoursConfirmation(props) {
  const [
    handleFilterEffectiveStaffType,
    handleFilterName,
    handleClockOut,
    handleUnacceptHoursAcceptancePeriod,
    handleAddNewHoursAcceptancePeriod,
    handleDeleteHoursAcceptancePeriod,
    handleAcceptHoursAcceptancePeriod,
    handleReEnableHoursAcceptancePeriod,
    filterText,
    filterEffectiveStaffTypes,
    clockingDays,
    pageOptions,
    effectiveStaffTypes,
    activeSpecialSecurityPayrates,
    mossHourTags,
    owedHours,
    entertainmentShifts,
    acceptEntertainmentShiftAcceptance,
    disableEntertainmentShiftAcceptance
  ] = oFetch(
    props,
    'handleFilterEffectiveStaffType',
    'handleFilterName',
    'handleClockOut',
    'handleUnacceptHoursAcceptancePeriod',
    'handleAddNewHoursAcceptancePeriod',
    'handleDeleteHoursAcceptancePeriod',
    'handleAcceptHoursAcceptancePeriod',
    'handleReEnableHoursAcceptancePeriod',
    'filterText',
    'filterEffectiveStaffTypes',
    'clockingDays',
    'pageOptions',
    'effectiveStaffTypes',
    'activeSpecialSecurityPayrates',
    'mossHourTags',
    'owedHours',
    'entertainmentShifts',
    'acceptEntertainmentShiftAcceptance',
    'disableEntertainmentShiftAcceptance'
  );
  const { openPromiseModal } = useModal();
  const pageType = oFetch(pageOptions, 'pageType');

  const currentDay = oFetch(pageOptions, 'currentDay');
  const permissions = oFetch(pageOptions, 'permissions');
  function acceptHoursAcceptancePeriod(clockingDay) {
    const venue = oFetch(clockingDay, 'venue');
    const venueId = oFetch(venue, 'id');

    return values => {
      const effectiveStaffMember = oFetch(clockingDay, 'effectiveStaffMember');
      const appType = oFetch(effectiveStaffMember, 'appType');
      const hoursAcceptanceTimeLeft = oFetch(clockingDay, 'hoursAcceptanceTimeLeft');
      const requiresReason = (appType !== MOSS_APP_TYPE) && hoursAcceptanceTimeLeft < 0;

      if (!requiresReason) {
        return handleAcceptHoursAcceptancePeriod({ ...values, venueId, appType });
      } else {
        return openPromiseModal({
          ModalComponent: LegacyCloseOutsideModalWrapper,
          ModalContent: WarningModal,
          onSubmit(closeModal, reasonNote) {
            return handleAcceptHoursAcceptancePeriod({ ...values, reasonNote, venueId, appType }, closeModal);
          },
          props: {},
          config: {
            title: 'WARNING !!!',
            baseClassName: 'boss-modal-window boss-modal-window_role_confirm',
          },
        });
      }
    };
  }

  function renderClockingDay(clockingDay) {
    return (
      <ClockingDayItem
        mossHourTags={mossHourTags}
        clockingDay={clockingDay}
        onClockOut={handleClockOut}
        pageType={pageType}
        onAddNewHoursAcceptancePeriod={handleAddNewHoursAcceptancePeriod}
        onUnaccept={handleUnacceptHoursAcceptancePeriod}
        onDeleteHoursAcceptancePeriod={handleDeleteHoursAcceptancePeriod}
        onAccept={acceptHoursAcceptancePeriod(clockingDay)}
        onUndoHoursAcceptancePeriod={handleReEnableHoursAcceptancePeriod}
        permissions={permissions}
        currentDay={currentDay}
        activeSpecialSecurityPayrates={activeSpecialSecurityPayrates}
      />
    );
  }
  return (
    <div>
      <HoursConfirmationDashboard pageOptions={pageOptions} />
      <ContentWrapper>
        {pageType !== 'overview' && (
          <Filter
            onFilterEffectiveStaffType={handleFilterEffectiveStaffType}
            onFilterName={handleFilterName}
            filterText={filterText}
            filterEffectiveStaffTypes={filterEffectiveStaffTypes}
            effectiveStaffTypes={effectiveStaffTypes}
          />
        )}
        {pageType === 'overview' && (oFetch(owedHours, 'length') > 0) && (
          <HoursOverviewOwedHoursSection owedHours={owedHours} />
        )}
        <EntertainmentShiftList
          entertainmentShifts={entertainmentShifts}
          itemRender={(entertainmentShift) => (
            <EntertainmentShiftItem
              onAccept={acceptEntertainmentShiftAcceptance}
              onDisable={disableEntertainmentShiftAcceptance}
              entertainmentShift={entertainmentShift}
            />)}
        />
        <ClockingDayList
          clockingDays={clockingDays}
          clockingDayRenderer={renderClockingDay}
        />
      </ContentWrapper>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    owedHours: selectors.owedHoursSelector(state),
    mossHourTags: selectors.getMossHourTags(state),
    filterText: selectors.filterTextSelector(state),
    filterEffectiveStaffTypes: selectors.filterEffectiveStaffTypesSelector(state),
    clockingDays: selectors.getClockInDays(state),
    pageOptions: selectors.getPageOptions(state),
    effectiveStaffTypes: selectors.getEffectiveStaffTypes(state),
    activeSpecialSecurityPayrates: selectors.getActiveSpecialSecurityPayrates(state),
    entertainmentShifts: selectors.entertainmentShiftsSelector(state),
  };
};

const mapDispatchToProps = {
  handleFilterEffectiveStaffType: actions.filterEffectiveStaffTypeAction,
  handleFilterName: actions.filterNameAction,
  handleClockOut: actions.clockOut,
  handleUnacceptHoursAcceptancePeriod: actions.unacceptHoursAcceptancePeriod,
  handleAddNewHoursAcceptancePeriod: actions.addNewAcceptancePeriodAction,
  handleDeleteHoursAcceptancePeriod: actions.deleteHoursAcceptancePeriodAction,
  handleAcceptHoursAcceptancePeriod: actions.acceptHoursAcceptancePeriodAction,
  handleReEnableHoursAcceptancePeriod: actions.reEnableHoursAcceptancePeriodAction,
  acceptEntertainmentShiftAcceptance: actions.acceptEntertainmentShiftAcceptance,
  disableEntertainmentShiftAcceptance: actions.disableEntertainmentShiftAcceptance,
};

export default connect(mapStateToProps, mapDispatchToProps)(HoursConfirmation);
