import React from 'react';
import oFetch from 'o-fetch';
import queryString from 'query-string';
import { ContentWrapper, EntertainerItem, EntertainersDashboard, List } from './components';
import { entertainersListHook } from './entertainers-list-hook';
import { Pagination } from '@/components/boss-pagination-new';
import { EntertainersListAppProps } from '.';
import { PaginationRangeValue } from '@/components/boss-pagination-new/use-pagination';

export function EntertainersList(props: EntertainersListAppProps) {
  const { entertainers, openAddNewEntertainerModal, pagination } = entertainersListHook(props);

  function handlePageChange(page: PaginationRangeValue) {
    const currentQueryString = queryString.parse(window.location.search);
    currentQueryString.page = page;
    window.location.search = queryString.stringify(currentQueryString);
  }

  return (
    <>
      <EntertainersDashboard
        onAddNew={openAddNewEntertainerModal}
        entertainersCount={oFetch(pagination, 'allCount')}
      />
      <ContentWrapper>
        <List
          entertainers={entertainers}
          renderItem={(entertainer: any) => {
            return <EntertainerItem entertainer={entertainer} />;
          }}
        />
        {oFetch(pagination, 'totalCount') > 0 && (
          <Pagination
            currentPage={oFetch(pagination, 'currentPage')}
            totalCount={oFetch(pagination, 'totalCount')}
            pageSize={oFetch(pagination, 'pageSize')}
          onPageChange={handlePageChange}
          />
        )}
      </ContentWrapper>
    </>
  );
}
