import React from 'react';
import oFetch from 'o-fetch';
import { LegacyUpdateBankDetailsForm } from './legacy-update-bank-details-form';

export function LegacyUpdateBankDetailsModalContent(props) {
  const onSubmit = oFetch(props, 'onSubmit');
  const bankDetailsLastUpdatedAt = oFetch(props, 'bankDetailsLastUpdatedAt');
  const bankDetailsLastUpdatedBy = oFetch(props, 'bankDetailsLastUpdatedBy');
  const altBankingFullname = oFetch(props, 'altBankingFullname');
  const initialValues = {
    bankSortCode: '',
    bankAccountNumber: '',
    altBankingFullname: altBankingFullname,
  };

  return (
    <div className="boss-modal-window__content">
      <LegacyUpdateBankDetailsForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        bankDetailsLastUpdatedAt={bankDetailsLastUpdatedAt}
        bankDetailsLastUpdatedBy={bankDetailsLastUpdatedBy}
      />
    </div>
  );
}
