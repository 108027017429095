import React from 'react';

type Props = {
  label: string
  value?: string
  children?: React.ReactNode
};

export function SectionItem(props: Props) {
  const { label, value, children } = props;

  function renderValue() {
    if (value) {
      return value;
    }
    return children;
  }

  return (
    <li className="boss-details__item">
      <p className="boss-details__label">{label}</p>
      <p className="boss-details__value">{renderValue()}</p>
    </li>
  );
}
