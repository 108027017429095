import React from 'react';
import { ShiftsList } from './shifts-list';
import oFetch from 'o-fetch';
import { DateItem } from './date-item';
import { ShiftItem } from './shift-item';

type Props = {
  groupedEntertainmentShifts: any;
};

export function TimeLine(props: Props) {
  const groupedEntertainmentShifts = oFetch(props, 'groupedEntertainmentShifts');

  return (
    <div className='boss-board__manager-timeline'>
      <ShiftsList
        groupedEntertainmentShifts={groupedEntertainmentShifts}
        renderItem={({ entertainmentShifts, date }) => (
          <DateItem
            entertainmentShifts={entertainmentShifts}
            date={date}
            renderShiftItem={(entertainmentShift) => <ShiftItem entertainmentShift={entertainmentShift} />}
          />
        )}
      />
    </div>
  );
}
