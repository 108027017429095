import oFetch from 'o-fetch';
import React from 'react';

type Props = {
  entertainer: any;
};

export function EntertainerItem(props: Props) {
  const entertainer = oFetch(props, 'entertainer');
  const entertainerId = oFetch(entertainer, 'id');
  const name = oFetch(entertainer, 'name');
  const description = oFetch(entertainer, 'description');
  const entertainerTypeLabel = oFetch(entertainer, 'entertainerTypeLabel');

  return (
    <div
      className="boss-table__row test-staff-members-index-listing"
    >
      <div className="boss-table__cell">
        <div className="boss-table__image">
          <a
            className="boss-table__link"
            href={`/entertainers/${entertainerId}`}
          >
            <div className="boss-avatar boss-avatar_type_combined boss-user-summary__avatar_type_scannable">
              <img
                className="boss-avatar__image"
                src="https://thispersondoesnotexist.com/"
              />
            </div>
          </a>
        </div>
      </div>

      <div className="boss-table__cell">
        <div
          className="boss-table__info"
          data-role="name"
        >
          <a
            className="boss-table__link"
            href={`/entertainers/${entertainerId}`}
          >
            <p className="boss-table__label">Name</p>
            <p className="boss-table__text">
              {name}
            </p>
          </a>
        </div>
      </div>

      <div className="boss-table__cell">
        <div
          className="boss-table__info"
          data-role="staff-type"
        >
          <a
            className="boss-table__link"
            href={`/entertainers/${entertainerId}`}
          >
            <p className="boss-table__label">Type</p>
            <p className="boss-table__text">
              {entertainerTypeLabel}
            </p>
          </a>
        </div>
      </div>

      <div className="boss-table__cell">
        <div
          className="boss-table__info"
          data-role="staff-type"
        >
          <a
            className="boss-table__link"
            href={`/entertainers/${entertainerId}`}
          >
            <p className="boss-table__label">Description</p>
            <p className="boss-table__text">
              {description}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}
