import oFetch from 'o-fetch';
import React from 'react';

type Props = {
  onEditProfile: () => void;
  onDisableProfile: () => void;
};

export function ProfileActions(props: Props) {
  const onEditProfile = oFetch(props, 'onEditProfile');
  const onDisableProfile = oFetch(props, 'onDisableProfile');

  return (
    <div className="boss-page-dashboard__buttons-group">
      <button
        onClick={onEditProfile}
        className="boss-button boss-button_role_edit boss-page-dashboard__button"
      >Edit Profile</button>
      <button
        onClick={onDisableProfile}
        className="boss-button boss-button_role_block boss-page-dashboard__button"
      >Disable Entertainer</button>
    </div>
  );
}
