import { useModal } from "@/components/hooks-components/modals";
import { EditEntertainerModal } from "./components";
import oFetch from "o-fetch";
import * as requests from './requests';
import { useLegacyGlobal } from "@/components/hooks-components/global";

export function entertainerHeaderHook() {
  const { openProcessingModal, openAreYouSureModal } = useModal();
  const updateGlobalState = oFetch(useLegacyGlobal(), 'updateGlobalState');
  const entertainer = oFetch(useLegacyGlobal(), 'entertainer');

  function updateEntertainer(updatedEntertainer: any) {
    updateGlobalState('entertainer', () => {
      return updatedEntertainer;
    });
  }

  function disableEntertainer() {
    return openAreYouSureModal({
      onSubmit() { },
      props: {
        text: 'This action will disable Entertainer',
        buttonText: 'DISABLE',
        buttonPendingText: 'DISABLING ...',
      }
    });
  }

  function openEditEntertainerModal() {
    return openProcessingModal({
      onSubmit: (handleCloseModal: any, setProcessing: any, values: any) => {
        setProcessing(true);
        return requests.updateEntertainerRequest({
          entertainer,
          values,
          onSuccess(updatedEntertainer) {
            updateEntertainer(updatedEntertainer);
            setProcessing(false);
            handleCloseModal();
          },
          onFailure() {
            setProcessing(false);
          }
        });
      },
      config: {
        baseClassName: 'boss-modal-window boss-modal-window_role_add',
        title: 'Edit Entertainer',
      },
      props: {
        entertainerTypesOptions: [],
        entertainer,
      },
      ModalContent: EditEntertainerModal,
    });
  }

  return {
    openEditEntertainerModal,
    entertainer,
    disableEntertainer
  };
}
