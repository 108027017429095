import { useEffect, useMemo } from 'react';
import React from 'react';
import _ from 'underscore';
import oFetch from 'o-fetch';
import { RotaChartInner } from './rota-chart-inner';
import { useLegacyGlobal } from '@/components/hooks-components/global';
import { calculateEntertainmentChartBoundaries } from './calculate-entertainment-chart-boundaries';

/**
This is a wrapper around the D3 rota chart that handles small state changes
like hover highlighting that don't work well with a full re-render of the chart
(due to e.g. mouseenter being re-triggered when a bar is replaced while under the cursor
... which in turn would cause a re-render).
 */
export function EntertainmentShiftsChart(props) {
  const entertainmentShifts = oFetch(props, 'entertainmentShifts');
  const onEntertainmentShiftClick = oFetch(props, 'onEntertainmentShiftClick');
  const [appType, graphXAxisHours] = oFetch(useLegacyGlobal(), 'appType', 'graphXAxisHours');

  const chartBoundaries = useMemo(() => calculateEntertainmentChartBoundaries({ entertainmentShifts, appType }), [entertainmentShifts]);

  useEffect(() => {
    require('./style.css');
  }, []);

  return (
      <RotaChartInner
        appType={appType}
        entertainmentShifts={_(entertainmentShifts).sortBy(item => item.startTime)}
        startTime={chartBoundaries.start}
        endTime={chartBoundaries.end}
        graphXAxisHours={graphXAxisHours}
        onEntertainmentShiftClick={onEntertainmentShiftClick}
      />
  );
}
