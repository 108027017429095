import React, { useMemo, useState } from 'react';
import { CompositeEntertainerFinanceReport, EntertainerFinanceReportsPageProps, EntertainerFinanceReportVenuePart, OnTabChangeArgs, OnVenueIdsChangeFuncArgs, PageFilter } from '../types';
import { Dashboard } from './dashboard';
import { EntertainerFinanceReportsTable } from './entertainer-finance-reports-table';
import { MarkAllReportsCompleteSection } from './mark-all-reports-complete-section';
import { RotaWeek } from '@/lib/rota-date';
import queryString from 'query-string';
import { ALL_TAB_FILTER_OPTION } from '../constants';
import { getCompositeEntertainerFinanceReport } from './get-composite-entertainer-finance-report';

export function EntertainerFinanceReportsPage(props: EntertainerFinanceReportsPageProps): JSX.Element {
  const allVenueIds = props.venueOptions.map((venue) => venue.id);
  const [filter, setFilter] = useState<PageFilter>({
    venueFilter: {
      allVenues: true,
      venueIds: allVenueIds,
    },
    tab: props.pageData.tab,
  });
  const allDataExists = props.pageData.containsAllReports && filter.tab === ALL_TAB_FILTER_OPTION && filter.venueFilter.allVenues;
  const hasReports = props.entertainerFinanceReports.length > 0;
  const hasCompletableReports = hasReports && props.entertainerFinanceReports.some(report => report.isCompletable
  );
  const hasIncomplableReports = hasReports && props.entertainerFinanceReports.some(report => {
    return !report.isDone && !report.isCompletable;
  });
  const allReportsCompletable = hasReports && hasCompletableReports && !hasIncomplableReports;
  const showCompleteAllSection = allDataExists && allReportsCompletable && filter.tab === ALL_TAB_FILTER_OPTION;
  const rotaWeek = RotaWeek.fromDate({ dCalendarDate: props.entertainerFinanceReportWeek.startDate });

  const onTabChange = (args: OnTabChangeArgs): void => {
    const queryStringObject = queryString.parse(window.location.search);
    window.history.pushState(
      'state',
      'title',
      `${window.location.pathname}?${queryString.stringify({ ...queryStringObject, tab: args.tab })}`,
    );

    setFilter((oldFilter) => {
      return {
        ...oldFilter,
        tab: args.tab,
      };
    });
  };

  function onVenueIdsChange(args: OnVenueIdsChangeFuncArgs): void  {
    const venueIds = args.venueIds;
    setFilter((oldFilter) => {
      return {
        ...oldFilter,
        venueFilter: {
          allVenues: venueIds.length === allVenueIds.length,
          venueIds,
        },
      };
    });
  }

  const filteredCompositeEntertainerFinanceReports = useMemo<CompositeEntertainerFinanceReport[]>(() => {
    const resultReports: CompositeEntertainerFinanceReport[] = [];

    props.entertainerFinanceReports.forEach((entertainerFinanceReport) => {
      const relevantVenueParts: EntertainerFinanceReportVenuePart[] = entertainerFinanceReport.entertainerFinanceReportVenueParts.filter((venuePart) => {
        return (
          filter.venueFilter.allVenues || filter.venueFilter.venueIds.includes(venuePart.venueId)
        );
      });

      if (relevantVenueParts.length > 0) {
        resultReports.push(
          getCompositeEntertainerFinanceReport({ entertainerFinanceReport, relevantVenueParts, tab: filter.tab }),
        );
      }
    });

    return resultReports;
  }, [filter, props.entertainerFinanceReports]);

  const filteredCount = filteredCompositeEntertainerFinanceReports.length;
  const totalCount = props.entertainerFinanceReports.length;
  const showingFilteredReports = filteredCount > 0 && filteredCount !== totalCount;

  const onMarkAllPageCompleted = (): void => {
    console.log(`TODO: now onMarkAllPageCompleted`);
  };

  return (
    <div className="boss-page-main boss-page-main_adjust_finance-reports">
      <Dashboard
        allDataExists={allDataExists}
        venueOptions={props.venueOptions}
        filter={filter}
        permissions={props.permissions}
        title="Entertainer Finance Reports"
        pageData={props.pageData}
        onTabChange={onTabChange}
        onVenueIdsChange={onVenueIdsChange}
        entertainerFinanceReportWeek={props.entertainerFinanceReportWeek}
        compositeEntertainerFinanceReports={filteredCompositeEntertainerFinanceReports}
      />
      { showingFilteredReports && (
        <h3> Showing {filteredCount} of {totalCount} </h3>
      )}
      { showCompleteAllSection && (
        <MarkAllReportsCompleteSection
          onMarkAllPageCompleted={onMarkAllPageCompleted}
        />
      )}
      <EntertainerFinanceReportsTable
        allDataExists={allDataExists}
        permissions={props.permissions}
        isWeekFinished={props.entertainerFinanceReportWeek.isFinished}
        rotaWeek={rotaWeek}
        compositeEntertainerFinanceReports={filteredCompositeEntertainerFinanceReports}
      />
    </div>
  );
}